import { axiosInstance } from "../axios"

export const getComments = async (caseID) => {
    try{
        const response = await axiosInstance.get(`/case/comment/all/${caseID}`);
        return response.data;
    } catch(error){
        console.log(error);
    }
}

export const addComment = async (info) => {
    try {
        const response = await axiosInstance.post("/case/comment/add", info);
        return response.data
    } catch(error){
        console.log(error)
    }
}

  
  export const updateComment = async (commentID, info) => {
    try {
      const response = await axiosInstance.patch(`/case/comment/update/${commentID}`, info);
      return response.data;
    } catch (error) {
      console.error(`POST comment request failed, ${error}`);
    }
  }
  
  export const  deleteComment = async (caseID, commentID) => {
    try {
        await axiosInstance.patch(`/case/comment/delete/${caseID}/${commentID}`);
    } catch (error) {
        console.error(`DELETE comment request failed, ${error}`);
    }
  }
