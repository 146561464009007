import { Box } from "@mui/material";
import InvestorQuestionSubsection from "./subsection/InvestorQuestionSubsection";


/**
 * @returns - Co-Applicant Investor Profile Section
 */


export default function CoAppInvestorSection() {
    return (
        <Box className='mt-12 bg-cofield-box rounded-2xl shadow-custom'>
            <InvestorQuestionSubsection applicantType='Co-Applicant' />
        </Box>
    );
};