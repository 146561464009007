import { Box, Grid, Tabs, Tab } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useApplication } from "../../../../contexts/ApplicationContext";
// import { useSelector } from "react-redux";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import LiabilitySubsection from "./section/subsection/LiabilitySubsection";
import AssetSubsection from "./section/subsection/AssetSubsection";
import IncomeSubsection from "./section/subsection/IncomeSubsection";
import RatioSubsection from "./section/subsection/RatioSubsection";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import "./style/FinancialAnalysis.css";
import { Stepper, Step, StepLabel, Button } from '@mui/material';
import { Scale } from "@mui/icons-material";
import PopupDialog from "../../../../components/smallComponents/alert/PopupDialog";
import { useSelector } from "react-redux";
import { current } from "@reduxjs/toolkit";



/**
 * @returns - Financial Analysis component for the application page
 * @description - Disable the CoAppFinancialSection component for now in order to save all the data in main applicant states
 */


export default function FinancialAnalysis() {
    const nextButtonRef = useRef(null);
    const [alertMsg, setAlertMsg] = useState({
        'income': '',
        'tdsr': '',
        'worth': '',
        'principleResidence': ''
    })
    const [income, setIncome] = useState(0);
    const [tdsr, setTdsr] = useState(0);
    const [worth, setWorth] = useState(true);
    const [skipValidation, setSkipValidation] = useState(false);
    const { clickNextButton } = useApplication();
    const steps = ['Add Liability', 'Add Asset', 'Add Income'];
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const liabilityRef = useRef(null);
    const assetRef = useRef(null);
    const incomeRef = useRef(null);
    const applicant = useSelector((state) => state.applicantInfo);


    // to avoid react state async update, added skip param 
    const handleNext = (filledCheck, skip = false) => {
        if(activeStep === steps.length - 1 ){
            if (skipValidation || skip){
                console.log("trigger click")
                nextButtonRef.current?.triggerClick();
                setSkipValidation(true);
                return;
            } else if(!incomeRef.current.isAllFieldsFilled()){
                setIsDialogOpen(true);
                return false;
            } else if (alertMsg.income || alertMsg.tdsr || alertMsg.worth || alertMsg.principleResidence){
                setOpenMessage(true);
                return false;
            } else {
                nextButtonRef.current?.triggerClick();
                return;
            }
        } else if(filledCheck){
            let isFilled = true;

            if (activeStep === 0 && liabilityRef.current) {
                isFilled = liabilityRef.current.isAllFieldsFilled();
            } else if (activeStep === 1 && assetRef.current) {
                isFilled = assetRef.current.isAllFieldsFilled();
            } 

            // Show an alert or prevent navigation if fields are not filled
            if (!isFilled) {
                setIsDialogOpen(true);
                return false;
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        

      };
      
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleDialogClose = () => {
        if(activeStep === steps.length - 1){
            setSkipValidation(true);
            handleNext(false, true)
        } else {
            handleNext(false);
        }
        setIsDialogOpen(false);
    }

    const handleDialogConfirm = () => {
        setIsDialogOpen(false);
    }

    useEffect(() => {
        setSkipValidation(false);
    }, [activeStep]);


    const title = "Financial Analysis";
    const color = "#E1E8EF";
    const pageName = 'financial';

    useEffect(() => {
        let currentMsg = alertMsg;
        if(income < 40000.0 ){
            currentMsg = {...currentMsg, 'income': '- Your income does not meet the requirement, please provide higher income.'};
        } else {
            currentMsg = {...currentMsg, 'income': ''};
        }
        
        if (tdsr > 35.0) {
            console.log("tdsr > 35.0");
            currentMsg = {...currentMsg, 'tdsr': '- Your Total Debt Servicing Ratio (TDSR) deos not meet the requirement, please provide either higher income or lower liability.'};
        } else {
            currentMsg = {...currentMsg, 'tdsr': ''};
        }
        
        if (worth) {
            currentMsg = {...currentMsg, 'worth': '- Your net worth does not meet the requirement, please provide higher net worth.'};
        } else {
            currentMsg = {...currentMsg, 'worth': ''};
        }
        const assetType = applicant.assetType;
        if (assetType && Array.isArray(assetType)) {
            const hasPrincipalResidence = assetType.includes('Principal Residence');
            if (hasPrincipalResidence && applicant.residentialStatus !== 'Own') {
                currentMsg ={...currentMsg, 'principleResidence': '- If you have a "Principal Residence" asset, your residential status must be "Own"'};
            } else {
                currentMsg = {...currentMsg, 'principleResidence': ''};
            }
        }

        // Update state only if the message actually changes
        if (JSON.stringify(currentMsg) !== JSON.stringify(alertMsg)) {
            setAlertMsg(currentMsg);
        }

    }, [income, tdsr, worth, applicant.assetType, applicant.residentialStatus]);

    return (
        <Box className="financial-box-overall">
            <Box>
                <TitleText text={title} />
            </Box>

            <Box className="mt-10">
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} className="financial-stepper">
                    {steps.map((label) => {
                    return (
                        <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                    })}
                </Stepper>
                <Box className="mt-10">
                    <RatioSubsection setIncome={setIncome} setTdsr={setTdsr} setWorth={setWorth}/>
                </Box>
                {
                    (activeStep === 0 && <LiabilitySubsection applicantType={'main'} color={color} ref={liabilityRef}/>) || 
                    (activeStep === 1 && <AssetSubsection applicantType={'main'} color={color} ref={assetRef}/>) ||
                    (activeStep === 2 && <IncomeSubsection applicantType={'main'} color={color} ref={incomeRef}/>)
                }
                {activeStep <= steps.length && <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                {activeStep === 0
                    ? <PrevButton /> 
                    : <Button 
                        color = "inherit"
                        onClick = {handleBack}
                        disabled = {activeStep === 0}
                        sx = {{ mr: 1, fontSize: '1.5rem' }}
                        >
                        Back
                    </Button>}
                <Box sx={{ flex: '1 1 auto'}} />
                    <Button sx= {{fontSize: '1.5rem'}} onClick={() => handleNext(true)} className={activeStep === steps.length - 1? 'hidden': 'flex'} variant={activeStep === steps.length - 1? 'contained': 'text'}>
                            Next
                    </Button>
                    <Box sx={{display: 'none'}}>
                        <NextButton ref={nextButtonRef} pageName={pageName} handleClick={() => handleNext(true, true)}/>
                    </Box>
                </Box>}
            </Box>
            <PopupDialog
                open={isDialogOpen}
                title="Step Incomplete"
                message="You have fields edited in this step that are not saved. Do you want to continue without saving?"
                closeOption="Move on without saving"
                confirmOption="Back to Editing"
                onClose={handleDialogClose}
                onConfirm={handleDialogConfirm}
            />
            <PopupDialog
                open={openMessage}
                message={Object.values(alertMsg).filter(msg => msg !== '').join('\n')}
                closeOption="Ok"
                onClose={() => setOpenMessage(false)}
            />
        </Box>
    );
};