import { Box, FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDropdownList } from "../../../services/dropdownService";
import { USER_ROLE } from "../../../constants/UserRoleMap";
import ListSubheader from '@mui/material/ListSubheader';
import PopupDialog from "../alert/PopupDialog";
import { addCaseStatusChangeHistory, updateCaseStatus } from "../../../services/caseStatusService";
/**
 * 
 * @param {string} defaultStatus - The initial status of the case ("0 Operator Approved" or "1 Form Generated")
 * @param {function} handleStatusCheck - Checks the status options
 * @returns CaseTracker component as part of the additionalTableItem for case status tracking
 */

const CaseTracker = ({ defaultStatus, caseID, userInfo, fetchAppCases = () => {} }) => {
    //Disable caseTracker selection when the user is not operator
    const userIsNotOperator = userInfo.userInfos.every(({userType}) => userType !== USER_ROLE.OPERATOR);
    const [caseStatuses, setCaseStatuses] = useState([]);
    // for history logging
    const [currStatus, setCurrStatus] = useState(defaultStatus);
    const [statusToConfirm, setStatusToConfirm] = useState('');
    const [isPopupDialogOpen, setIsPopupDialogOpen] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [error, setError] = useState('');

    const fetchStatuses = async ()=>{
        const statuses = await getDropdownList("caseStatus");
        setCaseStatuses(statuses.map(x=>x['caseStatus']));
    }

    useEffect(()=>{
        fetchStatuses();
    }, []);

    const groupedStatuses = {
        Completed: caseStatuses.filter(option => option.startsWith('5')) ,
        Processing: caseStatuses.filter(option => option.startsWith('4')),
        UnGrouped: caseStatuses.filter(option => !option.startsWith('4') && !option.startsWith('5') )
      };
    
    const handleChange = (event) => {
        setStatusToConfirm(event.target.value);
        setIsPopupDialogOpen(true);
    };

    const confirmStatusChange = async () => {

        const res = await updateCaseStatus(caseID, statusToConfirm);
        if (res.success){
            addStatusHistory(currStatus, statusToConfirm);
            setCurrStatus(statusToConfirm);
            // caseID remove the last 2 characters as applicationID
            fetchAppCases(caseID?.slice(0, -2));
            setError('');
        } else {
            setError(res.error);
            setIsErrorDialogOpen(true);
        }
        setIsPopupDialogOpen(false);
    }

    const cancelStatusChange = () => {
        setIsPopupDialogOpen(false); // Close the dialog
    };

    const addStatusHistory = async (originalStatus, newStatus)=>{
        try {
            addCaseStatusChangeHistory({
                'caseID': caseID, 
                'originalStatus': originalStatus,
                'newStatus': newStatus,
                'userName': userInfo.userName
            })
        } catch (error) {
            console.error(`Post history error: ${error}`);
        }
    } 

    return (
        <Box>
            <FormControl variant="standard" sx={{ mx: 1 }} disabled={userIsNotOperator}> 
                {caseStatuses.length > 0 && (<Select
                    value={currStatus || ""}
                    onChange={handleChange}
                    displayEmpty>
                    
                    {/* Ungrouped items */}
                    {groupedStatuses.UnGrouped.map((option, index) => (
                        <MenuItem key={`ungrouped-${index}`} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                    {/* Grouped items for Processing */}
                    <ListSubheader>Processing</ListSubheader>
                    {groupedStatuses.Processing.map((option, index) => (
                                <MenuItem key={`processing-${index}`} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                    <ListSubheader>Completed</ListSubheader>
                    {groupedStatuses.Completed.map((option, index) => (
                            <MenuItem key={`completed-${index}`} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                </Select>)}
            </FormControl>
            <PopupDialog
                open={isPopupDialogOpen}
                onClose={cancelStatusChange}
                onConfirm={confirmStatusChange}
                message={`Are you sure you want to change the status to "${statusToConfirm}"?`}
                title="Confirm Status Change" 
            />
            <PopupDialog
                open={isErrorDialogOpen}
                onClose={() => setIsErrorDialogOpen(false)}
                message={error}
                title="Status Change Error" 
            />
        </Box>
    );
}

export default CaseTracker;