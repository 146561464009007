import React from 'react';
import HeaderBox from '../../../components/smallComponents/headerBox/headerBox';
import WhiteCard from '../../../components/smallComponents/preAppCard/whiteCard'
import './Home.css'
import { Box } from '@mui/material';
import { useApplication } from '../../../contexts/ApplicationContext';
import { NavLink, useNavigate } from 'react-router-dom';
/**
 * Renders the Home component.
 *
 * @returns {JSX.Element} The rendered Home component.
 */
function NewApplicationHome() {
    const { setSelectedComponent } = useApplication();
    const navigate = useNavigate();
    const listLoan = [
        'Invest with loans to maximize my wealth',
        '......'
    ]
    const listOwn = [
        'Invest with my own money for future saving',
        'You can also transfer your registered accounts from your financial institutions'
    ]

    const handleBackyClick = (e) => {
        e.preventDefault();
        setSelectedComponent('NewApplicationHomeBack');
        navigate('newApplicationHome');
        
    }
    return (
        <Box>
            <HeaderBox text='How would you like to build your wealth?' />
            <div className="pre-application-page">
                <WhiteCard
                    title="Loan"
                    url="LoanType"
                    list={listLoan}
                />
                <WhiteCard
                    title="Own Fund"
                    url="OwnFund"
                    list={listOwn}
                    isDisabled={true} 
                />
            </div>
            <div className='button-Area'>
            <NavLink 
                onClick={handleBackyClick}
             >
             <button className="bigButton">Back</button>
             </NavLink>
            </div>


        </Box>
    );
}

export default NewApplicationHome;