import { useEffect, useState } from 'react';
import LoanType from './LoanType/LoanType';
import { useApplication } from '../../contexts/ApplicationContext';
import NewApplicationHome from './Home/Home';
import ApplicationHome from './Home/ApplicationHome';
import RequestOpt from './Home/RequestOpt';
import Loan from './Loan/Loan';
import { useNavigate, useLocation } from 'react-router-dom';
import TFSA_RRSP from './TFSA_RRSP/TFSA_RRSP';
import Quick from './Quick/Quick';
import QuickNext from './QuickNext/QuickNext';
import OwnFund from './OwnFund/OwnFund';
import TFSA from './TFSA/TFSA';
import RESP from './RESP/RESP';
import RRSP from './RRSP/RRSP';
import Non_Reg from './Non_Reg/Non_Reg';
import Unavailable from './Unavailable/Unavailable';
import LoanTransfer from './LoanTransfer/LoanTransfer';
import BeforeApp from './BeforeApp/BeforeApp';
import Account from "./../../pages/AccountPage/AccountSettingPage";
import Footer from '../../components/mediumComponents/footer/Footer';
/**
 * PreApplicationPage component.
 * Renders the appropriate component based on the selectedComponent state.
 */
const PreApplicationPage = () => {
    const { selectedComponent, setSelectedComponent } = useApplication();
    const navigate = useNavigate();
    const location = useLocation();
    const [BeforeAppNavigation, setBeforeAppNavigation] = useState('');

    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        const lastSegment = pathSegments[pathSegments.length - 1]; // Get the last segment of the path
        if (lastSegment === pathSegments[pathSegments.length - 2]) {
            let url = location.pathname;
            const size = lastSegment.length + pathSegments[pathSegments.length - 2].length + 2;
            url = url.slice(0, -size); // Remove the last character
            console.log(url)
            navigate(url);
        }

        // Check if the last segment is "Quick". This is moving from the loan selection to the new tab
        if (lastSegment === 'Quick' && selectedComponent !== 'QuickNext' && selectedComponent !== 'BeforeApp') {
            setSelectedComponent('Quick');
        }

        if (lastSegment === 'BeforeAppRegular') {
            setSelectedComponent('BeforeAppRegular');
        }

        if (lastSegment === 'LoanTransfer' && selectedComponent !== 'BeforeApp' && selectedComponent !== 'LoanTransferNext') {
            setSelectedComponent('LoanTransfer');
        }


        // Redirect based on the selectedComponent
        switch (selectedComponent) {
            case 'RegularNext':
                setSelectedComponent('Home');
                navigate('/application/regular/general');
                break;
            case 'QuickNextNext':
                setSelectedComponent('Home');
                navigate('/application/quick/general');
                break;
            case 'TFSANext':
                setSelectedComponent('Home');
                navigate('/application/tfsa/general');
                break;
            case 'RESPNext':
                setSelectedComponent('Home');
                navigate('/application/resp/general');
                break;
            case 'RRSPNext':
                setSelectedComponent('Home');
                navigate('/application/rrsp/general');
                break;
            case 'NonRegNext':
                setSelectedComponent('Home');
                navigate('/application/nonreg/general');
                break;
            case 'TransferNext':
                setSelectedComponent('Home');
                navigate('/application/transfer/general');
                break;
            case 'LoanTransfer':
                setBeforeAppNavigation('LoanTransfer');
                break;
            case 'TFSA':
                setBeforeAppNavigation('TFSA');
                break;
            case 'RESP':
                setBeforeAppNavigation('RESP');
                break;
            case 'RRSP':
                setBeforeAppNavigation('RRSP');
                break;
            case 'Non-Registered':
                setBeforeAppNavigation('NonReg');
                break;
            case 'QuickNext':
                setBeforeAppNavigation('QuickNext');
                break;
            case 'newApplicationHome':
                navigate('NewApplicationHome');
                break;
            case 'loan':
                navigate('LoanType');
                break;
            case 'requestOpt':
                navigate('ApplicationHome');
                break;
            case 'newApplicationHome':
                navigate('RequestOpt');
                break;
            default:
                break;
        }
    }, [location, navigate]);

    /**
     * Renders the appropriate component based on the selectedComponent state.
     * @returns {JSX.Element|null} The rendered component.
     */
    const renderComponent = () => {
        switch (selectedComponent) {
            case 'Home':
                return <ApplicationHome />;
            case 'my-account':
                return <Account />;
            case 'myAccountBack':
                return <ApplicationHome />;
            case 'RequestOpt':
                return <RequestOpt />;
            case 'RequestOptBack':
                return <ApplicationHome />;
            case 'NewApplicationHome':
                return <NewApplicationHome />;
            case 'NewApplicationHomeBack':
                return <RequestOpt />;
            case 'LoanTypeBack':
                return <NewApplicationHome />;
            case 'LoanBacks':
                return <LoanType />;
            case 'LoanType':
                return <LoanType />;
            case 'Loan':
                return <Loan />;
            case 'RRSP/TFSA':
                return <TFSA_RRSP />;
            case 'Quick':
                return <Quick />;
            case 'QuickNext':
                return <QuickNext />;
            case 'LoanTransfer':
                return <LoanTransfer />;
            case 'OwnFund':
                return <OwnFund />;
            case 'TFSA':
                return <TFSA />;
            case 'RESP':
                return <RESP />;
            case 'RRSP':
                return <RRSP />;
            case 'Non-Registered':
                return <Non_Reg />;
            case 'FHSA':
                return <Unavailable />;
            case 'LIRA':
                return <Unavailable />;
            case 'RRIF':
                return <Unavailable />;
            case 'BeforeAppRegular':
                return <BeforeApp typeBeforeApp='Regular' />;
            case 'BeforeApp':
                return <BeforeApp typeBeforeApp={BeforeAppNavigation} />;
            default:
                return null;
        }
    };
    const footerProp = "@2024 - 2025 Ai Financial Power Group LTD. All Rights Reserved.";

    return (
        <>
            <div className="min-h-[85vh]">
                {renderComponent()}
            </div>
            <Footer className="mt-4" content={footerProp} />
        </>
    );
};

export default PreApplicationPage;
