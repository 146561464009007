import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useApplication } from "../../../../../contexts/ApplicationContext";
import { setCoapplicantInfo } from "../../../../../reducers/coapplicantReducer";
import { useParams } from 'react-router-dom';
import TextFieldText from "../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../../components/smallComponents/textField/TextField";
import RadioGroup from "../../../../../components/smallComponents/radioGroup/RadioGroup";
import DateField from "../../../../../components/smallComponents/dateField/DateField";
import DropdownList from "../../../../../components/smallComponents/dropdownList/DropdownList";
import dayjs from "dayjs";
import Gender from "../../../../../components/enumTypes/Gender";
import { languages, ResidentialStatus, maritalStatus, answerAttributes, investorOptions } from "../../../../../constants/lists";
import "./style/CoApplicantSection.css";
import TextFieldWithHelper from "../../../../../components/smallComponents/helper/Helper";


/**
 * @param {boolean} enable - Enable the CoApplicantSection component to be displayed
 * @returns - CoApplicantSection component for General main field component
 */


export default function CoApplicantSection({ enable, countries}) {

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);

    const { handleCoInvestorScoreChange, clickNextButton } = useApplication();

    const applicationType = useParams().applicationType;

    const isLoanType = ['regular', 'quick', 'transfer'].includes(applicationType);

    const sinRequirement = isLoanType ? 'sinforloan' : 'sin';

    // Question index and score range for the pre-fill question
    const questionIndex = 0;
    const questionScore = [1, 2, 5, 10, 20];

    const handleCoapplicantChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))
    };

    const handleCoapplicantDOBChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))

        //Pre-fill the age group question and calculate investor profile score based on the co-applicant's age
        const coApplicantAge = dayjs().diff(dayjs(value), 'year');
        if (coApplicantAge > 71) {
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[0], value: [1, investorOptions[0][0], 1] }));
            handleCoInvestorScoreChange(questionIndex, questionScore[0]);
        }
        else if (coApplicantAge >= 65) {
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[0], value: [2, investorOptions[0][1], 2] }));
            handleCoInvestorScoreChange(questionIndex, questionScore[1]);
        }
        else if (coApplicantAge >= 55) {
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[0], value: [3, investorOptions[0][2], 5] }));
            handleCoInvestorScoreChange(questionIndex, questionScore[2]);
        }
        else if (coApplicantAge >= 41) {
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[0], value: [4, investorOptions[0][3], 10] }));
            handleCoInvestorScoreChange(questionIndex, questionScore[3]);
        }
        else if (coApplicantAge >= 18) {
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[0], value: [5, investorOptions[0][4], 20] }));
            handleCoInvestorScoreChange(questionIndex, questionScore[4]);
        }
    };

    const handleCoapplicantCountryChange = (attribute, value) => {
        // Clear resident of Canada since if co-applicant selects Canada as country of birth
        if (value === "Canada") {
            dispatch(setCoapplicantInfo({ attribute: 'residentOfCanadaSince', value: "" }))
        }
        dispatch(setCoapplicantInfo({ attribute, value }))
    };

    const itemSpace = 3;

    const genders = [Gender.MALE, Gender.FEMALE];
    const pageName = 'general';

    const hasCoApplicant = applicant.anyCoapplicant;

    return (
        <Box display={enable ? 'block' : 'none'} >

            <Box className="section-co-box-field">

                <Grid container alignItems={"flex-start"}>

                    <Grid item xs={itemSpace} >
                    <TextFieldWithHelper text='First Name' helperText="Please provide your first name as it appears on official IDs." />
                    <TextField
                            setStateValue={value => handleCoapplicantChange("firstname", value)}
                            defaultValue={coapplicant.firstname}
                            type={hasCoApplicant ? 'name' : 'optional'}
                            name='Co-Applicant First Name'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                    <TextFieldWithHelper text='Last Name' helperText="Please provide your last name as it appears on official IDs." />
                        <TextField
                            setStateValue={value => handleCoapplicantChange("lastname", value)}
                            defaultValue={coapplicant.lastname}
                            type={hasCoApplicant ? 'name' : 'optional'}
                            name='Co-Applicant Last Name'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Preferred Name' />
                        <TextField
                            setStateValue={value => handleCoapplicantChange("preferredName", value)}
                            defaultValue={coapplicant.preferredName}
                            type='optional'
                            name='Co-Applicant Preferred Name'
                            pageName={pageName}
                            placeholder='Optional'
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Gender' />
                        <DropdownList
                            options={genders}
                            setStateValue={value => handleCoapplicantChange("gender", value)}
                            defaultValue={coapplicant.gender}
                            type={hasCoApplicant ? 'mandatory' : 'optional'}
                            name='Co-Applicant Gender'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                    <TextFieldWithHelper text='Language Preference' helperText="Please indicate your preferred language for communication and documentation."/>
                    <DropdownList
                            options={languages}
                            setStateValue={value => handleCoapplicantChange("languagePreference", value)}
                            defaultValue={coapplicant.languagePreference}
                            type={hasCoApplicant ? 'mandatory' : 'optional'}
                            name='Co-Applicant Language Preference'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Social Insurance Number' />
                        <TextField
                            setStateValue={value => handleCoapplicantChange("socialInsuranceNumber", value)}
                            defaultValue={coapplicant.socialInsuranceNumber}
                            type={hasCoApplicant ? sinRequirement : 'optional'}
                            name='Co-Applicant SIN'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                    <TextFieldWithHelper text='Date of Birth' helperText={"MMDDYYYY"}/>
                    <DateField
                            setStateValue={value => handleCoapplicantDOBChange("dateOfBirth", value)}
                            defaultValue={coapplicant.dateOfBirth}
                            minYear={18}
                            type={hasCoApplicant ? 'date' : 'optional'}
                            name='Co-Applicant DOB'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    {isLoanType && (
                        <>
                            <Grid item xs={itemSpace} >
                                <TextFieldText text='Residential Status' />
                                <DropdownList
                                    options={ResidentialStatus}
                                    setStateValue={value => handleCoapplicantChange("residentialStatus", value)}
                                    defaultValue={coapplicant.residentialStatus}
                                    type={hasCoApplicant ? 'mandatory' : 'optional'}
                                    name='Co-Applicant Residential Status'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />
                            </Grid>

                            <Grid item xs={itemSpace} >
                                <TextFieldText text='Citizenship' />
                                {countries?.length > 0 && <DropdownList
                                    options={countries}
                                    setStateValue={value => handleCoapplicantChange("citizenship", value)}
                                    defaultValue={coapplicant.citizenship}
                                    type={hasCoApplicant ? 'mandatory' : 'optional'}
                                    name='Co-Applicant Citizenship'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />}
                            </Grid>

                            <Grid item xs={itemSpace} >
                                <TextFieldText text='Marital Status' />
                                <DropdownList
                                    options={maritalStatus}
                                    setStateValue={value => handleCoapplicantChange("maritalStatus", value)}
                                    defaultValue={coapplicant.maritalStatus}
                                    type={hasCoApplicant ? 'mandatory' : 'optional'}
                                    name='Co-Applicant Marital Status'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />
                            </Grid>

                            <Grid item xs={itemSpace} >
                                <TextFieldText text='Place of Birth (Country)' />
                                {countries?.length > 0 && <DropdownList
                                    options={countries}
                                    setStateValue={value => handleCoapplicantCountryChange("countryOfBirth", value)}
                                    defaultValue={coapplicant.countryOfBirth}
                                    type={hasCoApplicant ? 'mandatory' : 'optional'}
                                    name='Co-Applicant Place of Birth (Country)'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />}
                            </Grid>

                            <Grid item xs={itemSpace} >
                                <TextFieldText text='Place of Birth (Province)' />
                                <TextField
                                    setStateValue={value => handleCoapplicantChange("provinceOfBirth", value)}
                                    defaultValue={coapplicant.provinceOfBirth}
                                    type={hasCoApplicant ? 'mandatory' : 'optional'}
                                    name='Co-Applicant Place of Birth (Province)'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />
                            </Grid>

                            <Grid item xs={itemSpace} display={coapplicant.countryOfBirth !== "Canada" ? 'block' : 'none'} >
                            <TextFieldWithHelper text='Resident of Canada Since' helperText="Please provide the date you began residing in Canada"/>
                            <DateField
                                    setStateValue={value => handleCoapplicantChange("residentOfCanadaSince", value)}
                                    defaultValue={coapplicant.residentOfCanadaSince}
                                    type={hasCoApplicant && coapplicant.countryOfBirth !== "Canada" ? 'date' : 'optional'}
                                    name='Co-Applicant Resident of Canada Since'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />
                            </Grid>
                        </>
                    )}

                </Grid>

            </Box>

        </Box>
    );
};