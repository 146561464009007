
import { Box } from '@mui/material';
import './style/termsAndConditions.css';
import HeaderBox from '../../components/smallComponents/headerBox/headerBox';

//attempt to match p text with small text class in whiteCard component

//html output generated from online word to html converter

const PrivacyPolicy = () => {
    return (
        <>
            <Box className="legal-ul legal-ol p">
                <HeaderBox text='Privacy Policy' />
                <div class="legal">
                    <p>Ai Financial Power Group Limited (&ldquo;Company&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&rdquo;) respects your privacy and we are committed to protecting it along with your personal information through our compliance with this privacy policy (the &ldquo;Policy&rdquo;). This Policy applies to the Company and to any person providing services on our behalf.</p>
                    <p>In order to access our products and services, and as part of the account registration process, you will be required to provide information about yourself. This Policy is intended to establish transparent practices on how we collect, use, disclose, and protect the personal information of users of our website or mobile application (the &ldquo;Site&rdquo;), the types of personal information we may collect from you or that you may provide when you visit or use the Site or its features.</p>
                    <p>We will only use personal information in accordance with this Policy unless otherwise required by applicable law. We take steps to ensure that the personal information that we collect about you is adequate, relevant, not excessive, and used for limited purposes.</p>
                    <p>The Policy applies to information we collect, use, or disclose about you; in email, text, and other electronic messages between you and this Site or us. This policy does not apply to information collected by any third party through any application or content (including advertising) that may link to or be accessible from or on the Site.</p>
                    <p><strong>&nbsp;</strong></p>
                    <p><strong>Personal information we collect </strong></p>
                    <p>Through your use of the Site, we may collect several types of information from you as described below.</p>
                    <p><strong>&nbsp;</strong></p>
                    <p><strong>Personal information </strong></p>
                    <p>Personal information means information about an identifiable individual. This includes an individual&rsquo;s name, home address and phone number, age, sex, marital or family status, an identifying number, financial information, educational history, etc.</p>
                    <p>Personal information that can reasonably be used to directly or indirectly identify you that we may collect include, without limitation:</p>
                    <ol>
                        <li>Your name, mailing address, e-mail address, telephone number, date of birth, social insurance number (SIN) when required, information on government-issued identification, Internet protocol (IP) address used to connect your computer to the Internet, user name or other similar identifier;</li>
                        <li>Personal finance information such as annual income, credit report, list of assets, liabilities and investments and bank account information;</li>
                        <li>Authentication information such as passwords, answers to authentication questions, biometric information (physical and behavioural attributes that are unique to you), product and services identifiers such as a policy number</li>
                        <li>Health or lifestyle information such as your travel history or plans, driving record, criminal record, health habits</li>
                        <li>Medical information such as your healthcare provider&rsquo;s name, medical history for you or your family</li>
                        <li>Employment information such as your job and where you work, your work history, compensation records</li>
                        <li>Any down payment information such as savings and investments statement; (accepted) agreement of the purchase and sale of your existing residential property; RRSP withdrawals and any gift letters</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>Non-personal information </strong></p>
                    <p>We also collect non-personal information that does not directly or indirectly reveal your identity or directly relate to an identified individual, such as demographic information, or statistical or aggregated information. Statistical or aggregated data does not directly identify a specific person, but we may derive non-personal statistical or aggregated data from personal information. For example, we may aggregate personal information to calculate the percentage of users accessing a specific Site feature.</p>
                    <p><strong>&nbsp;</strong></p>
                    <p><strong>Technical information </strong></p>
                    <p>We collect technical information, including your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform, or information about your internet connection, the equipment you use to access our Site, and usage details.</p>
                    <p>In addition, we collect non-personal details about your Site interactions, including the full Uniform Resource Locators (URLs), clickstream to, through and from our Site (including date and time), products you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, or any phone number used to call our customer service number.</p>
                    <p><strong>&nbsp;</strong></p>
                    <p><strong>How we collect information about you </strong></p>
                    <p>Most of the information that we collect will come directly from you and with your consent. In addition, we collect information about you through automated technologies or interactions. We provide an opportunity for any user to unsubscribe from our programs on an ongoing basis by using the unsubscribe mechanism at the bottom of our e-mails or through your account settings.</p>
                    <p>While we normally collect information directly from you, we may collect your information from other persons with your consent or as authorized by law. We inform you, before or at the time of collecting personal information, of the purposes for which we are collecting the information. However, we don&rsquo;t provide this notification when you volunteer information for an obvious purpose.</p>
                    <p>The information we collect directly from you on or through our Site may include:</p>
                    <ol>
                        <li>Information that you provide on our Site. This includes information provided at the time of registering to use the Site, subscribing to our service, using a Site feature such as submitting your Investment or Investment Loan application, or providing Investment documentation, or requesting further services. We may also ask you for information when you report a problem with our Site.</li>
                        <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
                        <li>Details of transactions. Any transactions you carry out through our Site.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p>We also collect information through cookies and other automatic data collection technologies. As you navigate through and interact with our Site, we may use cookies or other automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>
                    <ol>
                        <li>Details of your visits to our Site, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Site.</li>
                        <li>Information about your computer and internet connection, including your IP address, operating system, and browser type.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p>The information we collect automatically is statistical information that may include personal information, and we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Site and to deliver a better and more personalized service, including by enabling us to:</p>
                    <ol>
                        <li>improve the products and services provided on the Site;</li>
                        <li>estimate our audience size and usage patterns;</li>
                        <li>store information about your preferences, allowing us to customize our Site according to your individual interests; and</li>
                        <li>recognize you when you return to our Site.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>Consent </strong></p>
                    <p>We ask for your consent to collect, use or disclose client personal information, except in specific circumstances where collection, use or disclosure without consent is authorized or required by law. We may assume your consent in cases where you volunteer information for an obvious purpose.</p>
                    <p>We ask for your express consent for some purposes and may not be able to provide certain services if you are unwilling to provide consent to the collection, use or disclosure of certain personal information. Where express consent is needed, we will normally ask you to provide their consent orally (in person, by telephone), in writing (by signing a consent form or by checking a box on a form), or electronically (by clicking a button).</p>
                    <p>You may withdraw consent to the use and disclosure of personal information at any time, unless the personal information is necessary for us to fulfill our legal obligations. We will respect your decision, but we may not be able to provide you with certain products and services if we do not have the necessary personal information. To withdraw your consent, if applicable, contact us as set out below.</p>
                    <p><strong>&nbsp;</strong></p>
                    <p><strong>How we use and disclose personal information </strong></p>
                    <p>We use and disclose client personal information only for the purposes for which the information was collected, except as authorized by law. For example, we use your information to approve your Investment Loan application. If we wish to use or disclose your personal information for any new business purpose, we will ask for your consent.</p>
                    <p>We use information that we collect about you or that you provide to us, including any personal information:</p>
                    <ol>
                        <li>to fulfilll the purposes for which you provided the information or that were described when it was collected, or any other purpose for which you provide it;</li>
                        <li>to provide you with notices about your account, including expiration and renewal notices;</li>
                        <li>to carry out our obligations and enforce our rights arising from any contracts with you;</li>
                        <li>to present our Site and its contents to you;</li>
                        <li>to provide you with information, products, or services that you request from us;</li>
                        <li>to notify you about changes to our Site or any products or services we offer or provide though it;</li>
                        <li>to contact you about our own and third-parties&rsquo; goods and services that may be of interest to you, as permitted by law;</li>
                        <li>to display advertisements to our advertisers&rsquo; target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria;</li>
                        <li>to improve our Site, products or services, or customer relationships and experiences;</li>
                        <li>in any other way we may describe when you provide the information;</li>
                        <li>for any other purpose with your consent</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p>We may also disclose your personal information:</p>
                    <ol>
                        <li>to our subsidiaries, affiliates, or successor corporation or other buyer in the event of a change of control of the Company;</li>
                        <li>to comply with any court order, law, or legal process, including to respond to any government or regulatory request, in accordance with applicable law;</li>
                        <li>to enforce or apply our <u>Terms of Use </u>and other agreements; and</li>
                        <li>if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our potential and existing clients/ customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>How we safeguard personal information </strong></p>
                    <p>The security of your personal information is very important to us. We use physical, electronic, and administrative measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. We protect client personal information in a manner appropriate for the sensitivity of the information. We make every reasonable effort to prevent any loss, misuse, disclosure or modification of personal information, as well as any unauthorized access to personal information.</p>
                    <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Site, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
                    <p>We will notify the applicable provincial or federal information and/or privacy commissioner, without delay, of a security breach affecting personal information if it creates a real risk of significant harm to individuals.</p>
                    <p>We retain client personal information only as long as is reasonable to fulfilll the purposes for which the information was collected or for legal or business purposes. Except as otherwise permitted or required by applicable law or regulation, we will only retain your personal information for as long as necessary to fulfilll the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
                    <p>We render client personal information non-identifying, or destroy records containing personal information once the information is no longer needed. We use appropriate security measures when destroying client personal information, including shredding paper records and permanently deleting electronic records.</p>
                    <p><strong>&nbsp;</strong></p>
                    <p><strong>How you can access records containing your own personal information </strong></p>
                    <p>You have a right of access to your own personal information in a record that is in our custody or under our control, subject to some exceptions. Applicable law may allow or require us to refuse to provide you with access to some or all of the personal information that we hold about you, or we may have destroyed, erased, or made your personal information anonymous in accordance with our record retention obligations and practices. If we refuse a request in whole or in part, we will provide the reasons for the refusal. In some cases where exceptions to access apply, we may withhold that information and provide you with the remainder of the record. For example, if we need the information to complete the transaction for which you provided the information or to provide a product or service you requested and take actions reasonably anticipated within the context of our relationship with you.</p>
                    <p>You may make a request for access to your personal information by contacting us as set out below. You must provide sufficient information in your request to allow us to identify the information you are seeking. You may also request information about our use of your personal information and any disclosure of that information to persons outside the Company.</p>
                    <p>It is important that the personal information we hold about you is accurate and current. We rely on you to notify us if there is a change to your personal information that may affect their relationship with the Company, its service providers and other third parties. If you are aware of an error in our information about you, please let us know and we will correct it on request wherever possible. In some cases we may ask for a written request for correction.</p>
                    <p>We will respond to your request within 30 calendar days, unless an extension is granted. We may charge a reasonable fee to provide information, but not to make a correction. We will advise you of any fees that may apply before beginning to process your request.</p>
                    <p>&nbsp;</p>
                    <p><strong>Choices about how we use and disclose your information </strong></p>
                    <p>We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:</p>
                    <ul>
                        <li>Tracking technologies and advertising. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on <u>Adobe&rsquo;s website</u>. If you disable or refuse cookies, please note that some parts of this Site may not be accessible or may not function properly. For more information about tracking technologies, see How we collect information about you.</li>
                        <li>Promotional offers from the Company. If you have opted in to receive certain emails from us but no longer wish to have your email address used by the Company to promote our own or third parties&rsquo; products or services, you can opt-out by sending us an email stating your request per the contact information set out below. If we have sent you a promotional email, you may unsubscribe by clicking the unsubscribe link we have included in the email.</li>
                    </ul>
                    <p>We do not control third parties&rsquo; collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of several third party ad servers&rsquo; and networks&rsquo; cookies simultaneously by using an opt-out tool created by the <u>Digital Advertising Alliance of Canada. </u></p>
                    <p>&nbsp;</p>
                    <p><strong>Changes to our Privacy Policy </strong></p>
                    <p>It is our policy to post any changes we make to our Privacy Policy on this page. If we make material changes to how we treat our users&rsquo; personal information, we will notify you via email or through a notice on the Site home page. We include the date the privacy policy was last revised at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you, and for periodically visiting our Site and this privacy policy to check for any changes.</p>
                    <p>&nbsp;</p>
                    <p><strong>Withdrawing consent, questions and complaints </strong></p>
                    <p>If you have a question or concern about how we handle personal information, our compliance with this Policy, or about a request for access to your own personal information, please contact us in the first instance:</p>
                    <div class="footer-end">
                    <p>Ai Financial Power Group Limited</p>
                    <p>905-418-4848</p>
                    <p>info@aifinancial.ca</p>
                    </div>
                    <p>&nbsp;</p>
                </div>
            </Box>
        </>);
}

export default PrivacyPolicy;