import { Box, Tabs, Tab } from "@mui/material";
import { useState } from 'react'
import { useSelector } from "react-redux";
import { useApplication } from "../../../../contexts/ApplicationContext";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import AppDisclosureSection from "./section/AppDisclosureSection";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import './style/Disclosure.css'

/**
 * @returns - Disclosure field component for the application page
 */


const Disclosure = () => {

    const applicant = useSelector((state) => state.applicantInfo);

    const { clickNextButton } = useApplication();

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const title = 'Disclosure Information';
    const pageName = 'disclosure';

    return (
        <Box className='max-w-screen-xl w-auto h-fit min-h-[80vh] ml-24 mr-24 disclosure-box-overall'>

            <Box>
                <TitleText text={title} />
            </Box>

            <Box>
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className='ml-2'>
                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} >
                    <Tab style={{ fontSize: 20 }} label="Main Applicant" value={0} />
                    {applicant.anyCoapplicant && (
                        <Tab style={{ fontSize: 20 }} label="Co-Applicant" value={1} />
                    )}
                </Tabs>
            </Box>

            <AppDisclosureSection enable={tabValue === 0} applicantType="Applicant"/>
            <AppDisclosureSection enable={tabValue === 1} applicantType="Co-Applicant"/>


            <Box className="mt-12 flex justify-center">
                <PrevButton />
                <NextButton pageName={pageName} />
            </Box>
        </Box>
    );
};

export default Disclosure;