import { Box, Grid } from "@mui/material";


/**
 * @returns - Score summary section component for the investor profile field
 * @description - This component displays the score summary of the main applicant and co-applicant and a brief description of the score ranges 
 */


export default function ScoreSummarySection() {

    const itemSpace = 12;

    return (
        <Box className='mt-12 bg-mainfield-box rounded-2xl shadow-custom'>
            <Grid container spacing={2}>
                <Grid item xs={itemSpace} >
                    <Box className="mt-5 mb-5 flex justify-center">
                        <p className="text-sm leading-8">Conservative: 12 - 40</p>
                    </Box>
                </Grid>
                <Grid item xs={itemSpace} >
                    <Box className="mb-5 flex justify-center">
                        <p className="text-sm leading-8">Moderate: 41 - 83</p>
                    </Box>
                </Grid>
                <Grid item xs={itemSpace} >
                    <Box className="mb-5 flex justify-center">
                        <p className="text-sm leading-8">Balanced: 84 - 160</p>
                    </Box>
                </Grid>
                <Grid item xs={itemSpace} >
                    <Box className="mb-5 flex justify-center">
                        <p className="text-sm leading-8">Growth: 161 - 199</p>
                    </Box>
                </Grid>
                <Grid item xs={itemSpace} >
                    <Box className="mb-5 flex justify-center">
                        <p className="text-sm leading-8">Ambitious: 200 - 240</p>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};