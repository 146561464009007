import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCoapplicantInfo } from "../../../../../reducers/coapplicantReducer";
import { useApplication } from "../../../../../contexts/ApplicationContext";
import { useParams } from 'react-router-dom';
import dayjs from "dayjs";
import TextFieldText from "../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import PhoneField from "../../../../../components/smallComponents/phoneField/PhoneField";
import TextField from "../../../../../components/smallComponents/textField/TextField";
import Checkbox from "../../../../../components/smallComponents/checkbox/Checkbox";
import DateField from "../../../../../components/smallComponents/dateField/DateField";
import RadioGroupBoolean from "../../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean";
import { currAddr, prevAddr } from "../../../../../constants/lists";
import "./style/CoAppContactSection.css";
import { usePhoneTypes } from "../../../../../hooks/usePhoneTypes";
import { twoPhoneNumbersVerified } from "../../../../../utilities/twoPhoneNumbersVerification";
import TextFieldWithHelper from "../../../../../components/smallComponents/helper/Helper";
import AddressSection from "../../../../../components/mediumComponents/AddressAutoComplete/AddressSection";
import { useState, useEffect } from "react";
import { isValidPostalCode } from "../../../../../services/dataValidationService";
import PopupDialog from "../../../../../components/smallComponents/alert/PopupDialog";


/**
 * @param {boolean} enable - Enable the CoAppContactSection component to be displayed
 * @returns - The Co-Applicant contact info section of the contact info field component
 */


export default function CoAppContactSection({ enable, setAllowNext = () => {}}) {
    const [currAddrErrors, setCurrAddrErrors] = useState({});
    const [prevAddrErrors, setPrevAddrErrors] = useState({});
    const [sameAddress, setSameAddress] = useState(false);
    const livingMonthKey = 24;
    const [dialogOpen, setDialogOpen] = useState(false);
    const { coManualInputCurrCheck, setCoManualInputCurrCheck,
        coManualInputPrevCheck, setCoManualInputPrevCheck, handleErrorMessage, clickNextButton } = useApplication();

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const { workPhoneType, cellPhoneType, homePhoneType } = usePhoneTypes(coapplicant);
    const [lessThanRequiredMonthsCoApp, setLessThanRequiredMonthsCoApp] = useState(false);
    const applicationType = useParams().applicationType;

    const hasCoApplicant = applicant.anyCoapplicant;
    const notSameAddress = !coapplicant.sameAddressAsPrimaryApplicant;
    const isLoanType = ['regular', 'quick', 'transfer'].includes(applicationType);

    const handleCoapplicantChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))
    };

    useEffect(() => {
        setLessThanRequiredMonthsCoApp(hasCoApplicant && notSameAddress && coapplicant.livingSince !== '' &&
            dayjs().diff(dayjs(coapplicant.livingSince), 'month') < livingMonthKey)
    }, [applicant.anyCoapplicant, coapplicant.livingSince]);

    const handlePhoneChange = (attribute, value) => {
        let twoDifferentPhones = twoPhoneNumbersVerified(attribute, value, coapplicant);
        if(twoDifferentPhones){
            handleErrorMessage(pageName, 'remove', 'Two different phone numbers are required for co-applicant')
            dispatch(setCoapplicantInfo({ attribute, value }))
        } else {
            handleErrorMessage(pageName, 'add', 'Two different phone numbers are required for co-applicant')
        }
    }

    const handleCoapplicantSameAddress = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))
        setSameAddress(value);

        // If the same address as main applicant is checked, copy the main applicant's address to the co-applicant's address
        if (value) {
            for (let i = 0; i < currAddr.length; i++) {
                handleCoapplicantChange(currAddr[i], applicant[currAddr[i]])
                handleCoapplicantChange(prevAddr[i], applicant[prevAddr[i]])
            }
            handleCoapplicantChange("livingSince", applicant.livingSince);
            handleCoapplicantChange("prevLivingSince", applicant.prevLivingSince);
        }
        // If the same address as main applicant is unchecked, clear the co-applicant's address
        else if (!value) {
            for (let i = 0; i < currAddr.length; i++) {
                handleCoapplicantChange(currAddr[i], "")
                handleCoapplicantChange(prevAddr[i], "")
            }
            handleCoapplicantChange("livingSince", "");
            handleCoapplicantChange("prevLivingSince", "");
        }
    };

    const dispatchAddr = (type, obj) => {
        if(type === 'curr'){
            Object.entries(obj).forEach(([key, value]) => {
                dispatch(setCoapplicantInfo({ attribute: key, value: value }));
            });
        } else if(type === 'prev'){
            Object.entries(obj).forEach(([key, value]) => {
                let prevKey = "prev" + key.charAt(0).toUpperCase() + key.slice(1);
                dispatch(setCoapplicantInfo({ attribute: prevKey, value: value }));
            });
        }
    }

    const handleCoapplicantCurrLivingChange = (attribute, value) => {
        // If the co-applicant has lived at the current address for more than 24 months, clear the previous address
        if (dayjs().diff(dayjs(value), 'month') > livingMonthKey) {
            for (let i = 0; i < prevAddr.length; i++) {
                handleCoapplicantChange(prevAddr[i], "");
            }
            handleCoapplicantChange("prevLivingSince", "");
            handleErrorMessage(pageName, 'remove', `Co-Applicant Previous Address is mandatory`)

        }
        dispatch(setCoapplicantInfo({ attribute, value }))
    };

    const itemSpace = 6;
    const specialItemSpace = 12;

    const applicantType = 'Co-Applicant';
    const pageName = 'contact';


    useEffect(() => {

        const isCurrAddressValid = (addrObj) => {
            let newErrors = {...currAddrErrors};
            Object.entries(addrObj).forEach(([key, value]) => {
                if(!value) {
                    newErrors[key] = 'Cannot be empty';
                } else {
                    if(key === 'province') {
                        if(value.trim() !== '' && !['ON', 'BC', 'QC', 'AB'].includes(value.toUpperCase())) {
                            setDialogOpen(true);
                            newErrors[key] = 'Ineligible province';
                            return;
                        } else {
                            newErrors[key] = '';
                        }
                    } else if(key === 'postalCode' && !isValidPostalCode(value)) {
                        newErrors[key] = 'Invalid format';
                    } else {
                        newErrors[key] = '';
                    }
                }
        });
        setCurrAddrErrors(newErrors);
    }

    isCurrAddressValid({
        streetNumber: coapplicant.streetNumber,
        streetName: coapplicant.streetName,
        city: coapplicant.city,
        province: coapplicant.province,
        postalCode: coapplicant.postalCode
    });
    
    }, [coapplicant.streetNumber, coapplicant.streetName, coapplicant.city, coapplicant.province, coapplicant.postalCode]);



    useEffect(() => {

        const isPrevAddressValid = (addrObj) => {
                if(!lessThanRequiredMonthsCoApp) {
                    setPrevAddrErrors({});
                    return;
                }
                Object.entries(addrObj).forEach(([key, value]) => {
                    if(value === '') {
                        setPrevAddrErrors({...prevAddrErrors, [key]: 'Cannot be empty'});
                    } else {
                        if(key === 'postalCode' && !isValidPostalCode(value)) {
                            setPrevAddrErrors({...prevAddrErrors, [key]: 'Invalid format'});
                        } else {
                            setPrevAddrErrors({...prevAddrErrors, [key]: ''});
                        }
                    }
            });
        }

        isPrevAddressValid({
            streetNumber: coapplicant.prevStreetNumber,
            streetName: coapplicant.prevStreetName,
            city: coapplicant.prevCity,
            province: coapplicant.prevProvince,
            postalCode: coapplicant.prevPostalCode
        });
    
    }, [coapplicant.prevStreetNumber, coapplicant.prevStreetName, coapplicant.prevCity, coapplicant.prevProvince, coapplicant.prevPostalCode]);

    useEffect(() => {
        // if all the values in errors are '' then set allowNext to true
        if(sameAddress) {
            setAllowNext(true);
        } else if (Object.values(currAddrErrors).every(x => x.trim() === '')){
            if(lessThanRequiredMonthsCoApp) {
                if(Object.values(prevAddrErrors).every(x => x.trim() === '')){
                    setAllowNext(true);
                }
            } else {
                setAllowNext(true);
            }
        } else {
            setAllowNext(false);
        }
    }, [currAddrErrors, prevAddrErrors]);

    return (
        <Box display={enable ? 'block' : 'none'} >

            <Box className="section-co-box-field">

                <Grid container spacing={2}>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Email' />
                        <TextField
                            setStateValue={value => handleCoapplicantChange("email", value)}
                            defaultValue={coapplicant.email}
                            type={hasCoApplicant ? 'email' : 'optional'}
                            name='Co-Applicant Email'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                            largeBox={true}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Home Phone' />
                        <PhoneField
                            setStateValue={value => handlePhoneChange("homePhone", value)}
                            defaultValue={coapplicant.homePhone}
                            type={hasCoApplicant ? 'phone' : 'optional'}
                            name='Co-Applicant Home Phone'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                    <TextFieldWithHelper text='Work Phone' helperText="your work phone number should be different from your home phone number."/>
                    <PhoneField
                            setStateValue={value => handlePhoneChange("workPhone", value)}
                            defaultValue={coapplicant.workPhone}
                            type={hasCoApplicant ? 'phone' : 'optional'}
                            name='Co-Applicant Work Phone'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Cell Phone' />
                        <PhoneField
                            setStateValue={value => handlePhoneChange("cellPhone", value)}
                            defaultValue={coapplicant.cellPhone}
                            type={hasCoApplicant ? 'optional' : 'optional'}
                            name='Co-Applicant Cell Phone'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                </Grid>

            </Box>

            <Box className="section-co-box-field">

                <Grid container spacing={2}>

                    <Grid item xs={specialItemSpace} >
                        <TextFieldText text='Same address as Main Applicant?' largeBox={true} />
                        <RadioGroupBoolean
                            setStateValue={value => handleCoapplicantSameAddress("sameAddressAsPrimaryApplicant", value)}
                            defaultValue={coapplicant.sameAddressAsPrimaryApplicant}
                            type={hasCoApplicant ? 'mandatory' : 'optional'}
                            name='Co-Applicant Same Address'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Box display={notSameAddress ? 'block' : 'none'} >

                        <Grid item xs={specialItemSpace} >
                            <TextFieldText text='Current Address' />
                            <AddressSection
                                errors={currAddrErrors}
                                addrObj={{
                                    unit: coapplicant.unit,
                                    streetNumber: coapplicant.streetNumber,
                                    streetName: coapplicant.streetName,
                                    city: coapplicant.city,
                                    province: coapplicant.province,
                                    postalCode: coapplicant.postalCode
                                }}
                                dispatchAddress={(obj) => dispatchAddr('curr', obj)}/>
                        </Grid>

                        {isLoanType && (
                            <Grid item xs={specialItemSpace} >
                            <TextFieldWithHelper text='Current Living Since' helperText="Please provide the date you began living at your current address." />
                            <DateField
                                    setStateValue={value => handleCoapplicantCurrLivingChange("livingSince", value)}
                                    defaultValue={coapplicant.livingSince}
                                    type={hasCoApplicant && notSameAddress ? 'date' : 'optional'}
                                    name='Co-Applicant Current Living Since'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />
                            </Grid>
                        )}

                    </Box>

                </Grid>

            </Box>

            <Box className="section-co-box-field">

                {isLoanType && (
                    <Grid container spacing={2} display={lessThanRequiredMonthsCoApp ? 'block' : 'none'} >

                        <Grid item xs={specialItemSpace} >
                            <TextFieldText text='Previous Address' />

                            <Grid item xs={specialItemSpace} >
                                <AddressSection
                                    errors={prevAddrErrors}
                                    addrObj={{
                                        unit: coapplicant.prevUnit,
                                        streetNumber: coapplicant.prevStreetNumber,
                                        streetName: coapplicant.prevStreetName,
                                        city: coapplicant.prevCity,
                                        province: coapplicant.prevProvince,
                                        postalCode: coapplicant.prevPostalCode
                                    }}
                                    dispatchAddress={(obj) => dispatchAddr('prev', obj)}/>
                            </Grid>
                        </Grid>

                        <Grid item xs={specialItemSpace} >
                            <TextFieldText text='Previous Living Since' />
                            <DateField
                                setStateValue={value => handleCoapplicantChange("prevLivingSince", value)}
                                defaultValue={coapplicant.prevLivingSince}
                                type={lessThanRequiredMonthsCoApp ? 'date' : 'optional'}
                                name='Co-Applicant Previous Living Since'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                    </Grid>
                )}

            </Box>

            <PopupDialog
                open={dialogOpen}
                closeOption="OK"
                onClose={() => setDialogOpen(false)}
                message={"Your current province is not eligible for our ongoing promotion. Please contact us for assistance."}
            />
        </Box>
    );
};