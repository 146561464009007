import { Box, LinearProgress, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { resetApplicantInfo } from '../../../reducers/applicantReducer';
import { resetCoapplicantInfo } from '../../../reducers/coapplicantReducer';
import { resetApplicationInfo } from '../../../reducers/applicationReducer';
import { resetAdditionalInfo } from '../../../reducers/additionalInfoReducer';
import { useApplication } from "../../../contexts/ApplicationContext";
import './style/Progressbar.css'


/**
 * @param {string} applicationID - Current application ID
 * @param {number} progress - Progress number range from 0 to 100
 * @param {string} buttonText1 - Text for button 1 and link, by default is Cancel
 * @param {string} buttonText2 - Text for button 2 and link, by default is My Account
 * @returns - Progress bar with application ID, progress number, and two buttons 
 */


export default function Progressbar({ applicationID, progress, buttonText1, buttonText2 }) {

    const [openAccount, setOpenAccount] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { resetContextStates } = useApplication();

    const handleClickOpenAccount = () => {
        setOpenAccount(true);
    };

    const handleConfirmAccount = () => {
        setOpenAccount(false);
        navigate('/home/my-account');
    };
    const handleClose = () => {
        setOpenAccount(false);
    };

    return (
        <Box className='box-component'>

            <Box className='box-progress'>
                <Typography className='typo-id'>Application ID: {applicationID}</Typography>
                <LinearProgress className='bar' variant="determinate" value={progress} />
            </Box>

            <Box className='box-number'>
                <Typography className='typo-percentage'>{progress}%</Typography>
            </Box>

            <Box className='box-button2' display={buttonText2 !== '' ? 'block' : 'none'} >
                <Button className='button-account' variant="contained" onClick={handleClickOpenAccount} >
                    {buttonText2}
                </Button>

                <Dialog
                    fullScreen={fullScreen}
                    open={openAccount}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        {"Do you want to leave the application page?"}
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            All the data and documents will be saved. You can continue the
                            application later.
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button autoFocus onClick={handleConfirmAccount}>
                            Yes
                        </Button>
                        <Button autoFocus onClick={handleClose}>
                            No
                        </Button>
                    </DialogActions>

                </Dialog>
            </Box>

        </Box>
    );
};