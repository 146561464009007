/**
 * Non_Reg component displays the requirements and documents needed for non-registered applicants.
 * It also provides navigation buttons to go back or proceed to the next step.
 */
import React from 'react';
import HeaderBox from '../../../components/smallComponents/headerBox/headerBox';
import './Non_Reg.css'
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useApplication } from '../../../contexts/ApplicationContext';

function Non_Reg() {
    const { setSelectedComponent } = useApplication();
    return (
        <Box>
            <HeaderBox text='Requirements: Non-Reg' />
            <div className="textPage">
                <p>Eligible applicants are:</p>
                <ul className='ulQuick'>
                    <li>at-least 18-year-old</li>
                    <li>having valid SIN</li>
                </ul>
                <br />
                <p>You will required to preapre and upload following documents during the application: </p>
                <ul className='ulQuick'>
                    <li>One piece of government-issued photo ID</li>
                    <li>Two pieces personal identification information documents</li>
                    <li>Void Cheque</li>
                </ul>
                <br />
                <p>For each contribution of the investment, we will charge a one-time fee from your investment as service fee. The service fee is 5% of the total contribution</p>

            </div>
            <div className='button-Area'>
                <NavLink onClick={() => setSelectedComponent('OwnFund')} className={'backButton'}>
                    <button className="bigButton">Back</button>
                </NavLink>

                <NavLink onClick={() => setSelectedComponent('BeforeApp')} className={'nextButton'}>
                    <button className="bigButton">Next</button>
                </NavLink>

            </div>

        </Box>
    );
}

export default Non_Reg;