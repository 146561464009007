import { Box, Typography } from '@mui/material';
import './style/Footer.css';


/**
 * 
 * @param {string} content - The content to be displayed in the footer
 * @returns - A footer component 
 */


export default function Footer({ content }) {
    return (
        <div className='footer-box'>
            <p className='footer-content'>{content}</p>
            <div className='footer-links'>
                <ul>
                    <li><a href="/terms-and-conditions" target="_blank">Terms and Conditions</a></li>
                    <li><a href="/privacy-policy" target="_blank">Privacy</a></li>
                </ul>
            </div>
        </div>
    )
};