import { Box, TextField, Typography } from "@mui/material";

export default function TextFieldNew({  onChange = () => {}, 
                                        largeBox = false, 
                                        longBox = false, 
                                        value='', 
                                        placeholder = '', 
                                        noMb = false,
                                        error = '',
                                        disabled=false    
                                    }) {

    const boxStyle = 'min-h-[70px] bg-transparent mt-[5px] mx-[25px] ' + (noMb? '': 'mb-[30px] ')
    + (largeBox ? 'max-w-[480px] w-[20vw] ' : longBox ? 'max-w-[1100px] w-[60vw]' : 'max-w-[240px] w-[10vw]');

    const textFieldStyle = largeBox ?
    'max-w-[460px] w-[20vw] min-h-[54px] mt-[6px] ml-[2px]' + ' bg-white rounded' :
    longBox ?
        'max-w-[1100px] w-[60vw] min-h-[54px] mt-[6px] ml-[2px]' + ' bg-white rounded':
        'max-w-[230px] w-[10vw] min-h-[54px] mt-[6px] ml-[2px]' + ' bg-white rounded';

    const handleChange = (event) => {
        onChange(event.target.value);
    }

    return (
         <Box className={boxStyle}>
            <TextField 
                disabled={disabled}
                error={!!error}
                className={textFieldStyle} 
                variant="outlined"  
                placeholder={placeholder}
                inputProps={{ style: { fontSize: '22px' } }} 
                margin="dense" 
                onChange={handleChange}
                value={value}/>
            {error && <Typography variant="caption" color="error" sx={{fontSize: '20px'}}>{error}</Typography>}
        </Box>
    )
}