import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import TextFieldText from "../../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import { useApplication } from "../../../../../../contexts/ApplicationContext";
import { useState, useEffect } from "react";
import { fetchConfig } from "../../../../../../services/fetchConfigService";
/**
 * @returns - Ratio Subsection component for the Financial Analysis field component
 * @description - This component displays the Net Worth, Total Income, and Total Debt Service Ratio of the applicant and co-applicant 
 */


export default function RatioSubsection({setTdsr = () => {}, setIncome = () => {}, setWorth = () => {}}) {

    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const [worthValue, setWorthValue] = useState(true);
    const [primeRate, setPrimeRate] = useState(0.0);
    let expectNetWorth = 0;
    const applyAmount = Number(applicant.loanAmountApplied);
    const itemSpace = 4;
    let loanAnnualPay = 0.0;
    let interstRate = primeRate === 0.0?  5.95 : primeRate;
  
    
    const netWorth =
        applicant.assetMarketValue
            .map(value => value === '' ? 0 : parseFloat(value))
            .reduce((accumulator, current) => accumulator + current, 0)
        +
        coapplicant.assetMarketValue
            .map(value => value === '' ? 0 : parseFloat(value))
            .reduce((accumulator, current) => accumulator + current, 0)
        -
        applicant.liabilityBalance
            .map(value => value === '' ? 0 : parseFloat(value))
            .reduce((accumulator, current) => accumulator + current, 0)
        -
        coapplicant.liabilityBalance
            .map(value => value === '' ? 0 : parseFloat(value))
            .reduce((accumulator, current) => accumulator + current, 0);

    if (applyAmount > 100000.0) {
        interstRate += 0.75;
        loanAnnualPay = 100000 * interstRate *0.01;
        expectNetWorth = 100000 * 1.5;
        if (netWorth >= expectNetWorth && worthValue !== false) {
            setWorthValue(false);
        } else  if (netWorth < expectNetWorth && worthValue !== true) {
            setWorthValue(true);
        }
    } else {
        interstRate += 1.00;
        loanAnnualPay = applyAmount * interstRate *0.01;
        expectNetWorth = applyAmount * 1.5;
        if (netWorth >= expectNetWorth && worthValue !== false) {
            setWorthValue(false);
        } else if (netWorth < expectNetWorth && worthValue !== true) {
            setWorthValue(true);
        }
    };

    const totalAnnualIncome =
        applicant.annualIncome
            .map(value => value === '' ? 0 : parseFloat(value))
            .reduce((accumulator, current) => accumulator + current, 0)
        +
        coapplicant.annualIncome
            .map(value => value === '' ? 0 : parseFloat(value))
            .reduce((accumulator, current) => accumulator + current, 0);

    //totoal annual debt include annual inerest for investment load
    const totalAnnualDebtServiceExpense =
        applicant.annualPropertyTax
            .map(value => value === '' ? 0 : parseFloat(value))
            .reduce((accumulator, current) => accumulator + current, 0)
        +
        applicant.monthlyManagementMaintenanceFee
            .map(value => value === '' ? 0 : parseFloat(value))
            .reduce((accumulator, current) => accumulator + current, 0) * 12
        +
        applicant.monthlyPayment
            .map(value => value === '' ? 0 : parseFloat(value))
            .reduce((accumulator, current) => accumulator + current, 0) * 12
        +
        coapplicant.annualPropertyTax
            .map(value => value === '' ? 0 : parseFloat(value))
            .reduce((accumulator, current) => accumulator + current, 0)
        +
        coapplicant.monthlyManagementMaintenanceFee
            .map(value => value === '' ? 0 : parseFloat(value))
            .reduce((accumulator, current) => accumulator + current, 0) * 12
        +
        coapplicant.monthlyPayment
            .map(value => value === '' ? 0 : parseFloat(value))
            .reduce((accumulator, current) => accumulator + current, 0) * 12
        
        + loanAnnualPay;

    const TDSR = totalAnnualIncome !== 0 ?
        totalAnnualDebtServiceExpense / totalAnnualIncome * 1.00
        :
        0.00;
    
    
    useEffect(() => {
        // Set the TDSR value in the ApplicationContext
        setTdsr(TDSR * 100);
        //Set the income value in the ApplicationContext
        setIncome(totalAnnualIncome);
        //the net worth  2 times or more of the applied amount if it's > 100K, 1.5 times if <= 100K
        setWorth(worthValue);
    }, [TDSR, setTdsr, totalAnnualIncome, setIncome, worthValue]);        
       
    useEffect(() => {
        
    }, [totalAnnualDebtServiceExpense]);   

    useEffect(() => {
        fetchConfig("Por_App_CustomerLiability", "DefaultPrimeRateforLoan").then((code) => {
            setPrimeRate(code);
        }); 
    }, []);

    return (
        <Box>
            <Box className='mt-12 bg-mainfield-box rounded-2xl shadow-custom'>
                <Grid container spacing={2}>
                    <Grid item xs={itemSpace} >
                        <Box className="ml-10 mt-5 mb-5">
                            <TextFieldText text='Net Worth' />
                            <span className="text-2xl font-bold ml-10 leading-10">
                                {"$"+netWorth.toLocaleString('en-US')}
                            </span>
                            <TextFieldText text="Required Net Worth Amount" />
                            <span className="text-2xl font-bold ml-10 leading-10"
                                  style={{ backgroundColor: 'yellow' }}  >
                                {"$"+expectNetWorth.toLocaleString('en-US')}
                            </span>
                        </Box>
                    </Grid>
                    <Grid item xs={itemSpace} >
                        <Box className="ml-10 mt-5 mb-5">
                            <TextFieldText text='Total Income' />
                            <span className="text-2xl font-bold ml-10 leading-10">
                                {"$"+totalAnnualIncome.toLocaleString('en-US')}
                            </span>
                            <TextFieldText text='Required Total Income Amount' />
                            <span className="text-2xl font-bold ml-10 leading-10"
                                 style={{ backgroundColor: 'yellow' }}  >
                                {"$40,000".toLocaleString('en-US')}
                            </span>
                        </Box>
                    </Grid>
                    <Grid item xs={itemSpace} >
                        <Box className="ml-10 mt-5 mb-5">
                            <TextFieldText text='Total Debt Service Ratio' />
                            <span className="text-2xl font-bold ml-10 leading-10">
                                {(TDSR * 100).toFixed(2)}%
                            </span>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};