import GoogleAPI from "./GoogleAPI";
import { useEffect, useState } from "react";
import AddressInput from "./AddressInput";
import { isValidPostalCode } from "../../../services/dataValidationService";

export default function AddressSection({addrObj, errors={}, dispatchAddress = () => {}}) {
    const [addr, setAddr] = useState(`${addrObj.unit || '' }  ${addrObj.streetNumber || ''} ${addrObj.streetName || ''}, ${addrObj.city} ${addrObj.province} ${addrObj.postalCode}`);

    const handleSelectAddress = (selectedAddress) => {
            dispatchAddress(selectedAddress);
    }


    const handleAddressInputChange = (attribute, value) => {
        dispatchAddress({[attribute]: value});
    }

    useEffect(() => {
        let tempAddr = `${addrObj.unit || '' }  ${addrObj.streetNumber || ''} ${addrObj.streetName || ''}, ${addrObj.city} ${addrObj.province} ${addrObj.postalCode}`;
        if(tempAddr.trim() === ',') tempAddr = '';
        setAddr(tempAddr);
    }, [addrObj.unit, addrObj.streetNumber, addrObj.streetName, addrObj.city, addrObj.province, addrObj.postalCode]);

    return (
        <>
        <GoogleAPI handleSelectAddress={handleSelectAddress} defaultAddr={addr}/>
        <AddressInput addrObj={addrObj} handleChange={handleAddressInputChange} errors={errors}/>
        </>
    )
}