import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import TextFieldText from "../../../components/smallComponents/text/textFieldText/TextFieldText";
import { useDispatch, useSelector } from "react-redux";
import DateField from "../../../components/smallComponents/dateField/DateField";
import { setAdditionalInfo } from "../../../reducers/additionalInfoReducer";
import { setApplicationInfo } from "../../../reducers/applicationReducer"
import { useApplication } from "../../../contexts/ApplicationContext";
import { saveInfo } from '../../../services/saveInfoService';
import TextFieldComponent from "../../../components/smallComponents/textField/TextField";
import { fetchIDVerification } from "../../../services/fetchInfoService";
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from "@mui/material";
import TextField from '@mui/material/TextField';
/**
 * @description - This component renders a DateField component for inputing the client's ID verification date
 * @param {string} color - Background color 
 * @returns - IdVerification subsection component as part of AddtionalInfoPage
 */

const VERIFIED_PLACE = {
    HOME: 'Home',
    OFFICE: 'Office',
    OTHER: 'Other'
}

const IdVerification = ({ color, applicationId }) => {
    const {  page } = useApplication();
    const dispatch = useDispatch();
    const additionalDetails = useSelector((state) => state.applicationInfo);
    const additionalDetailsinfo = useSelector((state) => state.additionalInfo);
    const { clickNextButton } = useApplication();
    const [verifiedPlace, setVerifiedPlace] = useState('');
    const [verifiedPlaceOther, setVerifiedPlaceOther] = useState('');


    const fetchIDVerificationInfo = async ()=>{

        const res = await fetchIDVerification(applicationId);
         
        dispatch(setAdditionalInfo({attribute: 'verifiedDate', value: res.idVerifiedDate || ''}));
        dispatch(setAdditionalInfo({attribute: 'verifiedPlace', value: res.idVerifiedPlace || ''}));
        dispatch(setAdditionalInfo({attribute: 'verifiedCity', value: res.idVerifiedCity || ''}));
        if(res.idVerifiedPlace?.startsWith(VERIFIED_PLACE.OTHER)){
            setVerifiedPlace(VERIFIED_PLACE.OTHER);
            setVerifiedPlaceOther(res.idVerifiedPlace.split('-')[1]);
        } else {
            setVerifiedPlace(res.idVerifiedPlace || '');
        }
    }

    useEffect(()=>{
        fetchIDVerificationInfo();
    },[]);

    useEffect(() => {
        if(verifiedPlace === VERIFIED_PLACE.OTHER){
            dispatch(setAdditionalInfo({ attribute: 'verifiedPlace', value: `Other-${verifiedPlaceOther}` }));
        } else {
            setVerifiedPlaceOther('');
            dispatch(setAdditionalInfo({ attribute: 'verifiedPlace', value: verifiedPlace }));
        }
    }, [verifiedPlace, verifiedPlaceOther]);


    const pageName = 'additional';

    const handleApplicantChange = (attribute, value) => {
        dispatch(setAdditionalInfo({ attribute, value }))
    };

    const handleApplicantChangeCity = (attribute, value) => {
        dispatch(setAdditionalInfo({ attribute, value }))
    };

    return (
        <div>
            <Box className='mt-10 rounded-xl shadow-lg text-black-950 py-5 flex justify-center' bgcolor={color}>
                <Box className='flex justify-center items-center mt-5 gap-5'>
                    <TextFieldText text="ID Verification Date" />
                    <DateField
                        defaultValue={additionalDetailsinfo.verifiedDate}
                        setStateValue={value => handleApplicantChange("verifiedDate", value)}
                        name='Client ID Verified Date'
                        type='date'
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                    />
                   <FormControl sx={{ m: 1, minWidth: 180 }}>
                        <InputLabel id="id-verified-place-label">ID Verified Place</InputLabel>
                        <Select className="bg-white rounded"
                            labelId="id-verified-place-label"
                            id="id-verified-place"
                            value={verifiedPlace}
                            label="ID Verified Place"
                            onChange={(e) => setVerifiedPlace(e.target.value)}
                        >
                            <MenuItem value={VERIFIED_PLACE.HOME} >Home</MenuItem>
                            <MenuItem value={VERIFIED_PLACE.OFFICE} >Office</MenuItem>
                            <MenuItem value={VERIFIED_PLACE.OTHER} >Other</MenuItem>
                        </Select>
                        {verifiedPlace === VERIFIED_PLACE.OTHER && (
                                <>
                                  <TextField 
                                    sx={{minWidth: 180}} 
                                    className="bg-white rounded" 
                                    id="outlined-basic" 
                                    variant="outlined" 
                                    value={verifiedPlaceOther}
                                    onChange={(e) => setVerifiedPlaceOther(e.target.value)}
                                    />
                                    <FormHelperText>Please specify.</FormHelperText>
                                </>
                        )}
                        
                    </FormControl>
                    <TextFieldText text="ID Verified City" />
                    <TextFieldComponent
                        defaultValue={ additionalDetailsinfo.verifiedCity}
                        setStateValue={value => handleApplicantChangeCity("verifiedCity", value)}
                        type='optional'
                    />
                </Box>
            </Box>
        </div>
    )
}

export default IdVerification;