import { Box } from '@mui/material';
import './style/termsAndConditions.css';
import HeaderBox from '../../components/smallComponents/headerBox/headerBox';

//attempt to match p text with small text class in whiteCard component

//html output generated from online word to html converter

const TermsAndConditions = () => {
    return (
        <>
        <Box className="legal-ul legal-ol p">
            <HeaderBox text='Terms and Conditions' />
            <div class="legal">
            <p>Please carefully read this agreement with The Ai Financial Power Group. In this agreement, together, we're referred to as Ai Financial, and we operate and maintain this website and any associated mobile application (collectively referred to as the Site).</p>
            <p>This agreement sets out the rules that will guide your use of the Site and everything on it. When you register for the&nbsp;Site you're agreeing to our:</p>
            <ul >
            <li>Terms and conditions</li>
            <li>Privacy policy</li>
            </ul>
            <p>These Terms and conditions and Privacy policy may change so we recommend you review them regularly. We'll post any changes on the Site. By visiting the Site, you agree to these changes.</p>
            <p>&nbsp;</p>
            <p><strong>Access and use of the Site</strong></p>
            <p>You confirm any information you enter to verify your identity belongs only to you and that you're only registering for your own account.</p>
            <p>You are granted a limited license to display the contents of the Site and can print, download and use the materials on the Site only for informational and educational purposes, and for doing business with us.</p>
            <p>You can access the Site only if you're allowed to. You'll be responsible for and indemnify (reimburse) us for any costs, losses, expenses, damages, legal fees, liabilities, claims, demands, actions and settlement amounts resulting from or related to your misuse of the Site or misuse of it by anyone you've provided your sign-in details to.</p>
            <p>Ai Financial may suspend any services on the Site for any reason.</p>
            <p>You agree you won't directly or indirectly misuse the Site in any way, including by:</p>
            <ul>
            <li>Probing, scanning, or testing the vulnerability of any system or associated network.</li>
            <li>Breaching or otherwise circumventing any security or authentication measures.</li>
            <li>Accessing, tampering with, or using non-public or shared areas of the Site that you haven't been invited to.</li>
            <li>Interfering with, degrading the performance of, or disrupting any user, host, or network.</li>
            <li>Intentionally sending a virus or a corrupt file, Trojan horse or worm; or performing any other activity with the intent to overload, flood, or spam any part of the Site or its services.</li>
            <li>Accessing or searching the Site by any means other than our publicly supported interfaces (for example, "scraping").</li>
            <li>Modifying the Site or any of its content.</li>
            <li>Sending unsolicited communications, promotions or advertisements, or spam through the Site or its services.</li>
            <li>Sending altered, deceptive or false source-identifying information, including "spoofing" or "phishing."</li>
            <li>Abusing the Site in any manner.</li>
            <li>Providing unauthorized access to the Site to others or accessing the Site on behalf of another person without appropriate authorization.</li>
            <li>Using the Site or its services to store or transmit content or data that is invasive of privacy, infringing, obscene, libelous, or that is otherwise unlawful or tortious, or to carry out infringing or unlawful activities.</li>
            </ul>
            <p>The applicable laws of Manitoba and Canada govern this agreement. Any legal actions about this agreement must be started in either Manitoba or your province or territory of residence in Canada.</p>
            <p>&nbsp;</p>
            <p><strong>Cookies</strong></p>
            <p>Cookies are used when you read or download information on the Site, or receive our emails.&nbsp;For more information on how we use cookies and to control how we collect and use your personal information, visit our cookie consent manager.</p>
            <p>&nbsp;</p>
            <p><strong>Communications and electronic delivery</strong></p>
            <p>By registering to use the Site you agree to receive email and mobile alerts. You're responsible for updating your email address or mobile number used on the Site.</p>
            <p>You agree to receive all documents electronically. The Site will be the address where you'll receive documents such as contracts, product updates, statements, tax information and other relevant materials. Please note this means you also agree to receive your mutual fund statements on the Site as well.</p>
            <p>&nbsp;</p>
            <p><strong>You may withdraw your consent to receive all documents electronically on the Site at any time in your profile on the Site or by writing to Ai Financial.</strong></p>
            <p>We may send an email or mobile notification to your registered email address to notify you when a document is available. Anyone with access to your email or mobile device may be able to view these alerts. We never include your password in these communications, but we may include your name or email address.</p>
            <p>It is your responsibility to regularly review the documents delivered to you on the Site.</p>
            <p>Ai Financial (including its employees, officers and directors) won't be responsible for any damages you may experience if you fail to access and review documents.</p>
            <p>The date documents are delivered to you is when they're made available on the Site, not when you first access them.</p>
            <p>Documents on the Site will be in a specific file format. You'll need the right technical resources to access, download or print the documents.</p>
            <p>You'll be able to download the documents on the Site for a period of time. It's your responsibility to download the documents if applicable. We'll keep a file according to our record keeping rules.</p>
            <p>If for any reason we can't, or decide not to, deliver a document electronically we'll send you a paper copy. In some cases, we might decide to send you only paper documents.</p>
            <p>&nbsp;</p>
            <p><strong>Use of email address and password</strong></p>
            <p>When you're signed in to&nbsp;the Site with your username/email address and password, any of the following will count as your electronic signature:</p>
            <ul>
            <li>Agreeing to or authorizing specific actions by selecting a checkbox.</li>
            <li>Selecting a button to indicate your agreement.</li>
            <li>Otherwise providing your signature electronically.</li>
            </ul>
            <p>Your electronic signature has the same effect as a written one and gives us, or other applicable parties, permission to act on what you've asked us to do.</p>
            <p>You're responsible for protecting your email address, username and password for your electronic signature, and to prevent any misuse of your information.</p>
            <p>Any page you visit on the Site is considered to be a written document. When you follow instructions, fill out an online form and select "accept", "register", "submit", "continue", "OK" or similar buttons, this has the same effect as if you had signed and delivered paper copy versions of the documents to us.</p>
            <p>We can't verify the identity of someone who uses your electronic signature. You'll be responsible for and indemnify (reimburse) us for any loss related to authorized or unauthorized use of your electronic signature.</p>
            <p>Notify us immediately if the confidentiality of your electronic signature is compromised or if there's been unauthorized use of it.</p>
            <p>Email may not be encrypted. We recommend that you don't send any personal or confidential information to Ai Financial or any third party by email.</p>
            <p>&nbsp;</p>
            <p><strong>Products and services</strong></p>
            <p>All products and services are subject to the terms and conditions of any applicable contracts and laws.</p>
            <p>Our products and services and those of other companies described on the Site are available only where they are allowed to be offered within Canada.</p>
            <p>Segregated funds may have commissions, trailing commissions, management fees and expenses associated with them.</p>
            <p>&nbsp;</p>
            <p><strong>Segregated funds:</strong>&nbsp;The information folder contains a description of the key features of the segregated fund policy. Any amount allocated to a segregated fund is invested at the risk of the policyowner. The values change frequently and may increase or decrease. Past performance may not be repeated.</p>
            <p>Information regarding the underlying holdings of segregated funds is for illustrative purposes only. The actual holdings may vary and are subject to change without notice.</p>
            <p>&nbsp;</p>
            <p><strong>Calculators, recommendations and projections</strong></p>
            <p>Our calculators, recommendations and projections are designed to help you set and reach your financial goals. We base them on information from you, your plan sponsor (employer/association) or advisor, if you have one, or other institution. Provide as accurate information as possible for the best results and update your information regularly.</p>
            <p>Many variables determine what your investments will look like down the road. Since no one can predict the future, our calculators, recommendations and projections are provided for general illustrative purposes only and are not warranted to be accurate or complete.</p>
            <p>All our suggestions are recommendations only based on your risk profile in the Site. Your investments are completely your choice and responsibility. Fully research any investments before you decide. Even if you save the suggested amount of money, and follow all recommendations, you may run out of money in retirement or fail to meet your goals.</p>
            <p>Ensuring you have enough money to meet your needs in retirement or for your other goals is solely your responsibility.</p>
            <p>The Site isn't intended to provide you with any personalized financial or insurance advice unless we specify.</p>
            <p>You also have access to personal advice from one of our representatives, and you can get advice from any other appropriate professional advisor as you see fit.</p>
            <p>The Site is not intended to provide legal, accounting, tax, medical or other professional advice unless we specify.</p>
            <p>&nbsp;</p>
            <p><strong>Records</strong></p>
            <p>All records for transactions on the Site may be subject to verification and acceptance by Ai Financial. These records will be conclusive and binding on you unless you establish our records are incomplete or incorrect.</p>
            <p>&nbsp;</p>
            <p><strong>Copyright</strong></p>
            <p>The Site was developed by Ai Financial and&nbsp;is our property. All information and materials contained on the Site are protected by copyright, trademark and/or other intellectual property laws of Canada and other countries, and are the property of their respective owner(s). Subject to the limited license to use the Site, no one may copy, redistribute, reproduce or republish in any form, or link to the Site or its contents in any manner whatsoever, including by way of hyperlinks or framing, unless that person has obtained the prior written consent of Ai Financial. Any infringement of the rights of Ai Financial may result in legal action.</p>
            <p>&nbsp;</p>
            <p><strong>Trademarks</strong></p>
            <p>Ai Financial owns or has obtained the right to use the trademarks, logos and domain names used on the Site. The trademarks are protected by Canadian and foreign trademark laws. All rights reserved. The display of trademarks on the Site shouldn't be construed as an implied license to use such trademarks.</p>
            <p>Ai Financial, the design, and marks displayed on the Site followed by the TM symbol at first time of use are registered or unregistered trademarks of The Ai Financial Power Group Limited or a third party. Third-party marks are used with permission or under license.</p>
            <p>&nbsp;</p>
            <p><strong>Other terms of use</strong></p>
            <p>Ai Financial provides you with access to the Site through network providers and other suppliers. It's your responsibility to make sure your own network is secure. Ai Financial (including our employees, officers and directors) isn't responsible for any loss from a security breach or from you sending private or sensitive information over the Internet.</p>
            <p>Other organizations or sites might link to&nbsp;the Site or we might link to them. This doesn't mean we endorse their products or services. Ai Financial (including our employees, officers and directors) isn't responsible for any of these sites or their content, and links are not an endorsement or recommendation of any other organization or site, or any of their products, services or content.</p>
            <p>Other companies provide tools and programs for us in connection with the Site. So, in using the Site, you may be linked to their servers and your information might be shared with them. When you use these tools or visit sites we link to, your use of them is subject to their terms and conditions, and Ai Financial (including our employees, officers and directors) isn't responsible for any loss that results.</p>
            <p>&nbsp;</p>
            <p><strong>Cancelling your account or access to the Site</strong></p>
            <p>You can cancel your access to the Site at any time by giving us 30 days written notice.</p>
            <p>You may lose or have limited access to the Site if your plan sponsor (employer or association) or your advisor stops using the Site, if applicable.</p>
            <p>We can cancel your access to the Site and its services at any time without notice. We're not liable for any loss, damage or inconvenience.</p>
            <p>The Site, in whole or in part, may occasionally be unavailable because of maintenance, updates or other causes that may be beyond our control.</p>
            <p>&nbsp;</p>
            <p><strong>General disclaimer</strong></p>
            <p>We do our best to make sure all the information on the Site is reliable and accurate, but we can't guarantee it. If there's any difference between the information provided on or through the Site and the information in our records, the information in our records prevails.</p>
            <p>Unless we specify, nothing on this Site forms a contract, except for the use of the Site. If there's any difference between the information on the Site and the terms of a product or service we or a third-party offer, the terms for that product or service prevail.</p>
            <p>We always try to make sure the Site is working at its best, but we can't guarantee you won't experience errors, interruptions or browser issues. We also can't guarantee communication to or from the Site is private, confidential or secure.</p>
            <p>We don't make and expressly disclaim any representations or warranties whatsoever relating to or arising from your use of the Site.</p>
            <p>You agree neither Ai Financial nor any third party (including their employees, officers and directors) shall be liable to you for loss of data, computer time or any loss or damage of any nature whatsoever attributable to your use or inability to use the Site for any reason whatsoever or to any action or inaction on the part of Ai Financial. This includes any direct, indirect, special, consequential or exemplary damages, even if Ai Financial has been advised of the possibility, including but not limited to lost profits, lost opportunities or business revenues, loss of goodwill, or failure to realize expected savings.</p>
            <p>This agreement is binding on you, your heirs, executors, administrators, legal representatives, successors and assigns.</p>
            <p>If anything in this agreement turns out to be unenforceable or invalid, the rest of it still applies.&nbsp;</p>
            <p>Last updated &ndash; January 2025</p>
            <p>&nbsp;</p>
            </div>
        </Box>
    </>);
}

export default TermsAndConditions;