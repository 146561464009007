import { Box, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setApplicantInfo } from "../../../../../reducers/applicantReducer";
import { setCoapplicantInfo } from "../../../../../reducers/coapplicantReducer";
import { useApplication } from "../../../../../contexts/ApplicationContext";
import { useParams } from 'react-router-dom';
import { discloseQuestions, disclosureVariables } from "../../../../../constants/lists";
import TextFieldText from "../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import DropdownList from "../../../../../components/smallComponents/dropdownList/DropdownList";
import { purposeOfInvestment, sourceOfFunds } from "../../../../../constants/lists";
import PopupDialog from "../../../../../components/smallComponents/alert/PopupDialog";
import { useEffect, useState } from "react";
import BooleanGroup from "../../../../../components/smallComponents/radioGroupBoolean/BooleanGroup";
/**
 * @param {boolean} enable - Enable the display of the applicant disclosure section
 * @returns - Applicant disclosure section for the disclosure page
 */


const AppDisclosureSection = ({ enable, applicantType}) => {

    const dispatch = useDispatch();
    let applicant;
    const coApplicant = useSelector(state => state.coapplicantInfo);
    const mainApplicant = useSelector(state => state.applicantInfo);
    if(applicantType === 'Applicant') {
        applicant = mainApplicant;
    } else if(applicantType === 'Co-Applicant') {
        applicant = coApplicant;
    }
    const { clickNextButton } = useApplication();

    const applicationType = useParams().applicationType;

    const questionSet = ['regular', 'quick'].includes(applicationType) ? discloseQuestions : [discloseQuestions[0]];
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleChange = (index, value) => {
        // Not allowed to choose "yes"
        if(value === "true") {
            setIsDialogOpen(true);
        } else {
            const attribute = disclosureVariables[index];
            handleApplicantChange( attribute, "No" )
        }
    };

    const handleApplicantChange = (attribute, value) => {
        if(applicantType === 'Applicant') {
            dispatch(setApplicantInfo({ attribute, value }))
        } else if (applicantType === 'Co-Applicant') {
            dispatch(setCoapplicantInfo({ attribute, value }))
        }
    };

    const itemSpace = 6;
    const pageName = 'disclosure';

    return (
        <>
        <Box display={enable ? 'block' : 'none'} >

            <Box className='mt-12 bg-mainfield-box rounded-2xl shadow-custom' >
                {questionSet.map((question, index) => {
                    const isLast = index === questionSet.length - 1;
                    return (
                        <div key={index} className={`flex flex-row items-center m-3 p-1 leading-8 ${isLast ? '' : 'border-b-2 border-gray-400'}`}>
                            <div className="flex basis-11/12 mx-2 my-2 text-2xl">{index + 1}. {question}</div>
                            <div className="flex justify-items-start grid-cols-1 basis-1/12 my-2 h-full place-items-center">
                            <BooleanGroup
                                horizontalOptions = {true}
                                value={applicant[disclosureVariables[index]] === "Yes" ? "true" : "false"}
                                onChange={(value) => handleChange(index, value)}
                                />
                                    
                            </div>
                        </div>
                    )
                })}
            </Box>

            <Box className='mt-12 bg-mainfield-box rounded-2xl shadow-custom'>

                {['nonreg', 'rrsp', 'tfsa'].includes(applicationType) && (
                    <Grid container spacing={2}>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='What is the purpose of investment?' largeBox={true} />
                            <DropdownList
                                options={purposeOfInvestment}
                                setStateValue={value => handleApplicantChange("purposeOfInvestment", value)}
                                defaultValue={applicant.purposeOfInvestment}
                                type='mandatory'
                                name='Purpose of Investment'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='Describe the source of funds used for this application' largeBox={true} />
                            <DropdownList
                                options={sourceOfFunds}
                                setStateValue={value => handleApplicantChange("sourceOfFunds", value)}
                                defaultValue={applicant.sourceOfFunds}
                                type='mandatory'
                                name='Source of Funds'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                    </Grid>
                )}

            </Box>

        </Box>
        <PopupDialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            message={"Application cannot be proceeded with this answer. Please review the question."}
            title="Notice" 
        />
        </>
    );
};

export default AppDisclosureSection;