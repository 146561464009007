import { Box, Tabs, Tab } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useApplication } from "../../../../contexts/ApplicationContext";
import MainApplicantSection from "./section/MainApplicantSection";
import CoApplicantSection from "./section/CoApplicantSection";
import SpouseSection from "./section/SpouseSection";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import "./style/General.css";
import { getDropdownList } from "../../../../services/dropdownService";
import { fetchApplicantInfo, fetchApplicationInfo } from '../../../../services/fetchInfoService';
import { resetCoapplicantInfo, setCoapplicantInfo } from "../../../../reducers/coapplicantReducer";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import { answerAttributes, investorOptions } from "../../../../constants/lists";
import dayjs from "dayjs";
/**
 * @returns - General field component for application page
 */


export default function General() {
    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const [currentAppID, setCurrentAppID] = useState(null);
    const { clickNextButton, handleMainInvestorScoreChange, handleCoInvestorScoreChange } = useApplication();

    const [tabValue, setTabValue] = useState(0);
    const [countries, setCountries] = useState([]);
    const fetchCountries = async ()=>{
        const countries = await getDropdownList("countries");
        setCountries(countries.map(x=>x['country']));
    }
    // Question index and score range for the pre-fill question
    const questionIndex = 0;
    const questionScore = [1, 2, 5, 10, 20];
    
    const fetchApplcantInfo = async ()=>{

        const applicationInfo = await fetchApplicationInfo(currentAppID);
        
        if(applicationInfo !== undefined && applicationInfo !== null){
            handleApplicantHasCoAppOrSpouseChange("anyCoapplicant", applicationInfo.hasCoApplicant);
        }
        const applicantInfo = await fetchApplicantInfo(currentAppID);
        if(applicantInfo.length >0){
          
            applicantInfo.map((applicant, index) => {
        
                if (index === 0) {
                    dispatch(setApplicantInfo({ attribute: 'languagePreference', value: applicant.preferredLanguage }));
                    dispatch(setApplicantInfo({ attribute: 'firstname', value: applicant.firstName }));
                    dispatch(setApplicantInfo({ attribute: 'lastname', value: applicant.lastName }));
                    dispatch(setApplicantInfo({ attribute: 'preferredName', value: applicant.preferredName }));
                    dispatch(setApplicantInfo({ attribute: 'dateOfBirth', value: applicant.dateOfBirth })); 
                    handleApplicantDOBChange('dateOfBirth', applicant.dateOfBirth);
                    dispatch(setApplicantInfo({ attribute: 'socialInsuranceNumber', value: applicant.sin }));
                    dispatch(setApplicantInfo({ attribute: 'gender', value: applicant.gender }));
                    dispatch(setApplicantInfo({ attribute: 'residentialStatus', value: applicant.residentialStatus }));
                    dispatch(setApplicantInfo({ attribute: 'citizenship', value: applicant.citizenship }));
                    dispatch(setApplicantInfo({ attribute: 'maritalStatus', value: applicant.maritalStatus }));
                    dispatch(setApplicantInfo({ attribute: 'countryOfBirth', value: applicant.countryOfBirth }));
                    dispatch(setApplicantInfo({ attribute: 'residentOfCanadaSince', value: applicant.residentOfCanadaSince }));
                    dispatch(setApplicantInfo({ attribute: 'provinceOfBirth', value: applicant.provinceOfBirth }));
                }else{
                    dispatch(setCoapplicantInfo({ attribute: 'languagePreference', value: applicant.preferredLanguage }));
                    dispatch(setCoapplicantInfo({ attribute: 'firstname', value: applicant.firstName }));
                    dispatch(setCoapplicantInfo({ attribute: 'lastname', value: applicant.lastName }));
                    dispatch(setCoapplicantInfo({ attribute: 'preferredName', value: applicant.preferredName }));
                    dispatch(setCoapplicantInfo({ attribute: 'dateOfBirth', value: applicant.dateOfBirth }));  
                    handleCoapplicantDOBChange('dateOfBirth', applicant.dateOfBirth);
                    dispatch(setCoapplicantInfo({ attribute: 'socialInsuranceNumber', value: applicant.sin }));
                    dispatch(setCoapplicantInfo({ attribute: 'gender', value: applicant.gender }));
                    dispatch(setCoapplicantInfo({ attribute: 'residentialStatus', value: applicant.residentialStatus }));
                    dispatch(setCoapplicantInfo({ attribute: 'citizenship', value: applicant.citizenship }));
                    dispatch(setCoapplicantInfo({ attribute: 'maritalStatus', value: applicant.maritalStatus }));
                    dispatch(setCoapplicantInfo({ attribute: 'countryOfBirth', value: applicant.countryOfBirth }));
                    dispatch(setCoapplicantInfo({ attribute: 'residentOfCanadaSince', value: applicant.residentOfCanadaSince }));
                    dispatch(setCoapplicantInfo({ attribute: 'provinceOfBirth', value: applicant.provinceOfBirth }));
                }
                
            });
        }
        
    }

    const handleApplicantDOBChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
        // Pre-fill the age group question and calculate investor profile score based on the main applicant's age
        const mainApplicantAge = dayjs().diff(dayjs(value), 'year');
        if (mainApplicantAge > 71) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[0], value: [1, investorOptions[0][0], 1] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[0]);
        }
        else if (mainApplicantAge >= 65) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[0], value: [2, investorOptions[0][1], 2] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[1]);
        }
        else if (mainApplicantAge >= 55) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[0], value: [3, investorOptions[0][2], 5] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[2]);
        }
        else if (mainApplicantAge >= 41) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[0], value: [4, investorOptions[0][3], 10] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[3]);
        }
        else if (mainApplicantAge >= 18) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[0], value: [5, investorOptions[0][4], 20] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[4]);
        }
    };

    const handleCoapplicantDOBChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))

        //Pre-fill the age group question and calculate investor profile score based on the co-applicant's age
        const coApplicantAge = dayjs().diff(dayjs(value), 'year');
        if (coApplicantAge > 71) {
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[0], value: [1, investorOptions[0][0], 1] }));
            handleCoInvestorScoreChange(questionIndex, questionScore[0]);
        }
        else if (coApplicantAge >= 65) {
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[0], value: [2, investorOptions[0][1], 2] }));
            handleCoInvestorScoreChange(questionIndex, questionScore[1]);
        }
        else if (coApplicantAge >= 55) {
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[0], value: [3, investorOptions[0][2], 5] }));
            handleCoInvestorScoreChange(questionIndex, questionScore[2]);
        }
        else if (coApplicantAge >= 41) {
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[0], value: [4, investorOptions[0][3], 10] }));
            handleCoInvestorScoreChange(questionIndex, questionScore[3]);
        }
        else if (coApplicantAge >= 18) {
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[0], value: [5, investorOptions[0][4], 20] }));
            handleCoInvestorScoreChange(questionIndex, questionScore[4]);
        }
    };


    useEffect(() => {
        fetchCountries()
        const params = new URLSearchParams(window.location.search);
        let applicationID = params.get('applicationID');
        if(applicationID !== null){
            setCurrentAppID(applicationID);
        }

    }, []);

    useEffect(() => {
        if(currentAppID !== null){
            
            fetchApplcantInfo();
        }
    }, [currentAppID]);
   
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleApplicantHasCoAppOrSpouseChange = (attribute, value) => {
        // Clear co-applicant information if main applicant selects no for co-applicant question or spouse question
        if (!value) {
            dispatch(resetCoapplicantInfo())
        }
        dispatch(setApplicantInfo({ attribute, value }))
    };
    
    const title = "Personal Information";
    const pageName = 'general';

    return (
        <Box className="general-box-overall">

            <Box className='mt-0'>
                <TitleText text={title} />
            </Box>

            <Box className="mt-0">
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className='ml-2 tabBox'>
                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} >
                    <Tab style={{ fontSize: 16 }} label="Main Applicant" value={0} />
                    {applicant.anyCoapplicant && (
                        <Tab style={{ fontSize: 16 }} label="Co-Applicant" value={1} />
                    )}
                    {applicant.spousalRSP && (
                        <Tab style={{ fontSize: 16 }} label="Spouse" value={2} />
                    )}
                </Tabs>
            </Box>

            <MainApplicantSection enable={tabValue === 0} countries={countries}/>
            <CoApplicantSection enable={tabValue === 1} countries={countries} />
            <SpouseSection enable={tabValue === 2} countries={countries}/>

            <Box className="general-box-button">
                {/* <PrevButton /> */}
                <NextButton pageName={pageName} />
                {/* Remember to Remove This when finalizing */}
            </Box>

        </Box>
    )
};