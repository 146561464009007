import { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { List, ListItem, ListItemText, ListItemButton } from "@mui/material";
import TextFieldNew from "../../smallComponents/textField/TextFieldNew";
import Stack from "@mui/material/Stack";

export default function GoogleAPI({defaultAddr = "", handleSelectAddress = () => {}}) {

    const [isLoading, setIsLoading] = useState(true);
    const [input, setInput] = useState(defaultAddr);
    const [results, setResults] = useState([]);
    let address = {
        unit: "",
        streetNumber: "",
        streetName: "",
        city: "",
        province: "",
        postalCode: ""
      };
    

    let token;
    // Add an initial request body.
    let request = {
      input: "",
      language: "en-US",
      region: "ca",
      includedRegionCodes: ["ca"]
    };

    useEffect(() => {
        setInput(defaultAddr);
    }, [defaultAddr]);

    // Consider making it a custom hook useScript in the future if needed
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&callback=init&libraries=places&v=weekly`;
        script.async = false;
        script.defer = true;

        script.onload = () => {
            setIsLoading(false);
          };

        document.head.appendChild(script);
        setIsLoading(false);

        return () => {
          document.head.removeChild(script);
        }
      }, []);
    
    
    async function makeAcRequest(value) {
        setInput(value);
        if (value === "") {
            setResults([]);
            return;
        }
        
        // Add the latest char sequence to the request.
        request.input = value;
        
        // Fetch autocomplete suggestions and show them in a list.
        // @ts-ignore
        const { suggestions } =
            await window.google.maps.places.AutocompleteSuggestion.fetchAutocompleteSuggestions(request,);
        
        // Clear the list first.
        setResults([]);

        if(suggestions.length === 0){
            setResults([{text: "No results found, please click to add your address manually.", place: 'NO_RESULTS'}]);  
            return;
            }
        
        for (const suggestion of suggestions) {
            const placePrediction = suggestion.placePrediction;

            const res = {
                text: placePrediction.text.toString(),
                place: placePrediction.toPlace(),
            }
    
            setResults((prev) => [...prev, res]);
      }
    }

    var ADDR_COMPONENT = {
        unit: ['subpremise'],
        streetNumber: ["street_number"],
        streetName: ["street_address", "route"],
        city: [
          "locality",
          "sublocality",
          "sublocality_level_2",
          "sublocality_level_3",
          "sublocality_level_4"
        ],
        province: [
            "administrative_area_level_1"
        ],
        postalCode: ["postal_code"],
      };
    
    async function onPlaceSelected(place) {
        if(!place){
            return;
        } else if(place === 'NO_RESULTS'){
            handleSelectAddress(null);
            setResults([]);
            return;
        }
        
        await place.fetchFields({
            fields: ["displayName", "formattedAddress", "addressComponents"],
        });
    
        let placeText = place.formattedAddress

        for(const component of place.addressComponents){
            if(component.types.some(type => ADDR_COMPONENT.unit.includes(type))){
                address.unit = component.shortText;
            }  else if(component.types.some(type => ADDR_COMPONENT.streetNumber.includes(type))){
                address.streetNumber = component.shortText;
            } else if(component.types.some(type => ADDR_COMPONENT.streetName.includes(type))){
                address.streetName = component.shortText;
            } else if(component.types.some(type => ADDR_COMPONENT.city.includes(type))){
                address.city = component.shortText;
            } else if(component.types.some(type => ADDR_COMPONENT.province.includes(type))){
                address.province = component.shortText;
            } else if(component.types.some(type => ADDR_COMPONENT.postalCode.includes(type))){
                address.postalCode = component.shortText;
            }
        }

    
        setResults([]);
        setInput(placeText);
        handleSelectAddress(address);
        refreshToken(request);
    }

    
    // Helper function to refresh the session token.
    async function refreshToken(request) {
      // Create a new session token and add it to the request.
      token = new window.google.maps.places.AutocompleteSessionToken();
      request.sessionToken = token;
      return request;
    }

    window.init = () => {
        token = new window.google.maps.places.AutocompleteSessionToken();
        request = refreshToken(request);
      };

    
    return !isLoading && (
            <Box> 
                <TextFieldNew longBox={true} placeholder="Search for a place..." value={input} onChange={makeAcRequest} noMb={true}/>
                <List sx={{position: 'fixed', zIndex: "99", width: "70%", marginLeft: "25px"}}>
                         {results.map((result, index) => (
                            <ListItemButton key={index} sx={{ bgcolor: "white", zIndex: "99", "&:hover": { bgcolor: "#f0f0f0", },}}>
                                <ListItemText 
                                primary={<Typography fontSize= "1.5rem">{result.text}</Typography>} 
                                value={result.text} 
                                onClick={() => onPlaceSelected(result.place)}
                                />
                            </ListItemButton>
                        ))}
                </List>
                
            </Box>
    )

}