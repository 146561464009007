import { Box, Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import TextFieldComponent from "../../../../components/smallComponents/textField/TextField";
import { useDispatch, useSelector } from "react-redux";
import { setAdditionalInfo } from "../../../../reducers/additionalInfoReducer";
import { AddBox } from "@mui/icons-material";
import { useApplication } from "../../../../contexts/ApplicationContext";
import OperatorSection from "./OperatorSection";
import CancelIcon from '@mui/icons-material/Cancel';
import { saveInfo, infoToSave } from "../../../../services/saveInfoService";
import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import ChooseForms from '../../../../constants/ChooseForms.json';
import { fetchApplicantsourceofConInfo } from '../../../../services/fetchInfoService';

import PopupDialog from "../../../../components/smallComponents/alert/PopupDialog";
import { addCaseStatusChangeHistory } from "../../../../services/caseStatusService";
/**
 * @description - This component is used for inputing/adding loan and fund related info by Advisors
 * @param {string} color - Background color 
 * @param {object} investFund - local state object to store investment fund and loan info
 * @param {function} setInvestFund - for setting the investFund
 * @param {string} pageName - The name of the page the doc uploader is located
 * @param {function} setOpenError - Control the display of snackbar messages
 * @param {function} setOpenSuccess - Control the display of snackbar messages
 * @param {function} firstLoanAndFund - To ensure the first "Bank & Investment" Info is mandatory
 * @param {boolean} disableAdd - To disable the edit button if any input fields are not empty, requiring submission/clearing before further editing
 * @returns - AdvisorSection component as the input area for BankInvestment subsection
 */

const AdvisorSection = ({ color, investFund, setInvestFund, pageName, setOpenError, setOpenSuccess, firstLoanAndFund, disableAdd, portfolioOpt, isUpdate, setIsUpdate, portfoliosMap, setCaseEdit, applicationsPolicy, setApplyAmountCurrent, handleAdditionalInfoChange, originalStatus}) => {
    const { applicationId, fundType } = useParams();
    const dispatch = useDispatch();
    const { clickNextButton } = useApplication();
    const additionalInfo = useSelector((state) => state.additionalInfo);
    const userTypes = useSelector((state) => state.userInfo.userInfos.map((info) => info.userType));
    const userName = useSelector((state) => state.userInfo.userName);
    const [page, setPage] = useState(0);
    const [error, setError] = useState('');
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    // State to trigger the clearing of the input fields in child components
    const [isClear, setIsClear] = useState(false);
    let currnetAmount = 0;
    let applyAmountbyApp = 0;
    const portfoliosMapAd = portfoliosMap;
    
    const bankList = () => {
        try {
            return Object.keys(ChooseForms.chooseForms[investFund.loanBanks]);
        } catch (error) {
          console.error('Error fetching bank list:', error);
          return []; // Return empty array on error
        }
      };
    const [fundCompaniesOpt, setFundCompaniesOpt] = useState([]);

    
    const subInvestmentOpt = ["Segregated Fund", "HISA"];
    const loanBanksOpt = ["B2B", "MLB", "iAT"];

    // TODO: data can totally be an object instead of an array of objects
    const addHistories = (data) => {
        data.forEach((item) => {
            addCaseStatusChangeHistory({
                caseID: item.caseID,
                newStatus: item.caseStatus,
                userName: userName,
                originalStatus:originalStatus
            })
        })
    }
  
   
    const handleAddInvestFund = async () => {
        const attributes = [
            { attribute: 'InvestmentLoanBank', value: investFund.loanBanks },
            { attribute: 'InvestmentFundCompany', value: investFund.fundCompanies },
            { attribute: 'appliedAmount', value: investFund.appliedAmount },
            { attribute: 'subInvestmentAccountType', value: investFund.subAccountType },
            //Add empty strings to state when the below values are empty
            { attribute: 'SalesCharge', value: investFund.salesCharge },
            { attribute: 'FundPortfolio', value: investFund.portfolio },
            { attribute: 'felRate', value: investFund.felRate },
            { attribute: 'felFee', value: investFund.felFee },
            { attribute: 'caseStatus', value: investFund.caseStatus },
            { attribute: 'completedAmount', value: investFund.completedAmount },
            { attribute: 'completedDate', value: investFund.completedDate },
            { attribute: 'loanAccountNo', value: investFund.loanAccountNo },
            { attribute: 'loanNo', value: investFund.loanNo },
            { attribute: 'contractNo', value: investFund.contractNo },
            { attribute: 'contributionAmount', value: investFund.contributionAmount },
            { attribute: 'primeRate', value: investFund.primeRate },
            { attribute: 'applicationID', value: applicationId },
            { attribute: 'policyGuaranteeLevel', value: applicationsPolicy },
            { attribute: 'FundPortfolioNo', value: portfoliosMapAd.get(investFund.portfolio) },
        ];
        
        let tempInfo = {};
        attributes.forEach(({ attribute, value }) => {
            tempInfo[attribute] = value;
        }); 
        //send request for 
         // Call the service to add a new liability to the database
         const info = infoToSave('newcaseCreate', investFund, 'Applicant', applicationId, null, tempInfo);
        

         // /push into Por_App_LoanInvestmentAndCasedetai
        const res = await saveInfo('investment-case', info);
        if(res.success){
            const newCaseId = res.data[0].caseID;
            handleAdditionalInfoChange('caseID', newCaseId);
            attributes.forEach(({ attribute, value }) => {
                
                
                dispatch(setAdditionalInfo({
                    attribute,
                    value: [...additionalInfo[attribute], value]
                }));
            });
            addHistories(res.data);
            setIsClear(true);
            setTimeout(() => setIsClear(false), 0.1);
            
            // Reset the local state to empty strings after adding a new entry
            setInvestFund({
                loanBanks: '',
                fundCompanies: '',
                appliedAmount: '',
                subAccountType: '',
                salesCharge: '',
                portfolio: '',
                felRate: '',
                felFee: '',
                caseStatus: '',
                completedAmount: '',
                completedDate: '',
                loanAccountNo: '',
                loanNo: '',
                contractNo: '',
                contributionAmount: '',
                primeRate: '',
                portfolioNo:'',
                policyGuaranteeLevel:''
            });
            setError('');
            setIsClear(true);
            handleClearForm();
            setApplyAmountCurrent(0);
            setOpenSuccess(true);
            setIsClear(true); // 2000ms = 2 seconds del
        } else {
            setError(res.error)
            setIsErrorDialogOpen(true);
        }
    }

    const handleTotalAmountCheck = () => {

        additionalInfo.appliedAmount.forEach((amount, index) => {
            currnetAmount += Number(amount);
          });
        //add current amount
        const appliedAmount = Number(investFund.appliedAmount) || 0;
        currnetAmount += appliedAmount;
       
    }

    const handleEditInvestFundCheck= async () => {
        const isFieldEmpty = (field) => (field?.trim() ?? '') === '';

        //Check mandatory fields
        const commonFields = [
            investFund.loanBanks
,
        ];
    
        const operatorFields = [
            ...commonFields,
            investFund.portfolio
        ];
    
        const fieldsToCheck = userTypes.includes("Advisor") ? commonFields : operatorFields;
    
        //If a mandatory field is empty, show error message
        if (fieldsToCheck.some(isFieldEmpty)) {
            setOpenError(true);
        } else {
            handleTotalAmountCheck();
            const applicantInfo = await fetchApplicantsourceofConInfo(applicationId);
            
            if(applicantInfo !== null ){
                const totalAmount = applicantInfo.amountApplied
                if(currnetAmount > totalAmount){
                    setOpenError(true);
                    setApplyAmountCurrent(currnetAmount);
                }else{
                    sendleEditInvestFundCheck()
                }
            }
        }
    }

    const fetchApplcantInfoSourceofCon = async ()=>{
    
        const applicantInfo = await fetchApplicantsourceofConInfo(applicationId);
        
        if(applicantInfo !== null ){
            applyAmountbyApp = applicantInfo.amountApplied
        }
    }   

    const sendleEditInvestFundCheck = async () => {

        const attributes = [
            { attribute: 'InvestmentLoanBank', value: investFund.loanBanks },
            { attribute: 'InvestmentFundCompany', value: investFund.fundCompanies },
            { attribute: 'appliedAmount', value: investFund.appliedAmount },
            { attribute: 'subInvestmentAccountType', value: investFund.subAccountType },
            //Add empty strings to state when the below values are empty
            { attribute: 'SalesCharge', value: investFund.salesCharge },
            { attribute: 'FundPortfolio', value: investFund.portfolio },
            { attribute: 'felRate', value: investFund.felRate },
            { attribute: 'felFee', value: investFund.felFee },
            { attribute: 'caseStatus', value: investFund.caseStatus },
            { attribute: 'completedAmount', value: investFund.completedAmount },
            { attribute: 'completedDate', value: investFund.completedDate },
            { attribute: 'loanAccountNo', value: investFund.loanAccountNo },
            { attribute: 'loanNo', value: investFund.loanNo },
            { attribute: 'contractNo', value: investFund.contractNo },
            { attribute: 'contributionAmount', value: investFund.contributionAmount },
            { attribute: 'primeRate', value: investFund.primeRate },
            { attribute: 'applicationID', value: investFund.applicationID },
            { attribute: 'caseID', value: investFund.caseID },
            { attribute: 'policyGuaranteeLevel', value: investFund.policyGuaranteeLevel },
            { attribute: 'FundPortfolioNo', value: portfoliosMapAd.get(investFund.portfolio) },
        ];
        
        let tempInfo = {};
        attributes.forEach(({ attribute, value }) => {
            tempInfo[attribute] = value;
        });
        //send request for 
         // Call the service to add a new liability to the database
         const info = infoToSave('updatecaseCreat', investFund, 'Applicant', applicationId, null,null);
        
         // /push into Por_App_LoanInvestmentAndCasedetai
        const res = await saveInfo('investment-case', info);
        if(res.success){
            // Clear input fields after adding a new entry
            // Reset the state to false after 0.1 seconds to resume input action; this allows error checking in the input child componnets
            const newCaseId = res.data[0].caseID;
            handleAdditionalInfoChange('caseID', newCaseId);
            attributes.forEach(({ attribute, value }) => {
                dispatch(setAdditionalInfo({
                    attribute,
                    value: [...additionalInfo[attribute], value]
                }));
            });
            addHistories(res.data);
            setOpenSuccess(true);
            setIsClear(true);
            setTimeout(() => setIsClear(false), 0.1);
            setIsUpdate(prevState => !prevState);
            // Reset the local state to empty strings after adding a new entry
            setInvestFund({
                loanBanks: '',
                fundCompanies: '',
                appliedAmount: '',
                subAccountType: '',
                salesCharge: '',
                portfolio: '',
                felRate: '',
                felFee: '',
                caseStatus: '',
                completedAmount: '',
                completedDate: '',
                loanAccountNo: '',
                loanNo: '',
                contractNo: '',
                contributionAmount: '',
                primeRate: '',
                portfolioNo:'',
                policyGuaranteeLevel:''
            });
            setCaseEdit((prevCaseEdit) => !prevCaseEdit);
            setError('');
            setIsClear(true);
            handleClearForm();
            setApplyAmountCurrent(0);
            setTimeout(() => {
            setOpenSuccess(true);
            }, 1500);
            setIsClear(true); // 2000ms = 2 seconds del
        } else {
            setError(res.error)
            setIsErrorDialogOpen(true);
        }
    }

    const handleAddInvestFundCheck = async ()=>{
        // Helper function to safely trim and check if any of the mandatory fields is empty
        const isFieldEmpty = (field) => {return field? field.toString().trim() === '' : true;}

        const commonFields = [
            investFund.loanBanks,
            investFund.fundCompanies,
            investFund.appliedAmount,
        ];
    
        const operatorFields = [
            ...commonFields,
            investFund.salesCharge,
            investFund.portfolio,
        ];
    
        const fieldsToCheck = userTypes.includes("Advisor") ? commonFields : operatorFields;
    
        //If a mandatory field is empty, show error message
        if (fieldsToCheck.some(isFieldEmpty)) {
            setOpenError(true);
        } else {
            handleTotalAmountCheck();
            const applicantInfo = await fetchApplicantsourceofConInfo(applicationId);
            
            if(applicantInfo !== null ){
                const totalAmount = applicantInfo.amountApplied
                if(currnetAmount > totalAmount){
                    setOpenError(true);
                    setApplyAmountCurrent(currnetAmount);
                }else{
                    handleAddInvestFund();
                    setApplyAmountCurrent(0);
                }
            }
        }
    };

    const handleClearForm = ()=>{
        setIsClear(true);
        setIsClear(true);
        setTimeout(() => setIsClear(false), 0.1);

        // Reset the local state to empty strings after adding a new entry
        setInvestFund({
            loanBanks: '',
            fundCompanies: '',
            appliedAmount: '',
            subAccountType: '',
            salesCharge: '',
            portfolio: '',
            felRate: '',
            felFee: '',
            caseStatus: '',
            completedAmount: '',
            completedDate: '',
            loanAccountNo: '',
            loanNo: '',
            contractNo: '',
            contributionAmount: '',
            primeRate: ''
        });
    }

    useEffect(() => {
        const options = bankList();
        setFundCompaniesOpt(options || []);
      }, [investFund.loanBanks]); 

    useEffect(() => {
    }, [additionalInfo]);
    const itemSpace = 4;
    
    useEffect(() => {
        
    }, [applyAmountbyApp]);

    useEffect(() => {
        
    }, [investFund]);

    useEffect(() => {
        
    }, [originalStatus]);

    useEffect(() => {
        
    }, [isClear]);


    useEffect(() => {
        fetchApplcantInfoSourceofCon();
    }, []);

    return (
        <>
            <Box className='flex-grow min-w-[800px] mt-8 pt-6 rounded-xl shadow-lg text-black-950 p-5' bgcolor={color}>
                <Grid container spacing={2} className="pt-4 pl-2">
                    <Grid item xs={itemSpace} className='flex items-center justify-center'>
                        <Box>
                            <TextFieldText text="Investmen Loan Bank" />
                            <DropdownList
                                name={"Investmen Loan Bank"}
                                options={loanBanksOpt}
                                defaultValue={investFund.loanBanks}
                                setStateValue={value => setInvestFund({ ...investFund, loanBanks: value })}
                                type={firstLoanAndFund ? 'mandatory' : 'optional'}
                                isClear={isClear}
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={itemSpace} className='flex items-center justify-center'>
                        <Box>
                            <TextFieldText text="Investment Fund Company" />
                            <DropdownList
                                name={"Investment Fund Company"}
                                options={fundCompaniesOpt}
                                defaultValue={investFund.fundCompanies}
                                setStateValue={value => setInvestFund({ ...investFund, fundCompanies: value })}
                                type={firstLoanAndFund ? 'mandatory' : 'optional'}
                                isClear={isClear}
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={itemSpace} className='flex items-center justify-center'>
                        <Box>
                            <TextFieldText text="Applied Amount" />
                            <TextFieldComponent
                                name={"Applied Amount"}
                                defaultValue={investFund.appliedAmount}
                                setStateValue={value => setInvestFund({ ...investFund, appliedAmount: value })}
                                type={firstLoanAndFund ? 'mandatory' : 'optional'}
                                isClear={isClear}
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={itemSpace} className='flex items-center justify-center'>
                        <Box className='pl-9'>
                            <TextFieldText text="Sub-Investment Acct. Type" />
                            <DropdownList
                                name={"Sub-Investment Acct. Type"}
                                options={subInvestmentOpt}
                                defaultValue={investFund.subAccountType}
                                value={investFund.subAccountType}
                                setStateValue={value => setInvestFund({ ...investFund, fundCompanies: value })}
                                type={firstLoanAndFund ? 'mandatory' : 'optional'}
                                isClear={isClear}
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                            
                         
                        </Box>
                    </Grid>
                </Grid>

                {/* Operator Input Section - visible only when  is operator */}
                {userTypes.includes("Operator") && (
                        <OperatorSection
                            investFund={investFund}
                            setInvestFund={setInvestFund}
                            pageName={pageName}
                            isClear={isClear}
                            firstLoanAndFund={firstLoanAndFund}
                            portfolioOpt= {portfolioOpt}
                            fundType={fundType} 
                            portfoliosMap={portfoliosMapAd}/>
                    )}
                
                <Box className='flex items-center justify-center gap-10 my-8'>
                    {/* Clear button */}
                    <IconButton onClick={handleClearForm}>
                        <CancelIcon sx={{ fontSize: 40 }} />
                        <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Clear Inputs</span>
                    </IconButton>

                    {!isUpdate && ( 
                            <IconButton onClick={handleAddInvestFundCheck} disabled={disableAdd}>
                                <AddBox sx={{ fontSize: 45 }} />
                                <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Add Case</span>
                            </IconButton>
                    )}

                    {isUpdate && ( 
                            <IconButton onClick={handleEditInvestFundCheck} disabled={disableAdd}>
                                <AddBox sx={{ fontSize: 45 }} />
                                <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Update Case</span>
                            </IconButton>
                    )}

                </Box>
            </Box>
            <PopupDialog
                open={isErrorDialogOpen}
                onClose={() => setIsErrorDialogOpen(false)}
                message={error}
                title="Status Change Error" 
            />
        </>
    );
}

export default AdvisorSection;