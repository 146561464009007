import { Box, Tab, Tabs } from "@mui/material";
import { useApplication } from "../../../../contexts/ApplicationContext";
import { useSelector, useDispatch } from "react-redux";
import "../general/style/General.css";
import EmploymentInformation from "./EmploymentInformation";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import { useState, useEffect } from "react";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import { fetchApplicantemploymentInfo } from '../../../../services/fetchInfoService';
import { resetCoapplicantInfo, setCoapplicantInfo } from "../../../../reducers/coapplicantReducer";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import PopupDialog from "../../../../components/smallComponents/alert/PopupDialog";

export default function Employment() {
    const [openDialog, setOpenDialog] = useState(false);
    const [validApp, setValidApp] = useState(false);
    const [validCoapp, setValidCoapp] = useState(true);
    const EmpStatus = ['Employed', 'Self-Employed', 'Student', 'Retired', 'Other']
    const title = "Employment Information";
    const dispatch = useDispatch();
    const { handleErrorMessage } = useApplication();
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const application = useSelector((state) => state.applicationInfo);
    const [tabValue, setTabValue] = useState(0);
    const pageName = 'employment'
    const { clickNextButton } = useApplication();
    const [errors, setErrors] = useState({
        'Applicant': {
            'employmentStatus': '',
            'jobTitle': '',
            'natureOfJobResponsibilities': '',
            'employmentName': '',
            'natureOfEmployersBusiness': ''
        },
        'Co-Applicant': {
            'employmentStatus': '',
            'jobTitle': '',
            'natureOfJobResponsibilities': '',
            'employmentName': '',
            'natureOfEmployersBusiness': ''
        } 
    });
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);

    };

    const fetchApplcantInfoEmployment = async ()=>{
    
        const applicantInfo = await fetchApplicantemploymentInfo(application.applicationID);
        if(applicantInfo.length >0){
            applicantInfo.map((applicant, index) => {
                if(applicant.applicantType === 'Applicant'){
                    dispatch(setApplicantInfo({ attribute: 'employmentStatus', value: applicant.employmentStatusC }));
                    dispatch(setApplicantInfo({ attribute: 'jobTitle', value: applicant.occupationJobTitleC }));
                    dispatch(setApplicantInfo({ attribute: 'natureOfJobResponsibilities', value: applicant.jobResponsibilitiesC }));
                    dispatch(setApplicantInfo({ attribute: 'employmentName', value: applicant.employerNameC}));
                    dispatch(setApplicantInfo({ attribute: 'natureOfEmployersBusiness', value: applicant.natureOfBusinessC }));
                
                }else if(applicant.applicantType === 'Co-Applicant'){
                    dispatch(setCoapplicantInfo({ attribute: 'employmentStatus', value: applicant.employmentStatusC }));
                    dispatch(setCoapplicantInfo({ attribute: 'jobTitle', value: applicant.occupationJobTitleC }));
                    dispatch(setCoapplicantInfo({ attribute: 'natureOfJobResponsibilities', value: applicant.jobResponsibilitiesC }));
                    dispatch(setCoapplicantInfo({ attribute: 'employmentName', value: applicant.employerNameC }));
                    dispatch(setCoapplicantInfo({ attribute: 'natureOfEmployersBusiness', value: applicant.natureOfBusinessC }));
                }
              
            });
        }
      
    }

    useEffect(() => {
        fetchApplcantInfoEmployment();
    }, []);

    const checkValidData = (obj, applicantType) => {
        let valid = true;
        for (const [key, value] of Object.entries(obj)) {
            if (!value) {
                valid = false;
                handleErrorMessage(pageName, 'add', `Cannot be empty`);
                setErrors(prevState => ({
                    ...prevState,
                    [applicantType]: {
                        ...prevState[applicantType],
                        [key]: `Cannot be empty`
                    }
                }));
            } else {
                handleErrorMessage(pageName, 'remove', `Cannot be empty`);
                setErrors(prevState => ({
                    ...prevState,
                    [applicantType]: {
                        ...prevState[applicantType],
                        [key]: ''
                    }
                }));
            }
        }
        return valid;
    }

    const handleNextButtonClick = () => {
        if(!['Employed', 'Self-Employed'].includes(applicant.employmentStatus)){
            setOpenDialog(true);
            return false;
        }
        const res = validApp && validCoapp;
        if(validApp && !validCoapp){
            setTabValue(1);
        }
        return res;
    }

    useEffect(() => {
        let valid = checkValidData({
            'employmentStatus': applicant.employmentStatus,
            'jobTitle': applicant.jobTitle,
            'natureOfJobResponsibilities': applicant.natureOfJobResponsibilities,
            'employmentName': applicant.employmentName,
            'natureOfEmployersBusiness': applicant.natureOfEmployersBusiness
        }, 'Applicant');
        setValidApp(valid);
        if(applicant.anyCoapplicant){
            let valid = checkValidData({
                'employmentStatus': coapplicant.employmentStatus,
                'jobTitle': coapplicant.jobTitle,
                'natureOfJobResponsibilities': coapplicant.natureOfJobResponsibilities,
                'employmentName': coapplicant.employmentName,
                'natureOfEmployersBusiness': coapplicant.natureOfEmployersBusiness
            }, 'Co-Applicant');
            setValidCoapp(valid);
        } else {
            setValidCoapp(true);
        }
    }, [applicant, coapplicant]);

    function clearField(applicantType) {
        if(applicantType === "Applicant") {
            dispatch(setApplicantInfo({ attribute: "jobTitle", value: "" }));
            dispatch(setApplicantInfo({ attribute: "natureOfJobResponsibilities", value: "" }));
            dispatch(setApplicantInfo({ attribute: "employmentName", value: "" }));
            dispatch(setApplicantInfo({ attribute: "natureOfEmployersBusiness", value: "" }));
            setErrors(prevState => ({
                ...prevState,
                'Applicant': {
                    'employmentStatus': '',
                    'jobTitle': '',
                    'natureOfJobResponsibilities': '',
                    'employmentName': '',
                    'natureOfEmployersBusiness': ''
                }
            }));
        } else if(applicantType === "Co-Applicant") {
            dispatch(setCoapplicantInfo({ attribute: "jobTitle", value: "" }));
            dispatch(setCoapplicantInfo({ attribute: "natureOfJobResponsibilities", value: "" }));
            dispatch(setCoapplicantInfo({ attribute: "employmentName", value: "" }));
            dispatch(setCoapplicantInfo({ attribute: "natureOfEmployersBusiness", value: "" }));
            setErrors(prevState => ({
                ...prevState,
                'Co-Applicant': {
                    'employmentStatus': '',
                    'jobTitle': '',
                    'natureOfJobResponsibilities': '',
                    'employmentName': '',
                    'natureOfEmployersBusiness': ''
                }
            }));
        }

    }

    //to track the employment status value and trigger the clearField
    useEffect(() => {
        if (!["Employed", "Self-Employed"].includes(applicant.employmentStatus)) {
            clearField('Applicant');
        } 
        if(applicant.anyCoapplicant){
            if (!["Employed", "Self-Employed"].includes(coapplicant.employmentStatus)) {
                clearField('Co-Applicant');
            }
        }
        // eslint-disable-next-line
    }, [applicant.employmentStatus, coapplicant.employmentStatus]);

    return (
        <Box className="general-box-overall">

            <Box>
                <TitleText text={title} />
            </Box>

            <Box>
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className='ml-2'>
                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} >
                    <Tab style={{ fontSize: 20 }} label="Main Applicant" value={0} />
                    {applicant.anyCoapplicant && (
                        <Tab style={{ fontSize: 20 }} label="Co-Applicant" value={1} />
                    )}
                </Tabs>
            </Box>

            <EmploymentInformation EmpStatus={EmpStatus} enable={tabValue === 0} applicantType="Applicant" errors={errors["Applicant"]}/>
            <EmploymentInformation EmpStatus={EmpStatus} enable={tabValue === 1} applicantType="Co-Applicant" errors={errors["Co-Applicant"]}/>

            <Box className="general-box-button">
                <PrevButton />
                <NextButton pageName={pageName} handleClick={handleNextButtonClick}/>
            </Box>
            <PopupDialog
                open={openDialog}
                title="Employment Status Error"
                closeOption="OK"
                onClose={() => setOpenDialog(false)}
                message={"You must be Employed or Self-Employed to proceed with the application."}
            />
        </Box>
    );
};