import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import {useDebounce} from 'use-debounce';

const Searchbar = ({ onSearch }) => {
    const [value, setValue] = useState("");
    const [searchValue] = useDebounce(value, 300);

    useEffect(() => {
        onSearch(searchValue); 
    }, [searchValue]);

    return (
    
        <TextField sx={{ m: 1, width: 300 }}
         label="Application ID" 
         variant="outlined"
         value={value}
         id="searchbar"
         onChange={(e) => setValue(e.target.value)}
        />
    );
};

export default Searchbar;