import { Button, Snackbar, Alert } from "@mui/material";
import { useApplication } from "../../../contexts/ApplicationContext";
import { useState, useEffect } from "react";
import "./style/SaveButton.css"


/**
 * @param {string} pageName - The name of the page that the button is on
 * @returns - A button that when clicked will call the handleNextButtonClick function from the ApplicationContext
 */


export default function SaveButton({ pageName = '', handlePageChange, onClick = () => {} }) {

    const { page, setPage, errorMessages, clickNextButton, setClickNextButton } = useApplication();

    const [localCheck, setLocalCheck] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);

    const handleNextButtonClick = () => {
        onClick();
        // Set the clickNextButton state to true so that the automatic validation will be triggered on the page 
        // after the user clicks the next button for the first time
        if (!clickNextButton[pageName]) {
            setClickNextButton(prev => ({
                ...prev,
                [pageName]: true,
            }));
        }

        // Set a timeout to allow the automatic validation to finish before checking if there are any error messages
        setTimeout(() => {
            setLocalCheck(true);
        }, 200);
    };

    const handleNextButtonErrorCheck = () => {
        // Check if there are any error messages on the page
        // If there are error messages, display a toast and return
        if (errorMessages[pageName] && errorMessages[pageName].length > 0) {
            setOpenMessage(true);
            return;
        }

        // Call the page change function if the user passes the error message check
        handlePageChange();
    };

    useEffect(() => {
        if (localCheck) {
            handleNextButtonErrorCheck(pageName);
            setLocalCheck(false);
        }
        // eslint-disable-next-line
    }, [localCheck]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenMessage(false);
    };

    return (
        <>
            <Button variant="contained" className="button-done"
                onClick={() => handleNextButtonClick()}
            >
                Save
            </Button>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openMessage} autoHideDuration={4000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    {'Please check error messages'}
                </Alert>
            </Snackbar>
        </>
    );
};