import React, { useState, useEffect } from 'react';
import { Box, Grid, Tabs, Tab } from "@mui/material";
import TitleText from '../../components/smallComponents/text/titleText/TitleText';
import ErrorBox from '../../components/smallComponents/errorBox/ErrorBox';
import BankInvestment from './subsection/BankInvestment/BankInvestment';
import AdvisorInfo from './subsection/AdvisorInfo';
import IdVerification from './subsection/IdVerification';
import AcquisitionChannel from './subsection/AcquisitionChannel';
import { useNavigate } from "react-router-dom"
import { useApplication } from '../../contexts/ApplicationContext';
import SaveButton from './DoneButton/SaveButton';
import { useParams } from 'react-router-dom';
import { fetchCases, fetchAppPolicy, fetchPortfoliowithID, fetchIDVerification } from '../../services/fetchInfoService';
import { useDispatch, useSelector } from "react-redux";
import { setAdditionalInfo, updateAdditionalInfoByIndex } from "../../reducers/additionalInfoReducer";
import { saveInfo } from '../../services/saveInfoService';
import axios from 'axios';
import { axiosInstance } from '../../axios';
/**
 * @returns - AdditionalInfo Page component
 * @description - This component renders four subsection components - BankInvestment, AdvisorInfo, IdVerification, AcquisitionChannel
 */

const AdditionalInfoPage = () => {
    const dispatch = useDispatch();

    const { applicationId, fundType } = useParams();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const { clickNextButton } = useApplication();
    const [page, setPage] = useState(0);
    const [applicationsPolicy, setApplicationsPolicy] = useState(null)
    const [applicationsStatus, setApplicationsStatus] = useState(null)
    const [applicationsSubCase, setApplicationSubCase] = useState([]);
   
    
    const userTypes = useSelector((state) => state.userInfo.userInfos.map((info) => info.userType));

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const additionalInfo = useSelector((state) => state.additionalInfo);

    const handlePageChange = async () => {
        //update application status
        if(userTypes.includes("Advisor")){
            setApplicationsStatus('Advisor Reviewed')
        }else if(userTypes.includes("Operator")){
            setApplicationsStatus('Operation Processing')
        }
        if(additionalInfo.acquisitionChannel !== "Client Referral" && additionalInfo.acquisitionChannel !== "Non-Client Referral"){
            //need to save this Acquisition Channel 
            saveAcquisitionChannelInfo();
        }
        
        const nextUrl = '/additionalDetails/'+applicationId;
        navigate(nextUrl, {state: {toBeConfirmed: true}});
    };

    const saveAcquisitionChannelInfo = async () => {
        try {
            const payload = {
                acquisitionChannelID: additionalInfo.AcquisitionChannelID,
                applicationID: applicationId,
                customerAcquisitionChannel: additionalInfo.acquisitionChannel,
                customerAcquisitionChannelDetail: additionalInfo.acquisitionChannelDetail,
                applicantType: 'Applicant',
                referrerFirstName: '',
                referrerLastName: '',
                referrerPreferredName: '',
                referrerCustomerID: null,
                referrerDateofBirth: '',
                referrerGender: '',
                referrerHomePhone: ''
            };
            console.log(payload)
            await saveInfo("acquisitionChannel", payload);
        } catch (error) {
            console.error("Error saving advisor info:", error);
        }
    };

    const itemSpace = 12;
    const title = "Additional Information";
    const color = "#E1E8EF";
    
    const pageName = 'additional';

    const fetchAppPolicys= async ()=>{
        const casesResPolicy = await fetchAppPolicy(applicationId);
        setApplicationsPolicy(casesResPolicy)
    }

    const fetchAppCases = async ()=>{
        try {
                const casesRes = await fetchCases(applicationId, page);
                setApplicationSubCase([...applicationsSubCase, ...casesRes]);
                let newCaseInvestmentLoanBank = [];
                let newCaseInvestmentFundCompany = [];
                let newappliedAmount = [];
                let newCaseSalesCharge = [];
                let newCasecaseStatus = [];
                let newCaseprimeRate = [];
                let newCasrcaseID = [];
                let newCasrapplicationID = [];
                let newCasrSubInvestmentAccountType = [];
                let newCasepolicyGuaranteeLevel = [];
                let newCasrcompletedAmount = [];
                let newCasecompletedDate = [];
                let newCasecontributionAmount = [];
                let newCasefelRate = [];
                let newCasefelFee = [];
                let newCaseloanAccountNo = [];
                let newCasefundPortfolio = [];
                let newCasefundPortfolioNo = [];
                let newCaseloanNo = [];
                let newCasecontractNo = [];

               
                for (const newCase of casesRes) {   
                    
                    //get fetchPortfolio based on ID
                    let portfolioName = null;
                    if(newCase.fundPortfolioNo !== null){
                        portfolioName  = await fetchPortfoliowithID(newCase.fundPortfolioNo);
                    }
                    if(additionalInfo.applicationID.includes(newCase.applicationID)){
                        
                        //update existing element
                        const index = additionalInfo.findIndex(
                            (item) => item.applicationID === newCase.applicationID
                          );
                        if(index >=0 ){
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'InvestmentLoanBank', index:index, value: newCase.loanFrom }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'InvestmentFundCompany', index:index, value: newCase.investmentTo }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'appliedAmount', index:index, value: newCase.amountApplied }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'SalesCharge', index:index, value: newCase.salesCharge }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'caseStatus', index:index, value: newCase.caseStatus }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'primeRate', index:index, value: newCase.primeRate }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'caseID', index:index, value: newCase.caseID }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'applicationID', index:index, value: newCase.applicationID }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'subInvestmentAccountType', index:index, value: newCase.subInvestmentAccountType }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'policyGuaranteeLevel', index:index, value: newCase.applicationsPolicy }));
                            
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'completedAmount', index:index, value: Math.round(newCase.completedAmount * 100) / 100 }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'completedDate', index:index, value: newCase.completedDate }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'contributionAmount', index:index, value: Math.round(newCase.contributionAmount * 100) / 100 }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'felRate', index:index, value: newCase.felRate }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'felFee', index:index, value: Math.round(newCase.felFee * 100) / 100 }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'loanAccountNo', index:index, value: newCase.loanAccountNo }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'FundPortfolio', index:index, value: portfolioName === null? '' : portfolioName }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'FundPortfolioNo', index:index, value: newCase.fundPortfolioNo }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'loanNo', index:index, value: newCase.loanNo }));
                            dispatch(updateAdditionalInfoByIndex({ attribute: 'contractNo', index:index, value: newCase.contractNo }));
         
                        }

                    }else{
                    
                        newCaseInvestmentLoanBank.push(newCase.loanFrom);
                        newCaseInvestmentFundCompany.push(newCase.investmentTo);
                        newappliedAmount.push(newCase.amountApplied);
                        newCaseSalesCharge.push(newCase.salesCharge);
                        newCasecaseStatus.push(newCase.caseStatus);
                        newCaseprimeRate.push(newCase.primeRate);
                        newCasrcaseID.push(newCase.caseID);
                        newCasrapplicationID.push(newCase.applicationID);
                        newCasrSubInvestmentAccountType.push(newCase.subInvestmentAccountType);
                        newCasepolicyGuaranteeLevel.push(applicationsPolicy);
                        newCasrcompletedAmount.push(Math.round(newCase.completedAmount * 100) / 100);
                        newCasecompletedDate.push(newCase.completedDate);
                        newCasecontributionAmount.push(Math.round(newCase.contributionAmount * 100) / 100);
                        newCasefelRate.push(newCase.felRate);
                        newCasefelFee.push(Math.round(newCase.felFee * 100) / 100);
                        newCaseloanAccountNo.push(newCase.loanAccountNo);
                        newCasefundPortfolio.push(portfolioName === null? '' : portfolioName);
                        newCasefundPortfolioNo.push(newCase.fundPortfolioNo);
                        newCaseloanNo.push(newCase.loanNo);
                        newCasecontractNo.push(newCase.contractNo);
                    }
                   
                } 
                if(newCasrcaseID.length >0){
                    dispatch(setAdditionalInfo({ attribute: 'InvestmentLoanBank', value: newCaseInvestmentLoanBank }));
                    dispatch(setAdditionalInfo({ attribute: 'InvestmentFundCompany', value: newCaseInvestmentFundCompany }));
                    dispatch(setAdditionalInfo({ attribute: 'appliedAmount', value: newappliedAmount }));
                    dispatch(setAdditionalInfo({ attribute: 'SalesCharge', value: newCaseSalesCharge }));
                    dispatch(setAdditionalInfo({ attribute: 'caseStatus', value: newCasecaseStatus }));
                    dispatch(setAdditionalInfo({ attribute: 'primeRate', value: newCaseprimeRate }));
                    dispatch(setAdditionalInfo({ attribute: 'caseID', value: newCasrcaseID }));
                    dispatch(setAdditionalInfo({ attribute: 'applicationID', value: newCasrapplicationID }));
                    dispatch(setAdditionalInfo({ attribute: 'subInvestmentAccountType', value: newCasrSubInvestmentAccountType }));
                    dispatch(setAdditionalInfo({ attribute: 'policyGuaranteeLevel', value: newCasepolicyGuaranteeLevel }));

                    dispatch(setAdditionalInfo({ attribute: 'completedAmount', value: newCasrcompletedAmount }));
                    dispatch(setAdditionalInfo({ attribute: 'completedDate', value: newCasecompletedDate }));
                    dispatch(setAdditionalInfo({ attribute: 'contributionAmount', value: newCasecontributionAmount }));
                    dispatch(setAdditionalInfo({ attribute: 'felRate', value: newCasefelRate }));
                    dispatch(setAdditionalInfo({ attribute: 'felFee', value: newCasefelFee }));
                    dispatch(setAdditionalInfo({ attribute: 'loanAccountNo', value: newCaseloanAccountNo }));
                    dispatch(setAdditionalInfo({ attribute: 'FundPortfolio', value: newCasefundPortfolio }));
                    dispatch(setAdditionalInfo({ attribute: 'FundPortfolioNo', value: newCasefundPortfolioNo }));
                    dispatch(setAdditionalInfo({ attribute: 'loanNo', value: newCaseloanNo }));
                    dispatch(setAdditionalInfo({ attribute: 'contractNo', value: newCasecontractNo }));
                
                    newCaseInvestmentLoanBank = [];
                    newCaseInvestmentFundCompany = [];
                    newappliedAmount = [];
                    newCaseSalesCharge = [];
                    newCasecaseStatus = [];
                    newCaseprimeRate = [];
                    newCasrcaseID = [];
                    newCasrapplicationID = [];
                    newCasrSubInvestmentAccountType = [];
                    newCasepolicyGuaranteeLevel = [];
                    newCasrcompletedAmount = [];
                    newCasecompletedDate = [];
                    newCasecontributionAmount = [];
                    newCasefelRate = [];
                    newCasefelFee = [];
                    newCaseloanAccountNo = [];
                    newCasefundPortfolio = [];
                    newCaseloanNo = [];
                    newCasefundPortfolioNo = [];
                    newCasecontractNo = [];
                   
                }
        } catch (error) {
            console.error(`Fetch Applications error: ${error}`);
        }
    } 

    useEffect(() => {
        fetchAppPolicys();
    }, [applicationId]);

    // Second useEffect to call fetchAppCases when applicationsPolicy is updated
    useEffect(() => {
        if (applicationsPolicy) {
            fetchAppCases();
        }
    }, [applicationsPolicy]);

    // Here, we only save ID verification info because I only rewrote this part's logics
    const saveInfo = async () => {
        try {
            const payload = {
                applicationID: applicationId,
                idVerifiedDate: additionalInfo.verifiedDate,
                idVerifiedPlace: additionalInfo.verifiedPlace,
                idVerifiedCity: additionalInfo.verifiedCity
            };
            console.log(additionalInfo)
            axiosInstance.post('app/save/id-verification', payload); 
            
        } catch (error) {
            console.error("Error saving ID verification info:", error);
        }
    }

    return (
        <div className="flex items-center justify-center">
            <Box className="w-auto max-w-[1330px] mx-auto h-[80%] h-fit mx-10 MuiBox-root css-0">

                <Box className='mt-24'>
                    <TitleText text={title} />
                </Box>

                <Box className="mt-0">
                    <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
                </Box>

                <Box className='mt-8 ml-2'>
                    <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange}>
                        <Tab style={{ fontSize: 20 }} label="Bank & Investment" value={0} />
                        <Tab style={{ fontSize: 20 }} label="Advisor Info" value={1} />
                        <Tab style={{ fontSize: 20 }} label="ID Verification" value={2} />
                        <Tab style={{ fontSize: 20 }} label="Acquisition Channel" value={3} />
                    </Tabs>
                </Box>

                <Box>
                    <Grid container spacing={2}>

                        <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <BankInvestment xs={{ minWidth: '1253px' }} color={color} applicationsPolicy={applicationsPolicy}   />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <AdvisorInfo xs={{ minWidth: '1253px' }} color={color} applicationId={applicationId} />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 2 ? 'block' : 'none'} >
                            <IdVerification xs={{ minWidth: '1253px' }} color={color} applicationId={applicationId} />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 3 ? 'block' : 'none'} >
                            <AcquisitionChannel xs={{ minWidth: '1253px' }} color={color} applicationId={applicationId}/>
                        </Grid>

                    </Grid>
                </Box>

                {/* <Box className='flex justify-center mt-10'>
                    <SaveButton pageName={pageName} handlePageChange={handlePageChange} />
                </Box> */}
                {tabValue === 3 && (
                    <Box className='flex justify-center mt-10'>
                        <SaveButton pageName={pageName} handlePageChange={handlePageChange} onClick={saveInfo}/>
                    </Box>
                )}
            </Box>
        </div>



    )
}

export default AdditionalInfoPage;