import { Box } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { useState, useEffect } from "react";
import { useApplication } from '../../../contexts/ApplicationContext';
import { isValidPhoneNumber } from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css';


/**
 * @param {function} setStateValue - A function that sets the state value
 * @param {string} defaultValue - The default value of the phone field
 * @param {boolean} disabled - A boolean value that determines if the phone field is disabled
 * @param {string} type - The type of validation to be performed on the phone field with default value as 'optional'
 * @param {string} name - The name of the phone field
 * @param {string} pageName - The name of the page where the phone field is located
 * @param {boolean} buttonCheck - A boolean value that determines if the automatic validation on NextButton has been enabled
 * @returns - A phone field component
 * @description - This component provides validaion for phone input based on country code
 */


export default function PhoneField({ setStateValue, defaultValue, disabled, type = 'optional', name = '', pageName = '', buttonCheck = false }) {

    const { handleErrorMessage } = useApplication();

    const [inputValue, setInputValue] = useState(defaultValue);
    const [selectedCountry, setSelectedCountry] = useState('ca');
    const [error, setError] = useState('');

    const handlePhoneFieldChange = (value, country) => {
        setInputValue(value);
        setSelectedCountry(country);
        setStateValue(value);
    };

    // Provied validation when the phone field is blurred
    const handlePhoneFieldCheck = () => {
        switch (type) {
            case 'phone':
                if (!isValidPhoneNumber(`+${inputValue}`, selectedCountry.countryCode)) {
                    setError('phone');
                    handleErrorMessage(pageName, 'add', `Please validate phone format for ${name}`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `Please validate phone format for ${name}`);
                }
                break;
            case 'optional':
                setError('');
                handleErrorMessage(pageName, 'remove', `Please validate phone format for ${name}`);
                break;
            default:
                break;
        }
    };

    function checkError(error) {
        if (error === 'phone') {
            return 'Valid Phone Required';
        }
    };

    useEffect(() => {
        if (defaultValue !== inputValue) {
            setInputValue(defaultValue);
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    useEffect(() => {
        // Proivde automatic validation when the buttonCheck is enabled
        if (buttonCheck) {
            handlePhoneFieldCheck();
        }
        // Automatically remove the error message when the component is unmounted
        return () => {
            handleErrorMessage(pageName, 'remove', `Please validate phone format for ${name}`);
        }
        // eslint-disable-next-line
    }, [buttonCheck, type]);

    return (
        <Box className='max-w-[250px] w-fit min-h-[70px] mt-[5px] mb-[30px] mx-[25px]'>
            <PhoneInput
                country={'ca'}
                onlyCountries={['ca']}
                value={defaultValue}
                onChange={handlePhoneFieldChange}
                onBlur={handlePhoneFieldCheck}
                disabled={disabled}
                disableDropdown={true}
                countryCodeEditable={false}
                inputClass="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedStart"
                inputStyle={{
                    maxWidth: '240px',
                    width: '15vw',
                    minHeight: '60px',
                    marginTop: '6px',
                    marginLeft: '2px',
                    fontSize: '18px'
                }}
            />
            {error && <p style={{ color: 'red', fontSize: 22, justifyContent: 'center', display: 'flex' }}>{checkError(error)}</p>}
        </Box>
    );
};