export function getFileNamesByUrls(urls) {
    if(!urls) {
        return null;
    }
    if(Array.isArray(urls)){
        const fileNames = urls.map(url => decodeURIComponent(url).split('/').pop());
        return fileNames;
    } else if (typeof urls === 'string') {
        return decodeURIComponent(urls).split('/').pop();
    }
}

export function isFileValid(val) {
    if(Array.isArray(val) && val.length > 0) {
        return true;
    } else if (val instanceof File){
        return true;
    }else if (val && val.isFetched) {
        return true;
    } else {
        return false;
    }
}