import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import { setCoapplicantInfo } from "../../../../reducers/coapplicantReducer";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../components/smallComponents/textField/TextField";
import "../general/section/style/MainApplicantSection.css";
import { useEffect, useState } from "react";
import { useApplication } from "../../../../contexts/ApplicationContext";
import TextFieldWithHelper from "../../../../components/smallComponents/helper/Helper";
import BasicSelect from "../../../../components/smallComponents/dropdownList/BasicSelect";
import TextFieldNew from "../../../../components/smallComponents/textField/TextFieldNew";
import { ErrorSharp } from "@mui/icons-material";



/**
 * Renders the Employment Information section of the application page.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.EmpStatus - The array of employment status options.
 * @returns {JSX.Element} The rendered component.
 */
export default function EmploymentInformation({ EmpStatus, enable, applicantType, errors = {}}) {
    const itemSpace = 3;
    const [empStatusError, setEmpStatusError] = useState('');
    const dispatch = useDispatch();
    let applicant;
    const mainApplicant = useSelector((state) => state.applicantInfo);
    const coApplicant = useSelector((state) => state.coapplicantInfo);
    if(applicantType === "Applicant") {
        applicant = mainApplicant;
    } else {
        applicant = coApplicant;
    }
    const { clickNextButton } = useApplication();
    const pageName = 'employment'



    const handleApplicantChange = (attribute, value) => {
        if(applicantType === "Applicant") {
            dispatch(setApplicantInfo({ attribute, value }));
        } else if(applicantType === "Co-Applicant") {
            dispatch(setCoapplicantInfo({ attribute, value }));
        }
    };




    return (
        <div style={{ display: enable ? 'block' : 'none' }}>
            <Box>
                <Box className="section-main-box-field">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={itemSpace}>
                                    <Box>
                                        <TextFieldWithHelper largeBox={true} text='Employment Status' helperText="Please indicate your current employment status (e.g., Employed, Self-employed, Student, Retired).
 If the policyowner is unemployed or retired, the policyowner’s previous occupation, employer and the nature of the previous employer's business (if applicable) must be provided."/>
                                        <BasicSelect
                                            value={applicant.employmentStatus || ''}
                                            options={EmpStatus}
                                            handleChange={value => handleApplicantChange("employmentStatus", value)}
                                            error={errors.employmentStatus}
                                        />
                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box>
                                        <TextFieldWithHelper helperText="For example - Self-employed electrician"
                                            text='Detailed Occupation/Job Title' largeBox={true}/>
                                        <TextFieldNew
                                            onChange={value => handleApplicantChange("jobTitle", value)}
                                            value={applicant.jobTitle}
                                            longBox = {true}
                                            disabled={applicant.employmentStatus === "Student" || applicant.employmentStatus === "Retired" || applicant.employmentStatus === ""}
                                            error={errors.jobTitle}
                                            />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box>
                                        <TextFieldWithHelper helperText="For example - Design, install and repair electrical systems"
                                            text='Nature of Job Responsibilities' largeBox={true}/>
                                        <TextFieldNew
                                            onChange={value => handleApplicantChange("natureOfJobResponsibilities", value)}
                                            longBox = {true}
                                            value={applicant.natureOfJobResponsibilities}
                                            disabled={applicant.employmentStatus === "Student" || applicant.employmentStatus === "Retired" || applicant.employmentStatus === ""}
                                            error={errors.natureOfJobResponsibilities}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box>
                                        <TextFieldWithHelper helperText="For example - ABC Electric Inc."
                                            text='Employment Name' />
                                        <TextFieldNew
                                            onChange={value => handleApplicantChange("employmentName", value)}
                                            value={applicant.employmentName}
                                            longBox = {true}
                                            disabled={applicant.employmentStatus === "Student" || applicant.employmentStatus === "Retired" || applicant.employmentStatus === ""}
                                            error={errors.employmentName}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box>
                                        <TextFieldWithHelper helperText="For example – Provide residential electrical systems services"
                                            text="Nature of Employer's Business" largeBox={true}/>
                                        <TextFieldNew
                                            onChange={value => handleApplicantChange("natureOfEmployersBusiness", value)}
                                            value={applicant.natureOfEmployersBusiness}
                                            longBox = {true}
                                            disabled={applicant.employmentStatus === "Student" || applicant.employmentStatus === "Retired" || applicant.employmentStatus === ""}
                                            error={errors.natureOfEmployersBusiness}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}