/**
 * Component for displaying the requirements and document information for a quick loan application.
 * @returns {JSX.Element} QuickNext component.
 */
import React from 'react';
import { useState, useEffect } from 'react';
import HeaderBox from '../../../components/smallComponents/headerBox/headerBox';
import './QuickNext.css'
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useApplication } from '../../../contexts/ApplicationContext';
import { fetchConfig } from '../../../services/fetchConfigService';

function QuickNext() {
    const { setSelectedComponent } = useApplication();
    const chargeFeeTitle = "How Ai Financial (AiF) charges a service fee";
    const chargeFeeDesc = 
    `The investment/insurance company will automatically charge a one-time fee of 5% on the investment loan you borrow from the investment bank, and our service fee is a portion of that. Therefore, you do NOT need to pay AiF additionally. 
    (For example: if you borrow $100,000 from the investment bank, $5,000 will be charged. Your initial principal will become $95,000)`
    const [ minimumScore, setMinimumScore ] = useState("");
    useEffect(() => {
        fetchConfig("Por_App_CreditReport", "CreditScoreRequired").then((score) => {
            setMinimumScore(score);
        }); 
    }, []);
    return (
        <>
        <HeaderBox text='Requirements: Quick Loan' />
        {!minimumScore? <Box><div className="textPage"></div></Box>: <Box>
            <div className="textPage">
            <p>Eligible applicants are:</p>
            <ul className='ulQuick'>
                <li>at-least 25-year-old</li>
                <li>either Canadian Citizen or Canadian Permanent Resident</li>
                <li>Canadian tax resident</li>
                <li>not identified as <a target="_blank" href="https://fintrac-canafe.canada.ca/guidance-directives/client-clientele/pep/pep-eng">PEP/HIO</a></li>
                <li>having credit score higher than {minimumScore} (Not sure? Check your credit score for free <a target="_blank" href="https://www.consumer.equifax.ca/personal/education/credit-report/articles/-/learn/how-to-get-a-free-credit-report/#online">Here</a>)</li>
                <li>having at least 2-year living and working experience in Canada</li>
            </ul>
            <br/>
            <p>You will required to prepare and upload following documents during the application: </p>
            <ul className='ulQuick'>
                <li>Two pieces of government-issued photo IDs</li>
                <li>Two pieces personal identification information documents</li>
                <li>Void Cheque</li>
                <li>Credit Score Report</li>
            </ul>
            <br/>
            <p className='font-medium'>{chargeFeeTitle}</p>
            <article style={{ whiteSpace: "pre-line" }}>{chargeFeeDesc}</article>
            </div>
            <div className='button-Area mb-6'>
                    <NavLink onClick={() => setSelectedComponent('Quick')} className={'backButton'}>
                        <button className="bigButton">Back</button>
                    </NavLink>
                    <NavLink onClick={() => setSelectedComponent('BeforeApp')} className={'nextButton'}>
                        <button className="bigButton">Next</button>
                    </NavLink>
                    
            </div>

        </Box>}
        </>
    );
}

export default QuickNext;