import { Box, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";

/**
 * @param {string[]} fieldNames - Array of strings that represent the names of the column fields
 * @param {Array[]} fieldValues - Array of arrays that represent the values of the column fields
 * @param {Function} handleDelete - Function that handles the deletion of a row
 * @param {Function} handleEdit - Function that handles the edit of a row
 * @param {Function} setRowIndex - The rowIndex of the table that user clicks on
 * @param {boolean} disableEdit - Disable the edit button if any input fields are not empty, requiring submission/clearing before editing another one
 * @returns - Table component that displays the field names and values 
 */


export default function ModifyTable({ fieldNames, fieldValues, handleDelete, handleEdit, setRowIndex = () => {}, disableEdit, displayHorizonally = false, fieldKeys=[] }) {

    const handleRowClick =(rowIdx)=>{
        setRowIndex(rowIdx);
    }

    return (
        <Box className="overflow-x-auto w-full mt-8 max-h-[300px] overflow-y-auto">

            <table className="min-w-full divide-y divide-gray-300 border border-gray-300">

                <thead className="bg-gray-200">
                    <tr>
                        {fieldNames.map((fieldName, index) => (
                            <th
                                key={index}
                                className="px-4 py-4 text-left text-base font-medium text-gray-500 uppercase tracking-wider"
                            >
                                {fieldName}
                            </th>
                        ))}
                        <th className="px-4 py-4 text-center text-base font-medium text-gray-500 uppercase tracking-wider" colSpan={2}>
                            Actions
                        </th>
                    </tr>
                </thead>
                {/* wrote for commentBlock for more convenient data display */}
                {displayHorizonally? (
                    <tbody className="bg-white divide-y divide-gray-300">
                    {fieldValues.length > 0 ? (
                        fieldValues.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {/* Render row data dynamically */}
                                {fieldKeys.map((header, colIndex) => (
                                    <td
                                        key={colIndex}
                                        className="px-4 py-4 whitespace-nowrap text-base text-gray-500"
                                        onClick={() => handleRowClick(rowIndex)}
                                    >
                                    {row[header]}
                                    </td>
                                ))}
                                {/* Action buttons */}
                                <td className="px-4 py-4 whitespace-nowrap text-base font-medium">
                                    <IconButton disabled={disableEdit} onClick={() => handleEdit(fieldValues[rowIndex])}>
                                        <Edit />
                                    </IconButton>
                                </td>
                                <td className="px-4 py-4 whitespace-nowrap text-base font-medium">
                                    <IconButton onClick={() => handleDelete(fieldValues[rowIndex])}>
                                        <Delete />
                                    </IconButton>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className="text-base" colSpan={fieldKeys.length + 1} style={{ textAlign: 'center' }}>
                                No data found.
                            </td>
                        </tr>
                    )}
                </tbody>) : (
                // Old code for other components
                <tbody className="bg-white divide-y divide-gray-300">
                    {fieldValues[0]?.map((_, rowIndex) => (
                        <tr key={rowIndex}>
                            {fieldValues.map((valueArray, colIndex) => (
                                <td
                                    key={colIndex}
                                    className="px-4 py-4 whitespace-nowrap text-base text-gray-500"
                                    onClick={()=>handleRowClick(rowIndex)}
                                >
                                    {valueArray[rowIndex]}
                                </td>
                            ))}
                            <td className="px-4 py-4 whitespace-nowrap text-base font-medium">
                                <IconButton disabled={disableEdit}
                                    onClick={() => handleEdit(rowIndex)}
                                >
                                    <Edit />
                                </IconButton>
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap text-base font-medium">
                                {/* For now, disabledDelete shares with disableEdit */}
                                <IconButton disabled={disableEdit}
                                    onClick={() => handleDelete(rowIndex)}
                                >
                                    <Delete />
                                </IconButton>
                            </td>
                        </tr>
                    )) || (
                        <tr>
                            <td className="text-base" colSpan="9" style={{"textAlign": "center"}}>No data found.</td>
                        </tr>
                        )}
                </tbody>
            )}
            </table>

        </Box>
    );
};