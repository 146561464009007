import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Typography } from '@mui/material';

export default function CheckboxNew({value = false, label = '', handleChange = () => {}}) {
    const onChange = (event) => {
        handleChange(event.target.checked);
    };

  return (
    <FormControlLabel
        label={<Typography sx={{fontSize: '22px'}}>{label}</Typography>}
        control={
            <Checkbox
            checked={value}
            onChange={onChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />}
    />
  );
}