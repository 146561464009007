import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextFieldText from '../../../components/smallComponents/text/textFieldText/TextFieldText';
import TextField from "../../../components/smallComponents/textField/TextField";
import { useDispatch, useSelector } from 'react-redux';
import { setAdditionalInfo } from "../../../reducers/additionalInfoReducer";
import { useApplication } from '../../../contexts/ApplicationContext';
import DropdownList from '../../../components/smallComponents/dropdownList/DropdownList';
import { saveInfo } from '../../../services/saveInfoService';
import { getDropdownList } from '../../../services/dropdownService';
import { axiosInstance } from '../../../axios';

const AdvisorInfo = ({ color, applicationId }) => {
    const dispatch = useDispatch();
    const additionalDetails = useSelector((state) => state.additionalInfo);
    const { clickNextButton } = useApplication();

    const [advisors, setAdvisors] = useState([]);
    const [initialSupportingAdvisorCode, setInitialSupportingAdvisorCode] = useState(null);
    const [initialServingAdvisorCode, setInitialServingAdvisorCode] = useState(null);
    const [advisorFound, setAdvisorFound] = useState(true);
    const pageName = 'additional';

    // Fetch advisor data and prepopulate
    useEffect(() => {
        const fetchAdvisors = async () => {
            try {

                const advisorsRes = await getDropdownList("advisors");
                setAdvisors(advisorsRes || []);  // Set advisors to an empty array if fetch fails
    
                const applicationResponse = await axiosInstance.get(`application/advisors/${applicationId}`);
                if (applicationResponse.data) {
                    const { supportingAdvisorCode, servingAdvisorCode } = applicationResponse.data;
    
                    // Dispatch the supporting and serving advisor IDs and set initial values
                    dispatch(setAdditionalInfo({
                        attribute: 'supportingAdvisorCode',
                        value: supportingAdvisorCode || ''
                    }));
                    dispatch(setAdditionalInfo({
                        attribute: 'servingAdvisorCode',
                        value: servingAdvisorCode || ''
                    }));
    
                    // Set initial advisor IDs to track changes
                    setInitialSupportingAdvisorCode(supportingAdvisorCode || '');
                    setInitialServingAdvisorCode(servingAdvisorCode || '');
                }
            } catch (error) {
                console.error('Error fetching advisor details:', error);
            }
        };
        
        fetchAdvisors();
    }, [applicationId, dispatch]);

    const advisorsIDs = Array.isArray(advisors) ? advisors.map(adv => String(adv.advisorCode)) : [];
    // Save advisor information to the backend
    const saveAdvisorInfo = async () => {
        const matchingServeringAdvisorID = Array.isArray(advisors) 
        ? advisors.find(adv => String(adv.advisorCode) === additionalDetails.servingAdvisorCode)
        : null;
        const matchingSupportingAdvisorID = Array.isArray(advisors) 
        ? advisors.find(adv => String(adv.advisorCode) === additionalDetails.supportingAdvisorCode)
        : null;

        try {
             const payload = {
                applicationID: applicationId,
                supportingAdvisorID: matchingSupportingAdvisorID.advisorID,
                supportingAdvisorCode: additionalDetails.supportingAdvisorCode,
                servingAdvisorID: matchingServeringAdvisorID.advisorID,
                servingAdvisorCode: additionalDetails.servingAdvisorCode,
            };

            await saveInfo("additional", payload);
        } catch (error) {
            console.error("Error saving advisor info:", error);
        }
    };

    // Trigger save only if the supporting or serving advisor IDs have changed
    useEffect(() => {
        if(advisorFound){
            if (
                initialSupportingAdvisorCode !== null &&
                initialServingAdvisorCode !== null &&
                (
                    additionalDetails.supportingAdvisorCode !== initialSupportingAdvisorCode ||
                    additionalDetails.servingAdvisorCode !== initialServingAdvisorCode
                )
            ) {
                saveAdvisorInfo();
            }
        }
       
      
    }, [additionalDetails.supportingAdvisorCode, additionalDetails.servingAdvisorCode, initialSupportingAdvisorCode, initialServingAdvisorCode, advisorFound]);

    // Change handler for Supporting Advisor ID
    const handleApplicantChange = (attribute, value) => {
        dispatch(setAdditionalInfo({ attribute, value }));
    };

    // Validation for Serving Advisor ID
    const handleServingAdvisorCodeChange = (value) => {
        if (!value) {
            setAdvisorFound(true);
            handleApplicantChange('isSystemAssignAdvisor', true);
        } else if (advisorsIDs.includes(value.toUpperCase())) {
            setAdvisorFound(true);
            handleApplicantChange('isSystemAssignAdvisor', false);
            handleApplicantChange('servingAdvisorCode', value.toUpperCase());
        } else {
            setAdvisorFound(false);
            handleApplicantChange('isSystemAssignAdvisor', false);
            handleApplicantChange('servingAdvisorCode', value.toUpperCase());
        }
    };

     // Validation for Serving Advisor ID
     const handleSupportingAdvisorCodeChange = (value) => {
        if (!value) {
            setAdvisorFound(true);
            handleApplicantChange('isSystemAssignAdvisor', true);
        } else if (advisorsIDs.includes(value.toUpperCase())) {
            setAdvisorFound(true);
            handleApplicantChange('isSystemAssignAdvisor', false);
            handleApplicantChange('supportingAdvisorCode', value.toUpperCase());
        } else {
            setAdvisorFound(false);
            handleApplicantChange('isSystemAssignAdvisor', false);
            handleApplicantChange('supportingAdvisorCode', value.toUpperCase());
        }
    };

    return (
        <Box>
            <Box className='mt-10 rounded-xl shadow-lg text-black-950 py-5' bgcolor={color}>
            <Box className='flex justify-center items-center mt-5 gap-10'>
                    <TextFieldText text="Serving Advisor Code" />
                    <TextField
                        name="Serving Advisor Code"
                        defaultValue={String(additionalDetails.servingAdvisorCode) || ""}
                        buttonCheck={true}
                        type={additionalDetails.servingAdvisorCode.length > 0? '7chars': 'optional'}
                        err={advisorFound ? "" : "Advisor not found"}
                        setStateValue={handleServingAdvisorCodeChange}
                        requiredProp={false}
                    />
                </Box>
                <Box className='flex justify-center items-center mt-5 gap-10'>
                    <TextFieldText text="Supporting Advisor Code" />
                    <TextField
                        options={advisorsIDs}
                        defaultValue={String(additionalDetails.supportingAdvisorCode) || ""}
                        setStateValue={handleSupportingAdvisorCodeChange}
                        name="Supporting Advisor's Code"
                        type={additionalDetails.supportingAdvisorCode.length > 0? '7chars': 'optional'}
                        err={advisorFound ? "" : "Advisor not found"}
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                    />
                </Box>
                
            </Box>
        </Box>
    );
};

export default AdvisorInfo;
