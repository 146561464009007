import React, { useState, useEffect } from 'react';
import { Grid, Box, Collapse, IconButton, styled } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { setCoapplicantInfo } from "../../../../reducers/coapplicantReducer"; // Updated reducer
import { useParams } from 'react-router-dom';
import { useApplication } from "../../../../contexts/ApplicationContext";
import TextFieldText from '../../../../components/smallComponents/text/textFieldText/TextFieldText';
import DocUploader, { FileLocation } from '../../../../components/mediumComponents/uploadDoc/docUploader';
import DropdownList from '../../../../components/smallComponents/dropdownList/DropdownList';
import TextField from '../../../../components/smallComponents/textField/TextField';
import DateField from '../../../../components/smallComponents/dateField/DateField';
import TitleText from '../../../../components/smallComponents/text/titleText/TitleText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputFileUpload from '../../../../components/mediumComponents/uploadDoc/FileUploader';
import { useRawFiles } from '../../../../contexts/FileContext';
import TextFieldWithHelper from '../../../../components/smallComponents/helper/Helper';
import { getFileNamesByUrls } from '../../../../utilities/fileHelper';

export default function CoAppIdVerification({ IDType, enable }) {
    const { clickNextButton, handleErrorMessage } = useApplication();
    const itemSpace = 3;
    const pageName = 'identification';
    const { rawFiles, saveRawFile } = useRawFiles();


    const dispatch = useDispatch();
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const applicationType = useParams().applicationType;

    const handleCoapplicantChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }));
    };

    
    useEffect(() => {
        if (coapplicant.coApplicantPersonalID_1Urls?.length > 0) {
            saveRawFile('coApplicantPersonalID_1Urls', {name: getFileNamesByUrls(coapplicant.coApplicantPersonalID_1Urls[0]), isFetched: true});
        }
    }, [coapplicant.coApplicantPersonalID_1Urls]);

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const [expanded, setExpanded] = useState(false);
    const [filledSection, setFieldSection] = useState(false);
    const [isMandatory, setIsMandatory] = useState(false);
    const [enabled, setEnabled] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    // Validate fields
    useEffect(() => {
        const mandatoryFields = {
            coApplicantPersonalIDType: coapplicant.coApplicantPersonalIDType?.trim() === "",
            coApplicantPersonalIDIssuingAuthority: coapplicant.coApplicantPersonalIDIssuingAuthority?.trim() === "",
            coApplicantPersonalIDExpiryDate: coapplicant.coApplicantPersonalIDExpiryDate?.trim() === "",
            coApplicantPersonalIDAccountReference: coapplicant.coApplicantPersonalIDAccountReference?.trim() === "",
            coApplicantPersonalID_1Urls: coapplicant.coApplicantPersonalID_1Urls?.length === 0
        };

        const allFieldsFilled = Object.values(mandatoryFields).every((field) => !field);
        setFieldSection(allFieldsFilled);
    }, [
        coapplicant.coApplicantPersonalIDType,
        coapplicant.coApplicantPersonalIDIssuingAuthority,
        coapplicant.coApplicantPersonalIDExpiryDate,
        coapplicant.coApplicantPersonalIDAccountReference,
        coapplicant.coApplicantPersonalID_1Urls
    ]);

    useEffect(() => {
        const isMandatory = coapplicant.verified === false || coapplicant.verified_2 === false;
        if (expanded && !filledSection) {
            handleErrorMessage(pageName, 'add', 'All Co-Applicant ID Verification Fields are mandatory!');
        } else {
            handleErrorMessage(pageName, 'remove', 'All Co-Applicant ID Verification Fields are mandatory!');
        }
        setIsMandatory(isMandatory);
        setEnabled(isMandatory);
    }, [expanded, filledSection, coapplicant.verified, coapplicant.verified_2]);

    const saveFile = (attribute, value) => {
        saveRawFile(attribute, value);
    }

    return (
        <Box display={enabled && enable ? 'block' : 'none'}>
            <Box className="section-main-box-field">
                <TitleText text="Co-Applicant ID Verification" />

                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon fontSize="large" />
                    {!expanded && (
                        <p style={{ color: 'grey', fontSize: 18, justifyContent: 'center', display: 'flex', fontWeight: 'bold' }}>
                            Fill Co-Applicant ID Verification Field
                        </p>
                    )}
                </ExpandMore>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>

                        <Grid item xs={itemSpace}>
                            <TextFieldText text='Verification Document Type' />
                            <DropdownList
                                options={IDType}
                                setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDType", value)}
                                defaultValue={coapplicant.coApplicantPersonalIDType}
                                type={expanded && isMandatory ? 'mandatory' : 'optional'}
                                name='Verification Document Type'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <TextFieldWithHelper text='Verification Document Issuing Authority' helperText="Please provide the name of the authority or agency that issued your ID"/>
                            <TextField
                                setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDIssuingAuthority", value)}
                                defaultValue={coapplicant.coApplicantPersonalIDIssuingAuthority}
                                type={expanded && isMandatory ? 'mandatory' : 'optional'}
                                name='Verification Document Issuing Authority'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <TextFieldText text='Verification Document Account/Reference number' />
                            <TextField
                                setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDAccountReference", value)}
                                defaultValue={coapplicant.coApplicantPersonalIDAccountReference}
                                type={expanded && isMandatory ? 'mandatory' : 'optional'}
                                name='Verification Document Account/Reference number'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <TextFieldWithHelper text='ID Verification Document Date of information' helperText="Please provide the expiry date of your ID or information Date of your document"/>
                            <DateField
                                setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDExpiryDate", value)}
                                defaultValue={coapplicant.coApplicantPersonalIDExpiryDate}
                                type={expanded && isMandatory ? 'date' : 'optional'}
                                name='ID Verification Document Date of information'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <TextFieldText text='Upload Verification Document' />
                            <InputFileUpload 
                                uploadedFile={rawFiles.coApplicantPersonalID_1Urls}
                                setUploadedFile={value => saveFile("coApplicantPersonalID_1Urls", value)} />
                        </Grid>
                    </Grid>
                </Collapse>
            </Box>
        </Box>
    );
}