import { Box, FormControlLabel, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../../reducers/applicantReducer";
import { useApplication } from "../../../../../contexts/ApplicationContext";
import { useParams } from 'react-router-dom';
import dayjs from "dayjs";
import TextFieldText from "../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import PhoneField from "../../../../../components/smallComponents/phoneField/PhoneField";
import TextField from "../../../../../components/smallComponents/textField/TextField";
import Checkbox from "@mui/material/Checkbox";
import DateField from "../../../../../components/smallComponents/dateField/DateField";
import { currAddr, prevAddr } from "../../../../../constants/lists";
import "./style/MainAppContactSection.css"
import { twoPhoneNumbersVerified } from "../../../../../utilities/twoPhoneNumbersVerification";
import { usePhoneTypes } from "../../../../../hooks/usePhoneTypes";
import { useState } from "react";
import TextFieldWithHelper from "../../../../../components/smallComponents/helper/Helper";
import GoogleAPI from "../../../../../components/mediumComponents/AddressAutoComplete/GoogleAPI";
import AddressSection from "../../../../../components/mediumComponents/AddressAutoComplete/AddressSection";
import { isValidPostalCode } from "../../../../../services/dataValidationService";
import PopupDialog from "../../../../../components/smallComponents/alert/PopupDialog";
/**
 * @param {boolean} enable - Enable the MainAppContactSection component to be displayed
 * @returns - The main applicant contact info section of the contact info field component
 */
export default function MainAppContactSection({ enable, setAllowNext = () => {} }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currAddrErrors, setCurrAddrErrors] = useState({});
    const [prevAddrErrors, setPrevAddrErrors] = useState({});
    const applicant = useSelector((state) => state.applicantInfo);
    const livingMonthKey = 24;

    const { handleErrorMessage, clickNextButton } = useApplication();

    const dispatch = useDispatch();

    const applicationType = useParams().applicationType;

    const isLoanType = ['regular', 'quick', 'transfer'].includes(applicationType);
    const lessThanRequiredMonths = applicant.livingSince !== '' && dayjs().diff(dayjs(applicant.livingSince), 'month') < livingMonthKey;

    const handlePhoneChange = (attribute, value) => {
        let twoDifferentPhones = twoPhoneNumbersVerified(attribute, value, applicant);
        if(twoDifferentPhones){
            handleErrorMessage(pageName, 'remove', 'Two different phone numbers are required for applicant')
            dispatch(setApplicantInfo({ attribute, value }))
        } else {
            handleErrorMessage(pageName, 'add', 'Two different phone numbers are required for applicant')
        }
    }

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const handleApplicantCurrLivingChange = (attribute, value) => {
        // If the main applicant has lived at the current address for more than 24 months, clear the previous address
        if (dayjs().diff(dayjs(value), 'month') > livingMonthKey) {
            for (let i = 0; i < prevAddr.length; i++) {
                handleApplicantChange(prevAddr[i], "");
            }
            handleApplicantChange("prevLivingSince", "");
            handleErrorMessage(pageName, 'remove', `Applicant Previous Address is mandatory`)
        }
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const itemSpace = 6;
    const specialItemSpace = 12;

    const manualInputQuestion = "Can't find your address?";
    const applicantType = 'Applicant';
    const pageName = 'contact';

    const dispatchAddr = (type, addrObj) => {
        if(type === 'curr'){
            Object.entries(addrObj).forEach(([key, value]) => {
                dispatch(setApplicantInfo({ attribute: key, value: value }));
            });
        } else if(type === 'prev'){
            Object.entries(addrObj).forEach(([key, value]) => {
                let prevKey = "prev" + key.charAt(0).toUpperCase() + key.slice(1);
                dispatch(setApplicantInfo({ attribute: prevKey, value: value }));
            });
        }
    }


    useEffect(() => {

        const isCurrAddressValid = (addrObj) => {
                let newErrors = { ...currAddrErrors}
                Object.entries(addrObj).forEach(([key, value]) => {
                    if(!value) {
                        newErrors[key] = 'Cannot be empty';
                    } else {
                        if(key === 'province') {
                            if(value.trim() !== '' && !['ON', 'BC', 'QC', 'AB'].includes(value.toUpperCase())) {
                                setDialogOpen(true);
                                newErrors[key] = 'Ineligible province';
                                return;
                            } else {
                                newErrors[key] = '';
                            }
                        } else if(key === 'postalCode' && !isValidPostalCode(value)) {
                            newErrors[key] = 'Invalid format';
                        } else {
                            newErrors[key] = '';
                        }
                    }
                });
                setCurrAddrErrors(newErrors);
        }

        isCurrAddressValid({
            streetNumber: applicant.streetNumber,
            streetName: applicant.streetName,
            city: applicant.city,
            province: applicant.province,
            postalCode: applicant.postalCode
        });
    
    }, [applicant.streetNumber, applicant.streetName, applicant.city, applicant.province, applicant.postalCode]);


    useEffect(() => {

        const isPrevAddressValid = (addrObj) => {
                let newErrors = { ...prevAddrErrors}
                Object.entries(addrObj).forEach(([key, value]) => {
                    if(!value) {
                        newErrors[key] = 'Cannot be empty';
                    } else {
                        if(key === 'postalCode' && !isValidPostalCode(value)) {
                            newErrors[key] = 'Invalid format';
                        } else {
                            newErrors[key] = '';
                        }
                    }
                });
                setPrevAddrErrors(newErrors);
        }

        isPrevAddressValid({
            streetNumber: applicant.prevStreetNumber,
            streetName: applicant.prevStreetName,
            city: applicant.prevCity,
            province: applicant.prevProvince,
            postalCode: applicant.prevPostalCode
        });
    
    }, [applicant.prevStreetNumber, applicant.prevStreetName, applicant.prevCity, applicant.prevProvince, applicant.prevPostalCode]);

    useEffect(() => {
        // if all the values in errors are '' then set allowNext to true
        if(Object.values(currAddrErrors).every(x => x === '')){
            if(lessThanRequiredMonths){
                if(Object.values(prevAddrErrors).every(x => x === '')){
                    setAllowNext(true);
                }
            } else {
                setAllowNext(true);
            }
        } else {
            setAllowNext(false);
        }
    }, [currAddrErrors, prevAddrErrors]);

    return (
        <Box display={enable ? 'block' : 'none'} >

            <Box className="section-main-box-field">

                <Grid container spacing={2}>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Email' />
                        <TextField
                            setStateValue={value => handleApplicantChange("email", value)}
                            defaultValue={applicant.email}
                            type='email'
                            name='Applicant Email'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                            largeBox={true}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Home Phone' />
                        <PhoneField
                            setStateValue={value => handlePhoneChange("homePhone", value)}
                            defaultValue={applicant.homePhone}
                            type='phone'
                            name='Applicant Home Phone'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldWithHelper text='Work Phone' helperText="your work phone number should be different from your home phone number."/>
                        <PhoneField
                            setStateValue={value => handlePhoneChange("workPhone", value)}
                            defaultValue={applicant.workPhone}
                            type='phone'
                            name='Applicant Work Phone'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Cell Phone' />
                        <PhoneField
                            setStateValue={value => handlePhoneChange("cellPhone", value)}
                            defaultValue={applicant.cellPhone}
                            type='optional'
                            name='Applicant Cell Phone'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                </Grid>

            </Box>

            <Box className="section-main-box-field">

                <Grid container spacing={2}>
                
                    <Grid xs={specialItemSpace} >
                        <TextFieldText text='Current Address' />
                        <AddressSection 
                            addrObj={{
                                unit: applicant.unit,
                                streetNumber: applicant.streetNumber,
                                streetName: applicant.streetName,
                                city: applicant.city,
                                province: applicant.province,
                                postalCode: applicant.postalCode
                            }}
                            dispatchAddress={(obj) => dispatchAddr('curr', obj)}
                            errors={currAddrErrors}
                            />
                    </Grid>

                    {isLoanType && (
                        <Grid item xs={specialItemSpace} >
                            <TextFieldWithHelper text='Current Living Since' helperText="Please provide the date you began living at your current address." />
                            <DateField
                                setStateValue={value => handleApplicantCurrLivingChange("livingSince", value)}
                                defaultValue={applicant.livingSince}
                                type='date'
                                name='Applicant Current Living Since'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>
                    )}

                </Grid>

            </Box>

            <Box className="section-main-box-field">

                {isLoanType && (
                    <Grid container spacing={2} display={lessThanRequiredMonths ? 'block' : 'none'} >

                        <Grid item xs={specialItemSpace} >
                        <TextFieldText text='Previous Address' />
                            <AddressSection
                                addrObj={{
                                    unit: applicant.prevUnit,
                                    streetNumber: applicant.prevStreetNumber,
                                    streetName: applicant.prevStreetName,
                                    city: applicant.prevCity,
                                    province: applicant.prevProvince,
                                    postalCode: applicant.prevPostalCode
                                }}
                                dispatchAddress={(obj) => dispatchAddr('prev', obj)}
                                errors={prevAddrErrors}
                            />
                        </Grid>

                        <Grid item xs={specialItemSpace} >
                            <TextFieldText text='Previous Living Since' />
                            <DateField
                                setStateValue={value => handleApplicantChange("prevLivingSince", value)}
                                defaultValue={applicant.prevLivingSince}
                                type={lessThanRequiredMonths ? 'date' : 'optional'}
                                name='Applicant Previous Living Since'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                    </Grid>
                )}

            </Box>
            <PopupDialog
                open={dialogOpen}
                closeOption="OK"
                onClose={() => setDialogOpen(false)}
                message={"Your current province is not eligible for our ongoing promotion. Please contact us for assistance."}
            />

        </Box>
    );
};