import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import { Button, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import TextFieldText from '../text/textFieldText/TextFieldText';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

export default function TextFieldWithHelper({text = "", helperText = "", helperDetail = "", largeBox = false}) {

    // Based on TextFieldText.jsx
    const textFieldTitleClasses =  (helperText? "underline decoration-dotted decoration-gray-500 underline-offset-4 ": "") + 
    "leading-8 text-[#3C3C3C] text-2xl font-normal font-sans min-h-[47px] " 
    + (largeBox ?
        'whitespace-nowrap' :
        'max-w-[230px] w-auto');

    const TextFieldTitle = () => {
        return (
            <span className={textFieldTitleClasses}>
                {text}
            </span>
        )
    }
    // Supports <a> tag
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: 'white',
          color: 'rgba(0, 0, 0, 0.75)',
          maxWidth: 400,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
      }));

      const stackClasses = (text? "ml-[30px] ": "")
      + (largeBox ?
          'w-fit min-h-[50px] bg-transparent mt-[5px] mb-[10px]' :
          'max-w-[240px] w-fit min-h-[10px] bg-transparent mt-[5px] mb-[20px]');

      return (
        <Stack direction="row" alignItems="flex-start" className={stackClasses}>
            {text && <TextFieldTitle/>}
            <HtmlTooltip
                title={
                    <React.Fragment>
                    <Typography color="inherit" sx={{ whiteSpace: "pre-line" }}>{helperText}</Typography>
                    {helperDetail && <article sx={{ whiteSpace: "pre-line" }}>{`${helperDetail}`}</article>}
                </React.Fragment>
                }
            >
                {helperText && <HelpOutlineIcon color="disabled"/>}
            </HtmlTooltip>
        </Stack>
      );
  }

