import React, { useEffect, useState } from "react";
import { Alert, Box, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAdditionalInfo } from "../../../../reducers/additionalInfoReducer";
import { useApplication } from "../../../../contexts/ApplicationContext";
import ModifyTable from "../../../../components/mediumComponents/table/ModifyTable";
import AdvisorSection from "./AdvisorSection";
import { useParams } from "react-router-dom";
import { fetchPortfolio, fetchApplicantsourceofConInfo } from '../../../../services/fetchInfoService';
import { axiosInstance  } from "../../../../axios";
/**
 * @description - This component is used for inputing, adding and displaying loan and fund related info 
 * @param {string} color - Background color 
 * @returns - BankInvestment subsection component as part of AddtionalInfoPage
 */

const BankInvestment = ({ color, applicationsPolicy }) => {

    const params = useParams();
    const dispatch = useDispatch();
    const { handleErrorMessage } = useApplication();
    const [originalStatus, setOriginalStatus] = useState(null);
    let coIndex = 0;
    const [caseEdit, setCaseEdit] = useState(false);
    const additionalInfo = useSelector((state) => state.additionalInfo);;
    const userTypes = useSelector((state) => state.userInfo.userInfos.map((info) => info.userType));
    const userName = useSelector((state) => state.userInfo.userName);
    const userInfo = useSelector((state) => state.userInfo);
    const [portfolios, setPortfolios] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [applyAmountCurrent, setApplyAmountCurrent] = useState(0);
    const [applyAmountbyApp, setApplyAmountbyApp] = useState(0);
    const [investFund, setInvestFund] = useState({
        loanBanks: '',
        fundCompanies: '',
        appliedAmount: 0,
        subAccountType: 'Segregated Fund',
        salesCharge: 'FEL',
        portfolio: '',
        felRate: '',
        felFee: 0,
        caseStatus: 'Under Review',
        completedAmount: 0,
        completedDate: null,
        loanAccountNo: '',
        loanNo: '',
        contractNo: '',
        contributionAmount: 0,
        primeRate: 5,
        policyGuaranteeLevel:'',
        caseID:'',
        applicationID:'',
        portfolioNo:''
    });

    // Array of strings that represent the names of the column fields in table component
    const operatorTable = [
        { key: 'InvestmentLoanBank', name: 'Loan Bank' },
        { key: 'InvestmentFundCompany', name: 'Fund Company' },
        { key: 'appliedAmount', name: 'Applied Amount' },
        { key: 'subInvestmentAccountType', name: 'sub-Investment Acct. Type' },
        { key: 'SalesCharge', name: 'Sales Charge' },
        { key: 'FundPortfolio', name: 'Fund Portfolio' },
        { key: 'policyGuaranteeLevel', name: 'Policy Guarantee Level' },
        { key: 'felRate', name: 'FEL Rate' },
        { key: 'felFee', name: 'FEL Fee' },
        { key: 'caseStatus', name: 'Case Status' },
        { key: 'completedAmount', name: 'Completed Amt' },
        { key: 'contributionAmount', name: 'Contribution Amt' },
        { key: 'completedDate', name: 'Completed Date' },
        { key: 'loanAccountNo', name: 'Loan Acct No.' },
        { key: 'loanNo', name: 'Loan No.' },
        { key: 'contractNo', name: 'Contract No.' },
        { key: 'primeRate', name: 'Prime Rate' },
        { key: 'policyGuaranteeLevel', name: 'Policy Guarantee Level' }
    ];
    const advisorTable = operatorTable.slice(0, 4);
    
    const tableContents = userTypes.includes("Operator") ? operatorTable : advisorTable;
    
    const fundTblNames = tableContents.map(attr => attr.name);
    const fundTblValues = tableContents.map(attr => additionalInfo[attr.key]);

    // Control the display of snackbar messages
    const [openError, setOpenError] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);

    //The rowIndex of the table that user clicks on
    const [rowIndex, setRowIndex] = useState(-1);
    const [portfoliosMap, setPortfoliosMap] = useState(new Map([]));

    //To ensure the first "Bank & Investment" Info is mandatory
    const [firstLoanAndFund, setFirstLoanAndFund] = useState();

    useEffect(() => {
        if (additionalInfo.appliedAmount.length > 0) {
            setFirstLoanAndFund(false)
            handleErrorMessage(pageName, 'remove', 'Please add one Bank & Investment Info at least')
        }
        else {
            handleErrorMessage(pageName, 'add', 'Please add one Bank & Investment Info at least')
            setFirstLoanAndFund(true)
        }
    }, [additionalInfo.appliedAmount]);


    
    //Disable add button to prevent more than one case is added per case ID
    const [disableAdd, setDisableAdd] = useState(false);

    useEffect(()=>{
        if(!isNaN(params.caseId)) {
            if (fundTblValues[0].length >= 0){
                setDisableAdd(!disableAdd);
            }
        }
    }, [params.caseId, fundTblValues[0]?.length])


    useEffect(()=>{
    }, [params.applicationId])

    const pageName = 'additional';

    const handleDeleteInvestFund = (index) => {
        const addtionalInfoAttributes = [
            'InvestmentLoanBank',
            'InvestmentFundCompany',
            'appliedAmount',
            'subInvestmentAccountType',
            'SalesCharge',
            'FundPortfolio',
            'felRate',
            'felFee',
            'caseStatus',
            'completedAmount',
            'completedDate',
            'loanAccountNo',
            'loanNo',
            'contractNo',
            'contributionAmount',
            'primeRate',
            'policyGuaranteeLevel',
            'caseID',
            'applicationID',
            'FundPortfolioNo'
        ];
        addtionalInfoAttributes.forEach(attribute => {
            dispatch(setAdditionalInfo({
                attribute,
                value: additionalInfo[attribute].filter((_, i) => i !== index)
            }));
        });
    };

    const deleteCase = async (caseID) => {
        try {
            const response = await axiosInstance.delete(`cases/delete/${caseID}`);
            return response.data;
        }
        catch (error) {
            console.error('Error: ', error);
        }
    }

    const handleDeleteInvestFundwithDB = async (index) => {
        const deletedCase = await deleteCase(additionalInfo.caseID[index]);
        const addtionalInfoAttributes = [
            'InvestmentLoanBank',
            'InvestmentFundCompany',
            'appliedAmount',
            'subInvestmentAccountType',
            'SalesCharge',
            'FundPortfolio',
            'felRate',
            'felFee',
            'caseStatus',
            'completedAmount',
            'completedDate',
            'loanAccountNo',
            'loanNo',
            'contractNo',
            'contributionAmount',
            'primeRate',
            'policyGuaranteeLevel',
            'caseID',
            'applicationID',
            'FundPortfolioNo'
        ];
        addtionalInfoAttributes.forEach(attribute => {
            dispatch(setAdditionalInfo({
                attribute,
                value: additionalInfo[attribute].filter((_, i) => i !== index)
            }));
        });
    };

    const handleEditInvestFund = (index) =>{
        coIndex = index;
        setInvestFund({
            loanBanks: additionalInfo.InvestmentLoanBank[index],
            fundCompanies: additionalInfo.InvestmentFundCompany[index],
            appliedAmount: additionalInfo.appliedAmount[index],
            subAccountType: additionalInfo.subInvestmentAccountType[index],
            salesCharge: additionalInfo.SalesCharge[index],
            portfolio: additionalInfo.FundPortfolio[index],
            felRate: additionalInfo.felRate[index],
            felFee: additionalInfo.felFee[index],
            caseStatus: additionalInfo.caseStatus[index],
            completedAmount: additionalInfo.completedAmount[index],
            completedDate: additionalInfo.completedDate[index],
            loanAccountNo: additionalInfo.loanAccountNo[index],
            loanNo: additionalInfo.loanNo[index],
            contractNo: additionalInfo.contractNo[index],
            contributionAmount: additionalInfo.contributionAmount[index],
            primeRate: additionalInfo.primeRate[index],
            policyGuaranteeLevel:additionalInfo.policyGuaranteeLevel[index],
            caseID:additionalInfo.caseID[index],
            applicationID: additionalInfo.applicationID[index],
            portfolioNo: additionalInfo.FundPortfolioNo[index]
        });
        handleDeleteInvestFund(index);
        setCaseEdit((prevCaseEdit) => !prevCaseEdit);
        setIsUpdate(prevState => !prevState);
        setOriginalStatus(additionalInfo.caseStatus[index]);
    }


    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    };

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
    };

    const fetchPortfoliolist= async ()=>{
        const portfoliosList = await fetchPortfolio(investFund.fundCompanies, investFund.salesCharge=== ""? 'FEL' : investFund.salesCharge, investFund.policyGuaranteeLevel === "" ? '75/75' : investFund.policyGuaranteeLevel) || [];
        if(portfoliosList.length >0){
            const indexOneValues = portfoliosList.map(item => item[1]).filter(value => value !== undefined);
            const updatedMap = new Map();
            portfoliosList.forEach(item => {
                const key = item[1];
                const value = item[0];
                updatedMap.set(key, value);
              });
    
            setPortfoliosMap(updatedMap);
            //setPortfolios((prevPortfolios) => [...prevPortfolios, ...indexOneValues]);
            setPortfolios((prevPortfolios) => {
                const combinedPortfolios = [
                  ...prevPortfolios,
                  ...indexOneValues.filter(
                    (newPortfolio) => !prevPortfolios.some((portfolio) => portfolio.id === newPortfolio.id)
                  ),
                ];
                return combinedPortfolios;
              });
            setCaseEdit((prevCaseEdit) => !prevCaseEdit);
        }
      
    }

    const itemSpace = 4;

    //Disable the edit buttons if any input fields are not empty, requiring submission/clearing before editing another one
    const disableEdit = !(Object.values(investFund).every(value => value === '' || value === 0));

    const handleAdditionalInfoChange = (attribute, value) => {
       console.log(value)
       dispatch(setAdditionalInfo({ attribute: 'caseID', value: [...additionalInfo.caseID, value] }));
        // dispatch(setAdditionalInfo({ attribute: 'CaseID', value: [...additionalInfo.CaseID, value] }));
    };

    const fetchApplcantInfoSourceofCon = async ()=>{
    
        const applicantInfo = await fetchApplicantsourceofConInfo(params.applicationId);
        
        if(applicantInfo !== null ){
            setApplyAmountbyApp(applicantInfo.amountApplied)
            
        }
    }   
    
    useEffect(() => {
        if(fundTblValues.length > 4 && fundTblValues[4][coIndex] === 'FEL' && caseEdit)
            fetchPortfoliolist();
    }, [caseEdit]);

    // useEffect(() => {
    //     if (hasFetched) return; 
    //     fetchPortfoliolist();
    //     setHasFetched((prevValue) => !prevValue);
    // }, [investFund.fundCompanies, hasFetched]);

    // Reset the flag if necessary, for example if appliedAmount is cleared or some condition changes
    useEffect(() => {
        setPortfolios([]);
        fetchPortfoliolist();;
    }, [investFund.fundCompanies]);

    useEffect(() => {

    }, [portfoliosMap, investFund]);

    useEffect(() => {

    }, [applyAmountbyApp]);

    useEffect(() => {

    }, [applyAmountCurrent]);

    useEffect(() => {

    }, [additionalInfo.caseID]);

    useEffect(() => {
        fetchApplcantInfoSourceofCon();
    }, []);

    return (
        <Box>
            {/* Bank & Investment Table */}
            <ModifyTable
                    fieldNames={fundTblNames}
                    fieldValues={fundTblValues}
                    handleDelete={colIndex => handleDeleteInvestFundwithDB(colIndex)}
                    handleEdit={colIndex => handleEditInvestFund(colIndex)}
                    setRowIndex={setRowIndex}
                 />
            
            <Box className="flex flex-row justify-center items-start">
                <Box>
                    {/* Advisor Input Section */}
                    <AdvisorSection
                        color={color}
                        investFund={investFund}
                        setInvestFund={setInvestFund}
                        pageName={pageName}
                        setOpenError={setOpenError}
                        setOpenSuccess={setOpenSuccess}
                        firstLoanAndFund={firstLoanAndFund}
                        disableAdd={disableAdd}
                        portfolioOpt={portfolios}
                        fundType={params.fundType}
                        isUpdate={isUpdate}
                        setIsUpdate={setIsUpdate}
                        portfoliosMap={portfoliosMap}
                        setCaseEdit={setCaseEdit}
                        applicationsPolicy={applicationsPolicy}
                        setApplyAmountCurrent={setApplyAmountCurrent}
                        handleAdditionalInfoChange={handleAdditionalInfoChange}
                        originalStatus={originalStatus}/>
                    
                </Box>
                {/* Fund and Loan Detials Card*/}
                {/* { === "Operator" && (
                    <FundDetailsCard
                        color={color}
                        fundTblNames={fundTblNames}
                        rowIndex={rowIndex}
                        handleEditInvestFund={handleEditInvestFund}
                        handleDeleteInvestFund={handleDeleteInvestFund}
                        disableEdit={disableEdit} />
                )} */}
            </Box>
                
            {/* Error Popup Messages*/}
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right', mb: '20px' }} open={openError}  onClose={handleErrorClose}>
                {/* <Alert
                    onClose={handleErrorClose}
                    severity="warning"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    Please fill all required fields before adding
                </Alert>
                
                    
                 */}
                <Alert
                    onClose={handleErrorClose}
                    severity="warning"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    { (applyAmountbyApp !== 0 && applyAmountCurrent > applyAmountbyApp )
                    ? 'The Applied Amount is over Amount based on Application, Please Update Applied Amount and Retry'
                    : ' Please fill all required fields before adding'
                    }
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right', mb: '20px' }} open={openSuccess} autoHideDuration={2000} onClose={handleSuccessClose}>
                <Alert
                    onClose={handleSuccessClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    {caseEdit 
                    ? 'Edited Bank & Investment Successfully' 
                    : 'Added Bank & Investment Successfully'
                    }
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default BankInvestment;