import { axiosInstance } from '../axios';
import { ApplicationProvider } from '../contexts/ApplicationContext';

export async function fetchApplications (filterStatus, page, searchQuery) {
    try{
        let url = `applications/${page}`;
        if (filterStatus) {
            url += `?statuses=${encodeURIComponent(filterStatus)}`;
        }
        if (searchQuery){
            url+= `&applicationID=${searchQuery}`;
        }
        const response = await axiosInstance.get(url);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchCases (applicationId) {
    try{
        const response = await axiosInstance.get(`cases/all/${applicationId}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }


  export async function fetchAppPolicy (applicationId) {
    try{
        const response = await axiosInstance.get(`/source/policy-guarantee-level/${applicationId}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }



  export async function fetchPortfolio (fundCompany, salesCharge, guaranteePolicy) {
    try {
        const guaranteePolicynew = guaranteePolicy.replace("/", "-");
        if(fundCompany !== '' && salesCharge !== ''){
            const response = await axiosInstance.get(`portfolio/fundPortfolio/${fundCompany}/${salesCharge}/${guaranteePolicynew}`);
            return response.data;
        }

        // if (!response.ok) {
        //     throw new Error('Fail to fetch portfolio in database');
        // }
        // if (!response.data || !Array.isArray(response.data)) {
        //     throw new Error("Data is not an array or is undefined");
        // }
       
    } catch (error) {
        console.error('There was an error fetching the portfolio:', error);
    }
  }

  export async function fetchFELRate (applicationID, salesCharge, applyAmount, fundsType) {
    try {
        const response = await axiosInstance.get(`felRate/${applicationID}/${salesCharge}/${applyAmount}/${fundsType}`);
        // if (!response.ok) {
        //     throw new Error('Fail to fetch portfolio in database');
        // }
        // if (!response.data || !Array.isArray(response.data)) {
        //     throw new Error("Data is not an array or is undefined");
        // }
       return response.data;
    } catch (error) {
        console.error('There was an error fetching the portfolio:', error);
    }
  }



  export async function fetchPortfoliowithID (fundPortfolioNo) {
    try {
        const response = await axiosInstance.get(`portfolio/fundPortfolioId/${fundPortfolioNo}`);
        // if (!response.ok) {
        //     throw new Error('Fail to fetch portfolio in database');
        // }
        // if (!response.data || !Array.isArray(response.data)) {
        //     throw new Error("Data is not an array or is undefined");
        // }
       return response.data;
    } catch (error) {
        console.error('There was an error fetching the portfolio:', error);
    }
  }


  export async function fetchCustomer (customerID) {
    try{
        const response = await axiosInstance.get(`referrer/${customerID}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchExistingAcquisitionChannel (applicationId) {
    try{
        const response = await axiosInstance.get(`acquisitionChannel/${applicationId}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchApplicantInfo (applicationId) {
    try{
        const response = await axiosInstance.get(`applicants/all/${applicationId}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchContact (applicationId) {
    try{
        const response = await axiosInstance.get(`contact/all/${applicationId}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchAddress (applicationId) {
    try{
        const response = await axiosInstance.get(`address/all/${applicationId}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchApplicantidInfo (applicationId) {
    try{
        const response = await axiosInstance.get(`id/${applicationId}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchApplicantidvInfo (applicationId) {
    try{
        const response = await axiosInstance.get(`id-verification/${applicationId}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchTaxStatus (applicationID, applicantType) {
    try{
        const response = await axiosInstance.get(`tax/status/all/${applicationID}/${applicantType}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchApplicantemploymentInfo (applicationId) {
    try{
        const response = await axiosInstance.get(`employment/${applicationId}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchApplicantsourceofConInfo (applicationId) {
    try{
        const response = await axiosInstance.get(`contribution/all/${applicationId}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchApplicantcreditInfo (applicationId) {
    try{
        const response = await axiosInstance.get(`applicantscreditreport/all/${applicationId}`);
        console.log(response.data);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchApplicantspecialInfo (applicationId) {
    try{
        const response = await axiosInstance.get(`special-instructions/${applicationId}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchIDVerification (applicationId) {
    try{
        let url = `app/info/id-verification/${applicationId}`;
       
        const response = await axiosInstance.get(url);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchApplicationsAnnuitant (applicationId) {
    try{
        let url = `annuitant/${applicationId}`;
       
        const response = await axiosInstance.get(url);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchApplicationsSuccessorOwner (applicationId) {
    try{
        let url = `successor-owner/${applicationId}`;
       
        const response = await axiosInstance.get(url);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }
  export async function fetchApplicationsInvestorProfile (applicationId) {
    try{
        let url = `investor-profile/${applicationId}`;
       
        const response = await axiosInstance.get(url);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchApplicationInfo (applicationId) {
    try{
        const response = await axiosInstance.get(`app/info/${applicationId}`);
        return response.data 
      }catch(error){
          // TODO: Block user from moving on 
          console.error("Failed to fetch info:" , error)
      }
  }