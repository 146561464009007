import React, { useState, useEffect } from 'react';
import { Grid, Box, Collapse, IconButton, styled } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import { useParams } from 'react-router-dom';
import { useApplication } from "../../../../contexts/ApplicationContext";
import TextFieldText from '../../../../components/smallComponents/text/textFieldText/TextFieldText';
import DropdownList from '../../../../components/smallComponents/dropdownList/DropdownList';
import TextField from '../../../../components/smallComponents/textField/TextField';
import DateField from '../../../../components/smallComponents/dateField/DateField';
import TitleText from '../../../../components/smallComponents/text/titleText/TitleText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EmailRounded } from '@mui/icons-material';
import InputFileUpload from '../../../../components/mediumComponents/uploadDoc/FileUploader';
import { useRawFiles } from '../../../../contexts/FileContext';
import TextFieldWithHelper from '../../../../components/smallComponents/helper/Helper';
import Stack from '@mui/material/Stack';
import { getFileNamesByUrls } from '../../../../utilities/fileHelper';

export default function IdVerification({ IDType, enable }) {
    const { clickNextButton, handleErrorMessage } = useApplication();
    const itemSpace = 3;
    const pageName = 'identification';
    const { rawFiles, saveRawFile } = useRawFiles();

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const applicationType = useParams().applicationType;

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }));
    };

    const saveFile = (attribute, value) => {
        saveRawFile(attribute, value);
    }

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const [expanded, setExpanded] = useState(false);
    const [filledSection, setFieldSection] = useState(false);
    const [isMandatory, setIsMandatory] = useState(false);
    const [enabled, setEnabled] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    // Validate fields
    useEffect(() => {
        const mandatoryFields = {
            applicantPersonalIDType: applicant.applicantPersonalIDType?.trim() === "",
            applicantPersonalIDIssuingAuthority: applicant.applicantPersonalIDIssuingAuthority?.trim() === "",
            applicantPersonalIDExpiryDate: applicant.applicantPersonalIDExpiryDate?.trim() === "",
            applicantPersonalIDAccountReference: applicant.applicantPersonalIDAccountReference?.trim() === "",
            applicantPersonalID_1Urls: applicant.applicantPersonalID_1Urls?.length === 0
        };

        const allFieldsFilled = Object.values(mandatoryFields).every((field) => !field);
        setFieldSection(allFieldsFilled);
    }, [
        applicant.applicantPersonalIDType,
        applicant.applicantPersonalIDIssuingAuthority,
        applicant.applicantPersonalIDExpiryDate,
        applicant.applicantPersonalIDAccountReference,
        applicant.applicantPersonalID_1Urls
    ]);

    useEffect(() => {
        const isMandatory = applicant.verified === false || applicant.verified_2 === false; // Set mandatory fields based on verified value
        if (expanded && !filledSection) {
            handleErrorMessage(pageName, 'add', 'All ID Verification Fields are mandatory!');
        } else {
            handleErrorMessage(pageName, 'remove', 'All ID Verification Fields are mandatory!');
        }
        setIsMandatory(isMandatory);
        setEnabled(isMandatory);
        // eslint-disable-next-line
    }, [expanded, filledSection, applicant.verified, applicant.verified_2]);


    useEffect(() => {
        if (applicant.applicantPersonalID_1Urls?.length > 0) {
            saveRawFile('applicantPersonalID_1Urls', {name: getFileNamesByUrls(applicant.applicantPersonalID_1Urls[0]), isFetched: true});
    }}, [applicant.applicantPersonalID_1Urls]);

    return (
        <Box display={enabled && enable ? 'block' : 'none'}>
            <Box className="section-main-box-field">
                <Stack direction="row" justifyContent="center">
                    <TitleText text="Main Applicant ID Verification" />
                    <TextFieldWithHelper text="" helperText="Information sources must be valid and current, you must record and verify the information shown on the two pieces of personal ID, each from a different reliable source, verify two of the following: name and address/name and date of birth/ Name and financial account." />
                </Stack>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon fontSize="large" />
                    {!expanded && (
                        <p style={{ color: 'grey', fontSize: 18, justifyContent: 'center', display: 'flex', fontWeight: 'bold' }}>
                            Fill ID Verification Field
                        </p>
                    )}
                </ExpandMore>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>

                        <Grid item xs={itemSpace}>
                            <TextFieldText text='Verification Document Type' />
                            <DropdownList
                                options={IDType}
                                setStateValue={value => handleApplicantChange("applicantPersonalIDType", value)}
                                defaultValue={applicant.applicantPersonalIDType}
                                type={expanded && isMandatory ? 'mandatory' : 'optional'}
                                name='Verification Document Type'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <TextFieldWithHelper text='Verification Document Issuing Authority' helperText="Please provide the name of the authority or agency that issued your ID"/>
                            <TextField
                                setStateValue={value => handleApplicantChange("applicantPersonalIDIssuingAuthority", value)}
                                defaultValue={applicant.applicantPersonalIDIssuingAuthority}
                                type={expanded && isMandatory ? 'mandatory' : 'optional'}
                                name='Verification Document Issuing Authority'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <TextFieldText text='Verification Document Account/Reference number' />
                            <TextField
                                setStateValue={value => handleApplicantChange("applicantPersonalIDAccountReference", value)}
                                defaultValue={applicant.applicantPersonalIDAccountReference}
                                type={expanded && isMandatory ? 'mandatory' : 'optional'}
                                name='Verification Document Account/Reference number'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <TextFieldWithHelper text='ID Verification Document Date of information' helperText="Please provide the expiry date of your ID or information Date of your document"/>
                            <DateField
                                setStateValue={value => handleApplicantChange("applicantPersonalIDExpiryDate", value)}
                                defaultValue={applicant.applicantPersonalIDExpiryDate}
                                type={expanded && isMandatory ? 'date' : 'optional'}
                                name='ID Verification Document Date of information'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <TextFieldText text='Upload Verification Document' />
                            <InputFileUpload uploadedFile={rawFiles.applicantPersonalID_1Urls} setUploadedFile={value=> saveFile("applicantPersonalID_1Urls", value)} />
                    
                        </Grid>
                    </Grid>
                </Collapse>
            </Box>
        </Box>
    );
}
