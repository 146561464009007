import TextFieldNew from "../../smallComponents/textField/TextFieldNew";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextFieldWithHelper from "../../smallComponents/helper/Helper";
import { useEffect, useState } from "react";
import { provinces } from "../../../constants/lists";
import DropdownList from "../../smallComponents/dropdownList/DropdownList";
import { provinceSelectorByAbbr, provinceSelectorByFullName } from "../../../services/provinceSelector";
import BasicSelect from "../../smallComponents/dropdownList/BasicSelect";

export default function AddressInput({addrObj = {}, helperTexts = {}, handleChange = () => {}, errors = {}}) {


    const itemSpace = 4;

    return (
        <Grid container spacing={2}>

            <Grid item xs={itemSpace} alignItems="flex-start" >
                <Stack direction="column">
                    <TextFieldWithHelper text="Unit" helperText={helperTexts.unitHelper || ''} />
                    <TextFieldNew
                        value={addrObj.unit || ''}
                        onChange={value => handleChange("unit", value)}
                        error={errors.unit}
                    />
                </Stack>
            </Grid>

            <Grid item xs={itemSpace} alignItems="flex-start" >
                <Stack direction="column">
                    <TextFieldWithHelper text="Street Number" helperText={helperTexts.streetNumberHelper || ''} />
                    <TextFieldNew
                        value={addrObj.streetNumber || ''}
                        onChange={value => handleChange("streetNumber", value)}
                        error={errors.streetNumber}
                    />
                </Stack>
            </Grid>

            <Grid item xs={itemSpace} alignItems="flex-start" >
                <Stack direction="column">
                    <TextFieldWithHelper text="Street Name" helperText={helperTexts.streetNameHelper || ''} />
                    <TextFieldNew
                        value={addrObj.streetName || ''}
                        onChange={value => handleChange("streetName", value)}
                        error={errors.streetName}
                    />
                </Stack>
            </Grid>

            <Grid item xs={itemSpace} alignItems="flex-start" >
                <Stack direction="column">
                    <TextFieldWithHelper text="City" helperText={helperTexts.cityHelper || ''} />
                    <TextFieldNew
                        value={addrObj.city || ''}
                        onChange={value => handleChange("city", value)}
                        error={errors.city}
                    />
                </Stack>
            </Grid>

            <Grid item xs={itemSpace} >
                    <Stack direction="column">
                        <TextFieldWithHelper text="Province" helperText={helperTexts.provinceHelper || ''} />
                        <BasicSelect
                            options={provinces}
                            handleChange={value => handleChange("province", provinceSelectorByFullName(value))}
                            value={provinceSelectorByAbbr(addrObj.province)}
                            error={errors.province}
                    />
                </Stack>
            </Grid>

            <Grid item xs={itemSpace} alignItems="flex-start" >
                <Stack direction="column">
                    <TextFieldWithHelper text="Postal Code" helperText={helperTexts.postalCodeHelper || ''} />
                    <TextFieldNew
                        value={addrObj.postalCode || ''}
                        onChange={value => handleChange("postalCode", value)}
                        error={errors.postalCode}
                    />
                </Stack>
            </Grid>

        </Grid>
    )

}