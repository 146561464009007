import { Button, Snackbar, Alert } from "@mui/material";
import { useApplication } from "../../../../contexts/ApplicationContext";
import { useState, useEffect, useRef } from "react";
import React from "react";
import { useSelector,useDispatch  } from "react-redux"; 
import "./style/NextButton.css"
import { saveInfo, infoToSave } from "../../../../services/saveInfoService";
import { useParams } from 'react-router-dom';
import { setApplicationInfo } from '../../../../reducers/applicationReducer';
import { resetCoapplicantInfo, setCoapplicantInfo } from "../../../../reducers/coapplicantReducer";
import { useNavigate } from "react-router-dom";
/**
 * @param {boolean} checkScore - A boolean that determines if the button should check the user's investor profile score
 * @param {boolean} hasCoApplicant - A boolean that determines if the user has a co-applicant
 * @param {string} pageName - The name of the page that the button is on
 * @returns - A button that when clicked will call the handleNextButtonClick function from the ApplicationContext
 * @description - The button has two different validations based on the checkScore prop and will display a toast if the user fails the validations
 */


const NextButton = React.forwardRef(({ checkScore = false, checkbeneShare = false, pageName = '' , addtionalsubmit = false, handleClick = async () => {} }, ref) => {
    const nextButtonRef = useRef(null);
    React.useImperativeHandle(ref, () => ({
        triggerClick: () => {
            nextButtonRef.current?.click();
          },
      }));
    const { page, progress, setPage, setProgress, mainInvestorScoreCalc, coInvestorScoreCalc,
        errorMessages, clickNextButton, setClickNextButton, beneShare } = useApplication();
    const [localCheck, setLocalCheck] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [beneShareFailed, setBeneShareFailed] = useState(false);
    const [resErrorMessage, setResErrorMessage] = useState("");
    const [empErrorMessage, setEmpErrorMessage] = useState("");
    const application = useSelector((state) => state.applicationInfo);
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const hasCoApplicant = applicant.anyCoapplicant;
    const applicationType = useParams().applicationType;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleNextButtonClick = () => {
        // Set the clickNextButton state to true so that the automatic validation will be triggered on the page 
        // after the user clicks the next button for the first time
        if (!clickNextButton[pageName]) {
            setClickNextButton(prev => ({
                ...prev,
                [pageName]: true,
            }));
        }

        // Set a timeout to allow the automatic validation to finish before checking if there are any error messages
        setTimeout(() => {
            setLocalCheck(true);
        }, 200);
    };

    const handleNextButtonErrorCheck = () => {
        // Check if there are any error messages on the page
        // If there are error messages, display a toast and return
        if (errorMessages[pageName] && errorMessages[pageName].length > 0) {
            setOpenMessage(true);
            return;
        }

        // Call the page change function if the user passes the error message check
        handlePageChange();
    };

    const handleApplicationReview = () => {
       
         navigate("/home/my-account")
    };

    const handleNextButtonInvestorProfileClick = () => {
        // If has co-applicant, check if both main and co-applicant scores are greater than or equal to 180
        // If not, display a toast and return
        
        if (hasCoApplicant) {
            if (mainInvestorScoreCalc < 200 || coInvestorScoreCalc < 200) {
                setOpenMessage(true);
                return;
            }
        }
        // If there is no co-applicant, check if the main applicant score is greater than or equal to 180
        // If not, display a toast and return
        else {
            if (mainInvestorScoreCalc < 200) {
                setOpenMessage(true);
                return;
            }
        }

        // Call the page change function if the user passes the investor profile score check
        handlePageChange();
    };

    const handleNextButtonbeneShareClick = () => {
        if(beneShare !== 0.0){
            setBeneShareFailed(true);
            setOpenMessage(true);
            return;
        }
        handlePageChange();
    }
   

    const handlePageChange = async () => {
        if(pageName !== 'financial' && pageName !== 'beneficiary'){
            const info = infoToSave(pageName, applicant, 'Applicant', application.applicationID, applicationType,application);
            let needToSaveCoInfo = true;
            const reponse = await saveInfo(pageName, info);
            if(pageName === 'identification'){
                let customerID = [];
                if(reponse !== undefined && reponse.length >0 ){
                    
                    reponse.map((idRepsone, index) => {
                        customerID.push(idRepsone.customerIdentityID);
                    });
                    dispatch(setApplicationInfo({ attribute: 'CustomerIDs', value: customerID}));
                }

            }
            if(['specialInstructions', 'option'].includes(pageName)){
                needToSaveCoInfo = false;
            }
            if(hasCoApplicant && needToSaveCoInfo){
                const coInfo = infoToSave(pageName, coapplicant, 'Co-Applicant', application.applicationID, applicationType,application);
                const coreponse = await saveInfo(pageName, coInfo);
                if(pageName === 'identification'){
                    let customerID = [];
                    if(coreponse !== undefined && coreponse.length >0 ){
                        
                        coreponse.map((idRepsoneco, index) => {
                            customerID.push(idRepsoneco.customerIdentityID);
                        });
                        dispatch(setCoapplicantInfo({ attribute: 'CustomerIDs', value: customerID}));
                    }
    
                }
            }
        }
        

        // Increment the progress and page states only if the page is greater than or equal to the saved progress
        // Otherwise, increment the page state only
        if(pageName === 'summary'){
            return;
        }

        if(pageName === 'applicationDetail'){
            return;
        }
        if (page >= progress) {
            setProgress(progress + 1);
            setPage(page + 1);
        } else {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        if (localCheck) {
            handleNextButtonErrorCheck(pageName);
            setLocalCheck(false);
        }
        // eslint-disable-next-line
    }, [localCheck]);


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenMessage(false);
    };

    return (
        <>
            <Button 
                ref={nextButtonRef}
                variant="contained" 
                className="button-next"
                onClick={ async () => {
                    const res = await handleClick();
                    if(res !== undefined && res === false){
                        console.log("returned due to handleclick")
                        return;
                    }
                    if (checkScore) {
                        handleNextButtonInvestorProfileClick();
                    } else if(checkbeneShare){
                        handleNextButtonbeneShareClick()
                    } else if(addtionalsubmit){
                        handleApplicationReview();
                    }
                    else {
                        handleNextButtonClick();
                    }                 
                }}
            >
                { pageName === "summary"? "Submit" : addtionalsubmit ? "Reviewed" :"Next"}
            </Button>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openMessage}  onClose={handleClose}>
                <div>                 

                {(checkbeneShare && beneShareFailed) && (
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    {checkScore 
                    ? 'You do not meet the investor risk level requirement' 
                    : 'Please update Share field as the Total of Shares must be 100'
                    }
                </Alert>
                )}

                {/* Alert for asset residential status error message */}
                {resErrorMessage && (
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%', fontSize: 20 }}
                    >
                        {resErrorMessage}
                    </Alert>
                )}   
                {/* Alert for employment status error message */}
                {empErrorMessage && (
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%', fontSize: 20 }}
                    >
                        {empErrorMessage}
                    </Alert>
                )}   
                 {/* Alert for check invest Score */}
                 {checkScore && (
                    <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                    >
                    {checkScore 
                    ? 'You do not Meet the Investor Risk Ambitious Level, Ambitious Score >= 200' 
                    : ''
                    }
                    </Alert>
                )}   
                               
            </div>
            
            </Snackbar>
            
        </>
    );
});

export default NextButton;