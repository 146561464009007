import { Box, Tab, Tabs } from "@mui/material";
import { useApplication } from "../../../../contexts/ApplicationContext";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import PrimaryBeneficiary from "./PrimaryBeneficiary";
import './style/Beneficiary.css'
import SecondaryBeneficiary from "./SecondaryBeneficiary";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import BeneficiaryType from "../../../../components/enumTypes/BeneficiaryType";
import { annuitantRelation } from '../../../../constants/lists';
import { Stepper, Step, StepLabel, Button } from '@mui/material';
import PopupDialog from "../../../../components/smallComponents/alert/PopupDialog";
import React, { useRef } from "react";

/**
 * @returns - Beneficiary field component for the application page
 */


export default function Beneficiary() {
    const nextButtonRef = useRef(null);
    const [skipValidation, setSkipValidation] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const { clickNextButton } = useApplication();
    const steps = ['Primary Beneficiary', 'Secondary Beneficiary'];
    const [primaryFieldExpanded, setPrimaryFieldExpanded] = useState(false);
    const [secondaryFieldExpanded, setSecondaryFieldExpanded] = useState(false);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const applicationType = useParams().applicationType;

    const hasSecondaryBeneficiary = ['regular', 'quick', 'transfer', 'nonreg', 'rrsp', 'tfsa'].includes(applicationType);

    useEffect(() => {
        if(activeStep === 0){
            setSecondaryFieldExpanded(false);
        } else if(activeStep === 1){
            setPrimaryFieldExpanded(false);
        }
        setSkipValidation(false);
    }, [activeStep]);

    useEffect(() => {
        if (skipValidation) {
            nextButtonRef.current?.triggerClick();
        }
    }, [skipValidation]);
    

    const handleDialogClose = () => {
        if(activeStep === steps.length - 1){
            setSkipValidation(true);
        } else {
            handleNext(false);
        }
        setIsDialogOpen(false);
    }

    const handleDialogConfirm = () => {
        setIsDialogOpen(false);
    }

    const handleNext = (fieldCheck) => {
        console.log("handlenext")
        if(activeStep === steps.length - 1) {
            if (skipValidation){
                console.log("return")
                return;
            } else if(fieldCheck && secondaryFieldExpanded){
                console.log("return false")
                setIsDialogOpen(true);
                return false;
            }
        } else if(fieldCheck ) {
            if (activeStep === 0 && primaryFieldExpanded){
                setIsDialogOpen(true);
                return false;
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            
        }
      };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const beneficiaryType = [
        BeneficiaryType.REVOCABLE,
        BeneficiaryType.IRREVOCABLE
    ];

    const pageName = 'beneficiary';

    return (
        <Box className="box-overall">

            <Box>
                <TitleText text="Beneficiary" />
            </Box>

            <Box>
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} className="beneficiary-stepper">
                    {steps.map((label) => {
                    return (
                        <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                    })}
                </Stepper>

                {activeStep === 0 && <PrimaryBeneficiary relationAnnuitant={annuitantRelation} beneficiaryType={beneficiaryType} setExpanded={setPrimaryFieldExpanded}/>}
                {activeStep === 1 && <SecondaryBeneficiary relationAnnuitant={annuitantRelation} beneficiaryType={beneficiaryType} setExpanded={setSecondaryFieldExpanded}/>}
                {activeStep <= steps.length && <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                {activeStep === 0
                    ? <PrevButton /> 
                    : <Button 
                        color = "inherit"
                        onClick = {handleBack}
                        disabled = {activeStep === 0}
                        sx = {{ mr: 1, fontSize: '1.5rem' }}
                        >
                        Back
                    </Button>}
                <Box sx={{ flex: '1 1 auto'}} />
                    {activeStep < steps.length - 1? 
                    <Button sx= {{fontSize: '1.5rem'}} onClick={() => handleNext(true)}  className={activeStep === steps.length - 1? 'hidden': 'flex'}>
                            Next
                    </Button>:
                    <Box>
                        <NextButton ref={nextButtonRef} checkbeneShare={true} pageName={pageName} handleClick={() => handleNext(true)}/>
                    </Box>}
                </Box>}
            </Box>
            <PopupDialog
                open={isDialogOpen}
                title="Step Incomplete"
                message="You have fields edited in this step that are not saved. Do you want to continue without saving?"
                closeOption="Move on without saving"
                confirmOption="Back to Editing"
                onClose={handleDialogClose}
                onConfirm={handleDialogConfirm}
            />
        </Box>
    );
};