import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setCoapplicantInfo } from '../../../../reducers/coapplicantReducer';
import { useParams } from 'react-router-dom';
import "./style/IdentificationCoAppInformation.css"
import { Box, Collapse, Grid, IconButton, styled } from '@mui/material';
import TitleText from '../../../../components/smallComponents/text/titleText/TitleText';
import TextFieldText from '../../../../components/smallComponents/text/textFieldText/TextFieldText';
import DateField from "../../../../components/smallComponents/dateField/DateField";
import TextField from "../../../../components/smallComponents/textField/TextField";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import RadioGroupBoolean from '../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean';
import DocUploader, { FileLocation } from '../../../../components/mediumComponents/uploadDoc/docUploader';
import { useApplication } from '../../../../contexts/ApplicationContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputFileUpload from '../../../../components/mediumComponents/uploadDoc/FileUploader';
import { useRawFiles } from '../../../../contexts/FileContext';
import TextFieldWithHelper from '../../../../components/smallComponents/helper/Helper';
import { getFileNamesByUrls } from '../../../../utilities/fileHelper';
/**
 * IdentificationInformation component.
 * @param {Object} props - The component props.
 * @param {Array} props.IDType - The array of ID types.
 * @param {Array} props.Countries - The array of countries.
 * @returns {JSX.Element} the form for manually inserting the Identification of Co-Applicant
 */


export default function IdentificationCoAppInformation({ IDType, Countries, Authorities, enable }) {

    const { clickNextButton, handleErrorMessage } = useApplication();

    const itemSpace = 3;
    const baseTitle = "Co-Applicant ID 1";
    const baseTitle2 = "Co-Applicant ID 2";
    const pageName = 'identification';


    const dispatch = useDispatch();
    const { rawFiles, saveRawFile } = useRawFiles();
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);

    const applicationType = useParams().applicationType;

    const isLoanType = ['regular', 'quick', 'transfer'].includes(applicationType);

    const handleCoapplicantChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))
    };


    useEffect(() => {
        if(applicant.anyCoapplicant){
            if (coapplicant.coApplicantID1Urls?.length > 0) {
                saveRawFile('coApplicantID1Urls', {name: getFileNamesByUrls(coapplicant.coApplicantID1Urls[0]), isFetched: true});
            }
            if (coapplicant.coApplicantID2Urls?.length > 0) {
                saveRawFile('coApplicantID2Urls', {name: getFileNamesByUrls(coapplicant.coApplicantID2Urls[0]), isFetched: true});
            }
        }
    }, [coapplicant.coApplicantID1Urls, coapplicant.coApplicantID2Urls]);

    useEffect(() => {
        if (coapplicant.idType.includes('Canadian Citizenship')) {
            handleCoapplicantChange("expireDate", 'N/A')
        }
        if (coapplicant.idType_2.includes('Canadian Citizenship')) {
            handleCoapplicantChange("expireDate_2", 'N/A')
        }
        // eslint-disable-next-line
    }, [coapplicant.idType, coapplicant.idType_2]);

    //Collapse function section ID 1
    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const [expanded, setExpanded] = useState(true);

    const [filledSection, setFieldSection] = useState(false);

    const handleExpandClick = () => {
        if (isLoanType) {
            setExpandedID2(expanded)
        }
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (!filledSection && applicant.anyCoapplicant) {
            handleErrorMessage(pageName, 'add', `All Co-Applicant ID 1 Field is mandatory !`);
        }
        else {
            handleErrorMessage(pageName, 'remove', `All Co-Applicant ID 1 Field is mandatory !`);
        }
        // eslint-disable-next-line
    }, [expanded, filledSection]);

    useEffect(() => {

        const timer = setTimeout(() => {
            
            const allFieldsFilled = coapplicant.fullName.trim() !== "" &&
                coapplicant.idType.trim() !== "" &&
                coapplicant.idNumber.trim() !== "" &&
                coapplicant.issueCountry.trim() !== "" &&
                coapplicant.issueProvince.trim() !== "" &&
                coapplicant.issueAuthority.trim() !== "" &&
                coapplicant.issueDate.trim() !== "" &&
                coapplicant.expireDate.trim() !== "" &&
                coapplicant.verified !== null; // Assuming verified is a boolean or has a non-string value
            setFieldSection(allFieldsFilled);
        }, 1000); // Delay in milliseconds (1000ms = 1 second)
    }, [
        coapplicant.fullName,
        coapplicant.idType,
        coapplicant.idNumber,
        coapplicant.issueCountry,
        coapplicant.issueProvince,
        coapplicant.issueAuthority,
        coapplicant.issueDate,
        coapplicant.expireDate,
        coapplicant.verified
    ]);

    // collapse ID section 2 (need edit)
    const ExpandMoreID2 = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const [expandedID2, setExpandedID2] = useState(false);

    const [filledSectionID2, setFieldSectionID2] = useState(false)

    const handleExpandClickID2 = () => {
        setExpanded(expandedID2)
        setExpandedID2(!expandedID2);
    };

    useEffect(() => {
        if (!filledSectionID2 && applicant.anyCoapplicant && isLoanType) {
            handleErrorMessage(pageName, 'add', `All Co-Applicant ID 2 Field is mandatory !`);
        }
        else {
            handleErrorMessage(pageName, 'remove', `All Co-Applicant ID 2 Field is mandatory !`);
        }
        // eslint-disable-next-line
    }, [expandedID2, filledSectionID2]);

    useEffect(() => {
        const timer = setTimeout(() => {
            const allFieldsFilled_2 = coapplicant.fullName.trim() !== "" &&
                coapplicant.idType.trim() !== "" &&
                coapplicant.idNumber.trim() !== "" &&
                coapplicant.issueCountry.trim() !== "" &&
                coapplicant.issueProvince.trim() !== "" &&
                coapplicant.issueAuthority.trim() !== "" &&
                coapplicant.issueDate.trim() !== "" &&
                coapplicant.expireDate.trim() !== "" &&
                coapplicant.verified !== null;
            setFieldSectionID2(allFieldsFilled_2);
     }, 1000); // Delay in milliseconds (1000ms = 1 second)
    
    }, [
        coapplicant.fullName,
        coapplicant.idType,
        coapplicant.idNumber,
        coapplicant.issueCountry,
        coapplicant.issueProvince,
        coapplicant.issueAuthority,
        coapplicant.issueDate,
        coapplicant.expireDate,
        coapplicant.verified,
    ]);

    const saveFile = (attribute, value) => {
            saveRawFile(attribute, value);
    }
    

    return (
        <Box display={enable ? 'block' : 'none'} >

            <Box className="section-co-box-field">

                <TitleText text={baseTitle} />

                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon fontSize="large" />
                    {!expanded && <p style={{ color: 'grey', fontSize: 18, justifyContent: 'center', display: 'flex', fontWeight: 'bold' }}>Fill the Applicant ID 1 Field</p>}

                </ExpandMore>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>

                        <Grid item xs={itemSpace}>
                            <Box>
                            <TextFieldWithHelper text='Full Name on ID' helperText="Please enter the full name as it appears on the ID"/>
                            <TextField
                                    setStateValue={value => handleCoapplicantChange("fullName", value)}
                                    defaultValue={coapplicant.fullName}
                                    type={applicant.anyCoapplicant && expanded ? 'name' : 'optional'} name='Co-Applicant Full Name 1' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                            <TextFieldWithHelper text="ID Type" helperText="Which document are you using to verify your identity? Canadian driver's licence/Passport/PR Card/Other ID(specify). Only the ID types listed are permitted."/>
                            <DropdownList
                                    options={IDType}
                                    setStateValue={value => handleCoapplicantChange("idType", value)}
                                    defaultValue={coapplicant.idType}
                                    type={applicant.anyCoapplicant && expanded ? 'mandatory' : 'optional'} name='Co-Applicant ID Type 1' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                            <TextFieldWithHelper text="ID Number" helperText="Please specify the ID Number on the ID"/>
                            <TextField
                                    setStateValue={value => handleCoapplicantChange("idNumber", value)}
                                    defaultValue={coapplicant.idNumber}
                                    type={applicant.anyCoapplicant && expanded ? 'mandatory' : 'optional'} name='Co-Applicant ID Number 1' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                                <TextFieldText text='Issuing Country' />
                                <DropdownList
                                    options={Countries}
                                    setStateValue={value => handleCoapplicantChange("issueCountry", value)}
                                    defaultValue={coapplicant.issueCountry}
                                    type={applicant.anyCoapplicant && expanded ? 'mandatory' : 'optional'} name='Co-Applicant Issuing Country 1' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                                <TextFieldText text='Issue Province' />
                                <TextField
                                    setStateValue={value => handleCoapplicantChange("issueProvince", value)}
                                    defaultValue={coapplicant.issueProvince}
                                    type={applicant.anyCoapplicant && expanded ? 'mandatory' : 'optional'} name='Co-Applicant Issue Province 1' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                                <TextFieldWithHelper text='Issuing Authority' helperText="Please provide the name of the authority or government agency that issued your ID."/>
                                <DropdownList
                                    options={Authorities}
                                    setStateValue={value => handleCoapplicantChange("issueAuthority", value)}
                                    defaultValue={coapplicant.issueAuthority}
                                    type={applicant.anyCoapplicant && expanded ? 'mandatory' : 'optional'} name='Co-Applicant Issuing Authority 1' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                                <TextFieldText text='Issue Date' />
                                <DateField
                                    setStateValue={value => handleCoapplicantChange("issueDate", value)}
                                    defaultValue={coapplicant.issueDate}
                                    type={applicant.anyCoapplicant && expanded ? 'date' : 'optional'} name='Co-Applicant Issue Date 1' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                                <TextFieldWithHelper text='Expiry Date' helperText="Please provide the date when your identification document will expire."/>
                                <DateField
                                    setStateValue={value => handleCoapplicantChange("expireDate", value)}
                                    defaultValue={coapplicant.expireDate}
                                    minYear={-50}
                                    isExpriyD={true}
                                    disabled={coapplicant.idType.includes('Canadian Citizenship')}
                                    type={applicant.anyCoapplicant && expanded && !coapplicant.idType.includes('Canadian Citizenship') ? 'date' : 'optional'} name='Co-Applicant Expiry Date 1' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace * 2}>
                            <Box>
                                <TextFieldText text='Has your ID been verified by the advisor physically/face-to-face?' largeBox={true} />
                                <RadioGroupBoolean
                                    setStateValue={value => handleCoapplicantChange("verified", value)}
                                    defaultValue={coapplicant.verified}
                                    type={applicant.anyCoapplicant && expanded ? 'mandatory' : 'optional'} name='Co-Applicant ID 1 is verified' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace * 2} >
                            <TextFieldText text='Upload ID 1' largeBox={true} />
                            <InputFileUpload 
                                uploadedFile={rawFiles.coApplicantID1Urls}
                                setUploadedFile={value => saveFile("coApplicantID1Urls", value)}
                            />
                        </Grid>

                    </Grid>
                </Collapse>

            </Box>

            {isLoanType && (
                <Box className="section-co-box-field">

                    <TitleText text={baseTitle2} />

                    <ExpandMoreID2
                        expand={expandedID2}
                        onClick={handleExpandClickID2}
                        aria-expanded={expandedID2}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon fontSize="large" />
                        {!expandedID2 && <p style={{ color: 'grey', fontSize: 18, justifyContent: 'center', display: 'flex', fontWeight: 'bold' }}>Fill the Applicant ID 2 Field</p>}
                    </ExpandMoreID2>

                    <Collapse in={expandedID2} timeout="auto" unmountOnExit>
                        <Grid container spacing={2}>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldWithHelper text='Full Name on ID' helperText="Please enter the full name as it appears on the ID"/>
                                    <TextField
                                        setStateValue={value => handleCoapplicantChange("fullName_2", value)}
                                        defaultValue={coapplicant.fullName_2}
                                        type={applicant.anyCoapplicant && expandedID2 ? 'name' : 'optional'} name='Co-Applicant Full Name 2' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                <TextFieldWithHelper text="ID Type" helperText="Which document are you using to verify your identity? Canadian driver's licence/Passport/PR Card/Other ID(specify). Only the ID types listed are permitted."/>
                                    <DropdownList
                                        options={IDType}
                                        setStateValue={value => handleCoapplicantChange("idType_2", value)}
                                        defaultValue={coapplicant.idType_2}
                                        type={applicant.anyCoapplicant && expandedID2 ? 'mandatory' : 'optional'} name='Co-Applicant ID Type 2' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                <TextFieldWithHelper text="ID Number" helperText="Please specify the ID Number on the ID"/>
                                    <TextField
                                        setStateValue={value => handleCoapplicantChange("idNumber_2", value)}
                                        defaultValue={coapplicant.idNumber_2}
                                        type={applicant.anyCoapplicant && expandedID2 ? 'mandatory' : 'optional'} name='Co-Applicant ID Number 2' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldWithHelper text='Issue Country' helperText="Please specify the country where your identification document was issued"/>
                                    <DropdownList
                                        options={Countries}
                                        setStateValue={value => handleCoapplicantChange("issueCountry_2", value)}
                                        defaultValue={coapplicant.issueCountry_2}
                                        type={applicant.anyCoapplicant && expandedID2 ? 'mandatory' : 'optional'} name='Co-Applicant Issuing Country 2' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text='Issue Province' />
                                    <TextField
                                        setStateValue={value => handleCoapplicantChange("issueProvince_2", value)}
                                        defaultValue={coapplicant.issueProvince_2}
                                        type={applicant.anyCoapplicant && expandedID2 ? 'mandatory' : 'optional'} name='Co-Applicant Issue Province 2' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text='Issuing Authority' />
                                    <DropdownList
                                        options={Authorities}
                                        setStateValue={value => handleCoapplicantChange("issueAuthority_2", value)}
                                        defaultValue={coapplicant.issueAuthority_2}
                                        type={applicant.anyCoapplicant && expandedID2 ? 'mandatory' : 'optional'} name='Co-Applicant Issuing Authority 2' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text='Issue Date' />
                                    <DateField
                                        setStateValue={value => handleCoapplicantChange("issueDate_2", value)}
                                        defaultValue={coapplicant.issueDate_2}
                                        type={applicant.anyCoapplicant && expandedID2 ? 'date' : 'optional'} name='Co-Applicant ID Issue Date 2' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldWithHelper text='Expiry Date' helperText="Please provide the date when your identification document will expire."/>
                                    <DateField
                                        setStateValue={value => handleCoapplicantChange("expireDate_2", value)}
                                        defaultValue={coapplicant.expireDate_2}
                                        minYear={-50}
                                        isExpriyD={true}
                                        disabled={coapplicant.idType_2.includes('Canadian Citizenship')}
                                        type={applicant.anyCoapplicant && expandedID2 && !coapplicant.idType_2.includes('Canadian Citizenship') ? 'date' : 'optional'} name='Co-Applicant Expiry Date 2' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace * 2}>
                                <Box>
                                    <TextFieldText text='Has your ID been verified by the advisor physically/face-to-face?' largeBox={true} />
                                    <RadioGroupBoolean
                                        setStateValue={value => handleCoapplicantChange("verified_2", value)}
                                        defaultValue={coapplicant.verified_2}
                                        type={applicant.anyCoapplicant && expandedID2 ? 'mandatory' : 'optional'} name='Co-Applicant ID 2 is verified' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace * 2} >
                                <TextFieldText text='Upload ID 2' largeBox={true} />
                                <InputFileUpload 
                                    uploadedFile={rawFiles.coApplicantID2Urls}
                                    setUploadedFile={value => saveFile("coApplicantID2Urls", value)}
                                />
                            </Grid>

                        </Grid>
                    </Collapse>

                </Box>
            )}

        </Box>
    );
};