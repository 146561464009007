import { Box, Grid, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../../../reducers/applicantReducer";
import { setCoapplicantInfo } from "../../../../../../reducers/coapplicantReducer";
import { useApplication } from "../../../../../../contexts/ApplicationContext";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RadioGroup from "../../component/investProfileRadioGroup";
import TextFieldText from "../../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TabPanel from "../../../../../../components/smallComponents/tabPanel/TabPanel";
import {answerAttributes } from "../../../../../../constants/lists";
import { getDropdownList } from "../../../../../../services/dropdownService";
import { fetchApplicationsInvestorProfile} from '../../../../../../services/fetchInfoService';

/**
 * @param {String} applicantType - Determines if the applicant is the 'main or 'co' applicant
 * @returns - Investor questionaire subsection
 */


export default function InvestorQuestionSubsection({ applicantType }) {
    
    const dispatch = useDispatch();

    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const application = useSelector((state) => state.applicationInfo);
    const applicationType = useParams().applicationType;

    const hasFinancialAnalysis = ['regular', 'quick'].includes(applicationType);

    const { handleMainInvestorScoreChange, handleCoInvestorScoreChange,
        mainInvestorScoreCalc, coInvestorScoreCalc } = useApplication();

    const [TabPanelValue, setTabPanelValue] = useState(0);
    const [disableBackButton, setDisableBackButton] = useState(true);
    const [disableForwardButton, setDisableForwardButton] = useState(false);
    const [investorOptions, setInvestorOptions] = useState(new Map());
    const [investorQuestions, setInvestorQuestions] = useState([]);
    const [investorProfileInfo, setInvestorProfileInfo] = useState([]);
    const [investorQuestionsCategory, setInvestorQuestionsCategory] = useState([]);
    const updatedQues = [...applicant.q_ques];
    const updatedQuesCate = [...applicant.q_quesCate];
    let updatedCOQues = [...coapplicant.q_ques];
    let updatedCOQuesCate = [...coapplicant.q_quesCate];
    const fetchInvestorOptions = async ()=>{
        const investorOptionsList = await getDropdownList("aiFKYCQuestionOption");
         // Create a map to store options grouped by question
        const optionsMap = new Map();

        investorOptionsList.forEach(item => {
            const questionId = item['questionID'];
            const option = item['option'];
            if (!optionsMap.has(questionId)) {
                optionsMap.set(questionId, []);
              }
        
              // Add the option to the corresponding question's array
              optionsMap.get(questionId).push(option);
        });

        return optionsMap;
        

    }

    const fetchAnswerAttributes = async ()=>{
        const investorQuestionsList = await getDropdownList("aiFKYCQuestion");
        return investorQuestionsList;
    }

    const handleBackIconButtonChange = () => {
        if (TabPanelValue === 1) {
            setDisableBackButton(true);
        }
        if (disableForwardButton) {
            setDisableForwardButton(false);
        }
        setTabPanelValue(TabPanelValue - 1);
    };

    const handleForwardIconButtonChange = () => {
        if (TabPanelValue === ((investorQuestions).length - 2)) {
            setDisableForwardButton(true);
        }
        if (disableBackButton) {
            setDisableBackButton(false);
        }
      
        setTabPanelValue(TabPanelValue + 1);
        
    };

    const handleRadioChange = (index, value, ansIndex) => {
        let score = 0;
        switch (ansIndex) {
            case 0:
                score = 1;
                break;
            case 1:
                score = 2;
                break;
            case 2:
                score = 5;
                break;
            case 3:
                score = 10;
                break;
            case 4:
                score = 20;
                break;
            default:
                break;
        }
        const updatedValues = [...selectedValues];
        updatedValues[index] = { value, ansIndex };  // Store both value and ansIndex
        setSelectedValues(updatedValues);

        if (applicantType === 'Applicant') {
            dispatch(setApplicantInfo({ attribute: answerAttributes[index], value: [ansIndex+1, value, score] }));
            handleMainInvestorScoreChange(index, score)
        } else {
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[index], value: [ansIndex+1, value, score] }))
            handleCoInvestorScoreChange(index, score)
        }

    };

    const setAnswer = (index, value, ansIndex, applicantTypeinput) => {
        let score = 0;
        switch (ansIndex) {
            case 0:
                score = 1;
                break;
            case 1:
                score = 2;
                break;
            case 2:
                score = 5;
                break;
            case 3:
                score = 10;
                break;
            case 4:
                score = 20;
                break;
            default:
                break;
        }
        const updatedValues = [...selectedValues];
        updatedValues[index] = { value, ansIndex };  // Store both value and ansIndex
        setSelectedValues(updatedValues);

        if (applicantTypeinput === "Applicant" && applicantType === 'Applicant') {
            dispatch(setApplicantInfo({ attribute: answerAttributes[index], value: [ansIndex+1, value, score] }));
            handleMainInvestorScoreChange(index, score)
        } else if(applicantTypeinput === "Co-Applicant" && applicantType === 'Co-Applicant'){
            handleCoInvestorScoreChange(index, score)
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[index], value: [ansIndex+1, value, score] }))
        }
            
    }

    const scoreBoardSpace = 12;
    const arrowButtonSpace = 1;
    const questionSpace = 10;
    const disableQuestionIndex = hasFinancialAnalysis ? [0, 1, 2] : [0];

    const handleFetchedInfo = (fetchedInfo) => {
        fetchedInfo.map((rec, index) => {
            const questionId = rec.questionID;
            const answerIndex = parseInt(rec.optionID, 10)-1;
            const answer = rec.answer;
            const question = rec.question;
            const questionCategory = rec.questionCategory;
            if (questionId >3 && answer && answer.length > 0) { 
                // Calculate based on the default option
                setAnswer(index, answer, answerIndex, rec.applicantType);
            }
            
            // Update arrays for Redux state
            if (!updatedQues.includes(question)) {  
                updatedQues.push(question); 
                updatedQuesCate.push(questionCategory);
            }
            if (rec.applicantType !== 'Applicant' && !updatedCOQues.includes(question)) {
                updatedCOQues.push(question); 
                updatedCOQuesCate.push(questionCategory);
            }
            
            setSelectedValues(
                investorQuestions.map((question, index) => ({
                    value: question,
                    ansIndex: answerIndex
                }))
            );
        })

        
        // Dispatch Redux updates
        if (applicantType === 'Applicant') {
            dispatch(setApplicantInfo({ attribute: 'q_ques', value: updatedQues }));
            dispatch(setApplicantInfo({ attribute: 'q_quesCate', value: updatedQuesCate }));
        } else {
            dispatch(setCoapplicantInfo({ attribute: 'q_ques', value: updatedCOQues }));
            dispatch(setCoapplicantInfo({ attribute: 'q_quesCate', value: updatedCOQuesCate }));
        }
    }
   
    const fetchApplcantInfoInvestorProfile = async (questions)=>{
        const investorProfileInfo = await fetchApplicationsInvestorProfile(application.applicationID);
        return investorProfileInfo;
    }

    const handleDefaultOption = (questions, options, categories) => {
        questions.forEach((question, index) => {
            // in DB, key starts from 1
            const optionKey = index + 1; 
            const optionValues = options.get(optionKey); 
    
            if (index > 2 && optionValues && optionValues.length > 0) {
                // Identify the default option and its index
                const defaultOption = optionValues[optionValues.length - 1];
                const defaultAnsIndex = optionValues.length - 1; // Assuming ansIndex is the position
                
                // Calculate based on the default option
                handleRadioChange(index, defaultOption, defaultAnsIndex);
            }
    
            // Update arrays for Redux state
            if (!updatedQues.includes(question)) {  
                updatedQues.push(question); 
                updatedQuesCate.push(categories[index]);
            }
    
            if (applicantType === 'Co-Applicant' && !updatedCOQues.includes(question)) {
                updatedCOQues = [...updatedCOQues, question];
                updatedCOQuesCate = [...updatedCOQuesCate, categories[index]];
            }
        });
    
        // Dispatch Redux updates
        if (applicantType === 'Applicant') {
            dispatch(setApplicantInfo({ attribute: 'q_ques', value: updatedQues }));
            dispatch(setApplicantInfo({ attribute: 'q_quesCate', value: updatedQuesCate }));
        } else {
            dispatch(setCoapplicantInfo({ attribute: 'q_ques', value: updatedCOQues }));
            dispatch(setCoapplicantInfo({ attribute: 'q_quesCate', value: updatedCOQuesCate }));
        }

        
    
        // Update selected values
        setSelectedValues(
            investorQuestions.map((question, index) => ({
                value: applicantType === 'Applicant' 
                    ? applicant[answerAttributes[index]]
                    : coapplicant[answerAttributes[index]],
                ansIndex: 4, // Default ansIndex, adjust as needed
            }))
        );
    };
    
    

    const [selectedValues, setSelectedValues] = useState(
        investorQuestions.map((question, index) => ({
            value: applicantType === 'Applicant' 
                ? applicant[answerAttributes[index]]
                : coapplicant[answerAttributes[index]],
            ansIndex: index, // Initial ansIndex (you can customize this as needed)
        }))
    );
    
   
    useEffect(() => {
        const fetchInfo = async () => {
            const questions = await fetchAnswerAttributes();
            setInvestorQuestions(questions.map(x=>x['question']));
            setInvestorQuestionsCategory(questions.map(x=>x['category']));
            const options = await fetchInvestorOptions();
            setInvestorOptions(options);
            const profile = await fetchApplcantInfoInvestorProfile(questions, options);
            setInvestorProfileInfo(profile);
        }
        fetchInfo();
     }, []);

     useEffect(() => {
        const KYCInfo = investorProfileInfo.filter(x=>x.applicantType === applicantType);
        if(KYCInfo.length >0 ){            
            handleFetchedInfo(KYCInfo);
        } else {
            handleDefaultOption(investorQuestions, investorOptions, investorQuestionsCategory);
        }
     }, [investorProfileInfo, investorQuestions, investorOptions, investorQuestionsCategory]);

     const applicantAnswer = (index) => {
        const ans = applicantType === 'Applicant' ? applicant[answerAttributes[index]][1] : coapplicant[answerAttributes[index]][1];
        if(index >= 3) {
            return ans;
        } else {
            // loop through the option without index and find the option equivalent to ans 
            const optionsWithoutIndex = investorOptions.get(index+1)?.map(option => option.split('. ')[1]);
            for(let i = 0; i < optionsWithoutIndex?.length; i++) {
                if(optionsWithoutIndex[i] === ans) {
                    return investorOptions.get(index+1)[i];
                }
            }
        }
    }

    return (
        <Box className="ml-7 mr-7 mt-5 mb-5" >
            <Grid container spacing={2}>

                <Grid item xs={scoreBoardSpace} >
                    {applicantType === 'Applicant' ?
                        <Box className="mt-5 mb-5 flex justify-center items-center border-4 border-gray-400 p-3 rounded-xl">
                            <span className="text-2xl font-semibold mr-10">
                                Main Applicant Score:
                            </span>
                            <span className="text-2xl font-bold text-indigo-600">
                                {mainInvestorScoreCalc}
                            </span>
                        </Box>
                        :
                        <Box className="mt-5 mb-5 flex justify-center items-center border-4 border-gray-400 p-3 rounded-xl">
                            <span className="text-2xl font-semibold mr-10">
                                Co-Applicant Score:
                            </span>
                            <span className="text-2xl font-bold text-indigo-600">
                                {coInvestorScoreCalc}
                            </span>
                        </Box>
                    }
                </Grid>

                <Grid item xs={arrowButtonSpace} display="flex" alignItems="center" justifyContent="center">
                    <IconButton onClick={handleBackIconButtonChange} disabled={disableBackButton} >
                        <ArrowBack sx={{ fontSize: 45 }} />
                    </IconButton>
                </Grid>
                  <Grid item xs={questionSpace}>
                    {investorQuestions.length > 0 && investorQuestions.map((question, index) => (
                        <TabPanel value={TabPanelValue} index={index} key={index} className="ml-5">
                            <TextFieldText text={`${index + 1}. ${question}`} largeBox={true} />
                            <RadioGroup 
                                options={investorOptions.get(index+1) || []} // Make sure to safely access options
                                setStateValue={(value, ansIndex) => handleRadioChange(index, value, ansIndex)}
                                defaultValue={applicantAnswer(index)}
                                disabled={disableQuestionIndex.includes(index)}
                            />
                        </TabPanel>
                    ))}
                </Grid>

                <Grid item xs={arrowButtonSpace} display="flex" alignItems="center" justifyContent="center">
                <IconButton onClick={handleForwardIconButtonChange} disabled={disableForwardButton} >
                        <ArrowForward sx={{ fontSize: 45 }} />
                    </IconButton>
                </Grid>

            </Grid>
        </Box>
    );
};