import "./App.css";
import { Button, Box } from "@mui/material";

// Components
import Topbar from "./components/mediumComponents/topbar/Topbar";
import PreApplicationPage from "./pages/PreApplicationPage/PreApplicationPage.jsx";
import Application from "./pages/ApplicationPage/ApplicationPage";
import Account from "./pages/AccountPage/AccountSettingPage";
import ApplicationDetails from "./pages/SummaryPage/ApplicationDetailsPage.jsx";
import Summary from "./pages/SummaryPage/SummaryPage.jsx";
import LogoutPage from "./pages/Authentication/LogoutPage";
import TermsAndConditions from './pages/LegalStuff/TermsAndConditions';
import PrivacyPolicy from "./pages/LegalStuff/PrivacyPolicy.jsx";
import HeaderBox from './components/smallComponents/headerBox/headerBox';
// Functionality
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { MsalProvider } from '@azure/msal-react';
import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ApplicationProvider } from './contexts/ApplicationContext.js';
import AdditionalInfoPage from './pages/AdditionalInfoPage/AdditionalInfoPage.jsx';
import AdditionalInfoSummaryPage from './pages/AdditionalInfoSummaryPage/AdditionalInfoSummaryPage.jsx';
import AdditionalDetailsPage from './pages/AdditionalInfoSummaryPage/AdditionalDetailsPage.jsx';
import useFetchMe from './hooks/useFetchMe.js';
import { useDispatch } from 'react-redux';
import useIdToken from './hooks/useIdToken.js';
import { loginRequest, signupRequest } from './authConfig.js';
import { RawFileProvider } from "./contexts/FileContext.js";
const topbarProps = {
  titles:
    [
      // {
      //   name: 'Need Help?',
      //   link: 'need-help'
      // }
    ],
  userName: '',
  menuOptions:
    [
      {
        name: 'Home',
        link: 'home'
      },
      { name: 'My Account',
        link: 'home/my-account' },
      {
        name: 'Logout',
        link: 'logout'
      }
    ]

};

function getCommonRoutes() {
  return [
    <Route key="terms-and-conditions" path="/terms-and-conditions" element={<TermsAndConditions />} />,
    <Route key="privacy-policy"       path="/privacy-policy"       element={<PrivacyPolicy />} />,
  ];
}

const WrappedView = () => {
  const { instance } = useMsal();
  const hasEffectRun = useRef(false);
  const dispatch = useDispatch();

  const { idToken } = useIdToken();
  useFetchMe(!!idToken);

  useEffect(() => {
    const initializeMsal = async () => {
      await instance.initialize();
      try {
        const response = await instance.handleRedirectPromise();
        if (response !== null) {
          topbarProps.userName = response.account.name;
          hasEffectRun.current = true; // Mark effect as run
        }
      } catch (error) {
        console.error("Error initializing MSAL: ", error);
      }
    };

    if (!hasEffectRun.current) {
      initializeMsal();
    }
  }, [dispatch, instance]);

  const Login = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.error(error);
    }
  };

  const Signup = async () => {
    try {
      await instance.loginRedirect(signupRequest);
    } catch (error) {
      console.error(error);
    }
  };

  let welcomeText ='';

  if (process.env.REACT_APP_DISPLAY_EXTERNAL_TITLE === 'true'){
      welcomeText = 'Welcome to AiFundTech';
  }
  else {
      welcomeText = 'Welcome to Ai Financial Online Portal';
  }
  
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <ApplicationProvider>
          <RawFileProvider>
            <Router>
              <Topbar titles={topbarProps.titles} menuOptions={topbarProps.menuOptions} />
              <Routes>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<PreApplicationPage />} />
                <Route path="/home/:1" element={<PreApplicationPage />} />
                <Route path="/home/:1/:2" element={<PreApplicationPage />} />
                <Route path="/home/:1/:2/:3" element={<PreApplicationPage />} />
                <Route path="/home/:1/:2/:3/:4" element={<PreApplicationPage />} />
                <Route path="/home/:1/:2/:3/:4/:5" element={<PreApplicationPage />} />
                <Route path="/application/:applicationType/general" element={<Application />} />
                <Route path="/application/:applicationType/:pageName/:applicationId" element={<Application />} />
                <Route path='/summary' element={<Summary />} />
                <Route path="/additionalInfo/:applicationId" element={<AdditionalInfoPage /> }/>
                <Route path="/additionalInfoSummary/:applicationId" element={<AdditionalInfoSummaryPage />}/>
                <Route path="/additionalDetails/:applicationID" element={<AdditionalDetailsPage />}/>
                <Route path="/additionalInfo/:fundType/:applicationId" element={<AdditionalInfoPage />}/>
                <Route path="/home/my-account" element={<Account />} />
                <Route path="/details/:fundType/:applicationType/:applicationId" element={<ApplicationDetails />} />
                <Route path="/logout" element={<LogoutPage />} />
                {getCommonRoutes()}
              </Routes>
            </Router>
            </RawFileProvider>
        </ApplicationProvider>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Router>
          <Routes>
            <Route path="/" element={
              <div className="alert alert-warning text-center" role="alert">
                <Box className="logo-container">
                    <img className="logo" src='/AiF_Logo.png' alt="Logo" />
                </Box>
                <HeaderBox text={welcomeText} />
                <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
                  <Button variant="contained" size="large" onClick={() => Login()}>
                    Login
                  </Button>

                  {
                    process.env.REACT_APP_ALLOW_SIGNUP === 'true' && (
                      <Button variant="contained" size="large" onClick={() => Signup()}>
                        Signup
                      </Button>
                    )
                  }
                </div>
              </div>
            }/>
            {getCommonRoutes()}
          </Routes>
        </Router>
      </UnauthenticatedTemplate>
    </div>
  );
};

function App({ instance }) {
  return (
    <MsalProvider instance={instance}>
      <WrappedView />
    </MsalProvider>
  );
}

export default App;
