import { Box, Grid, IconButton, Tabs, Tab, Snackbar, Alert } from "@mui/material";
import { AddBox } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import { setApplicationInfo } from "../../../../reducers/applicationReducer";
import { useApplication } from "../../../../contexts/ApplicationContext";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import NumberField from "../../../../components/smallComponents/numberField/NumberField";
import NumberFieldMoney from "../../../../components/smallComponents/numberField/NumberFieldMoney";
import TextField from "../../../../components/smallComponents/textField/TextField";
import RadioGroup from "../../../../components/smallComponents/radioGroup/RadioGroup";
import RadioGroupBoolean from "../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import DateField from "../../../../components/smallComponents/dateField/DateField";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import LoanRatio from "../../../../components/enumTypes/LoanRatio";
import MonthlyRepayment from "../../../../components/enumTypes/MonthlyRepayment";
import DocUploader from "../../../../components/mediumComponents/uploadDoc/docUploader";
import { repaymentTermOptions, institutions, investmentCompanies, provinces, typeOfTransfer, typeOfAmount, sourceOfInitial, frequencyOfPAD } from "../../../../constants/lists";
import Table from "../../../../components/mediumComponents/table/Table";
import './style/SourceOfContribution.css';
import { fetchApplicantsourceofConInfo} from '../../../../services/fetchInfoService';
import { axiosInstance } from "../../../../axios";
import TextFieldWithHelper from "../../../../components/smallComponents/helper/Helper";
/**
 * @returns - SourceOfContribution main field component for the Application Page
 */


export default function SourceOfContribution() {

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);

    const { clickNextButton } = useApplication();

    const [tabValue, setTabValue] = useState(0);

    const [fund, setFund] = useState({ fundName: '', fundCode: '' });
    const [loanAmountApplied, setLoanAmountApplied] = useState(parseInt(applicant.loanAmountApplied || 0));
    const [initLoanAmount, setInitLoanAmount] = useState(parseInt(applicant.loanAmountApplied || 0));
    const [collateralAmount, setCollateralAmount] = useState(parseInt(applicant.collateralAmount || 0));
    // State to trigger the clearing of the input fields in child components
    const [isClear, setIsClear] = useState(false);

    // Control the display of snackbar messages
    const [openError, setOpenError] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    
    const handleCollateralChange = (value)=> {
        setCollateralAmount(value);
        handleApplicantChange("amountForCollateral", value);
    }

    const applicationType = useParams().applicationType;
    const minLoanAmount = applicationType === 'quick' ? 10000 : 200001;
    const maxLoanAmount = applicationType === 'quick' ? 200000 : 800000;
    const tabOneLabel = {
        'quick': 'Main Section',
        'regular': 'Main Section',
        'transfer': 'Loan Transfer Detail',
        'nonreg': 'Source Details',
        'resp': 'Source Details',
        'rrsp': 'Source Details',
        'tfsa': 'Source Details',
    };
    const tabTwoLabel = {
        'quick': 'Additional Info',
        'regular': 'Additional Info',
        'transfer': 'Current Investment Info',
        'nonreg': 'Pre-Authorized Debits',
        'resp': 'Pre-Authorized Debits',
        'rrsp': 'Pre-Authorized Debits',
        'tfsa': 'Pre-Authorized Debits',
    };

    const isFundTransfer = applicant.sourceOfInitialContribution === 'Transfer from another institution';
    const hasPAD = applicant.setUpRecurringContributionsRegularPAD;

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }));
    };

    const handleLoanAmountChange = (value) => {
        dispatch(setApplicantInfo({ attribute: "loanAmountApplied", value: value }));
        setLoanAmountApplied(value);
    }

    const application = useSelector((state) => state.applicationInfo);
    const fetchApplcantInfoSourceofCon = async ()=>{
    
        const res = await axiosInstance.get(`source/${application.applicationID}`);
        const info = res.data
        if(info){
             setLoanAmountApplied(info.amountApplied || 0);
             setInitLoanAmount(info.amountApplied || 0);
             dispatch(setApplicantInfo({ attribute: 'loanAmountApplied', value: info.amountApplied || 0}));
             dispatch(setApplicantInfo({ attribute: 'newLoan', value: info.newLoanorNot === null? true : info.newLoanorNot }));
             dispatch(setApplicantInfo({ attribute: 'loanRatio', value: info.loanRatio === null? "100%" : info.loanRatio }));
             dispatch(setApplicantInfo({ attribute: 'monthlyRepaymentOption', value: info.monthlyRepaymentOption }));
             dispatch(setApplicantInfo({ attribute: 'existingLoanAmount', value: info.existingLoanAmount || 0 }));
             dispatch(setApplicantInfo({attribute: "existingSegFundInvestmentAccount", value: info.existingSegFundInvestmentAccount || 0}));
            };
        }

    
    useEffect(() => {
        fetchApplcantInfoSourceofCon();
    }, []);

    useEffect(()=>{
        switch(applicant.loanRatio){
            case LoanRatio.ONEHUNDRED:
                setCollateralAmount(0);
                dispatch(setApplicantInfo({attribute: "amountForCollateral", value: ""}));

                break;
            case LoanRatio.ONEFORONE:
                setCollateralAmount(loanAmountApplied);
                dispatch(setApplicantInfo({attribute: "amountForCollateral", value: loanAmountApplied}));
                break;
            case LoanRatio.TWOFORONE:
                setCollateralAmount(Math.floor(loanAmountApplied / 2));
                dispatch(setApplicantInfo({attribute: "amountForCollateral", value: Math.floor(loanAmountApplied / 2)}));
                break;
            case LoanRatio.THREEFORONE: 
                setCollateralAmount(Math.floor(loanAmountApplied / 3));
                dispatch(setApplicantInfo({attribute: "amountForCollateral", value: Math.floor(loanAmountApplied / 3)}));  
                break;
            default:
                break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanAmountApplied, applicant.loanRatio]);

    const handleExistingLoanAmountChange = (value) => {
        const newLoanAmountApplied = initLoanAmount - value;
        setLoanAmountApplied(newLoanAmountApplied);
        handleLoanAmountChange(newLoanAmountApplied);
        handleApplicantChange( 'existingLoanAmount', value);
    }

    const handleApplicantNewLoanChange = (attribute, value) => {
        // Clear existing loan amount info if new loan is selected
        if (value) {
            dispatch(setApplicantInfo({ attribute: "newLoanorNot", value: 1 }));
            dispatch(setApplicantInfo({ attribute: "existingLoanAmount", value: "" }));
            dispatch(setApplicantInfo({ attribute: "existingSegFundInvestmentAccount", value: "" }));
            dispatch(setApplicantInfo({ attribute: "loanAmountApplied", value: initLoanAmount }));
        } else{
            dispatch(setApplicantInfo({ attribute: "newLoanorNot", value: 0 }));
        }
        dispatch(setApplicantInfo({ attribute, value }));
    };

    const handleApplicantLoanRatioChange = (attribute, value) => {
        // Clear amount for collateral if loan ratio is 100%
        if (value === LoanRatio.ONEHUNDRED) {
            dispatch(setApplicantInfo({ attribute: "amountForCollateral", value: "" }));
        }
        dispatch(setApplicantInfo({ attribute, value }));
    };

    const handleApplicantRepaymentOptionChange = (attribute, value) => {
        // Clear repayment terms if interest only is selected
        if (value !== MonthlyRepayment.PRINCIPALANDINTEREST) {
            dispatch(setApplicantInfo({ attribute: "repaymentTerms", value: '' }));
        }
        dispatch(setApplicantInfo({ attribute, value }));
    };

    const handleApplicantInitialContributionChange = (attribute, value) => {
        // Clear fund transfer info if source is not fund transfer
        if (value !== 'Transfer from another institution') {
            dispatch(setApplicantInfo({ attribute: "relinquishingInstitution", value: '' }));
            dispatch(setApplicantInfo({ attribute: "relinquishingInstitutionStreetNumber", value: '' }));
            dispatch(setApplicantInfo({ attribute: "relinquishingInstitutionStreetName", value: '' }));
            dispatch(setApplicantInfo({ attribute: "relinquishingInstitutionUnit", value: '' }));
            dispatch(setApplicantInfo({ attribute: "relinquishingInstitutionCity", value: '' }));
            dispatch(setApplicantInfo({ attribute: "relinquishingInstitutionProvince", value: '' }));
            dispatch(setApplicantInfo({ attribute: "relinquishingInstitutionPostalCode", value: '' }));
            dispatch(setApplicantInfo({ attribute: "investmentDescription", value: '' }));
            dispatch(setApplicantInfo({ attribute: "policyAccountNumber", value: '' }));
            dispatch(setApplicantInfo({ attribute: "ownFundTypeOfTransfer", value: '' }));
            dispatch(setApplicantInfo({ attribute: "ownFundTransTypeOfAmount", value: '' }));
            dispatch(setApplicantInfo({ attribute: "ownFundTransAmount", value: '' }));
            dispatch(setApplicantInfo({ attribute: "isSameBeneficiary", value: null }));
            // Update the global state of own fund request type to New Case
            dispatch(setApplicationInfo({ attribute: "requestType", value: 'New Case' }));
        }
        else {
            // Update the global state of own fund request type to New Transfer
            dispatch(setApplicationInfo({ attribute: "requestType", value: 'New Transfer' }));
        }
        dispatch(setApplicantInfo({ attribute, value }));
    };

    const handleApplicantHasPADChange = (attribute, value) => {
        // Clear PAD info if no PAD is selected
        if (!value) {
            dispatch(setApplicantInfo({ attribute: "regularPADAmount", value: '' }));
            dispatch(setApplicantInfo({ attribute: "regularPADFrequency", value: '' }));
            dispatch(setApplicantInfo({ attribute: "firstRegularPADDate", value: '' }));
        }
        dispatch(setApplicantInfo({ attribute, value }));
    };

    const handleAddFund = () => {

        dispatch(setApplicantInfo({ attribute: 'currentInvestmentFundName', value: [...applicant.currentInvestmentFundName, fund.fundName] }));
        dispatch(setApplicantInfo({ attribute: 'currentInvestmentFundCode', value: [...applicant.currentInvestmentFundCode, fund.fundCode] }));

        // Trigger the clearing of the input fields in child components after adding a new fund
        // Reset the state to false after 0.1 seconds to resume input action in the child components
        setIsClear(true);
        setTimeout(() => setIsClear(false), 0.1);

        // Reset the local state to empty strings after adding a new fund
        setFund({ fundName: '', fundCode: '' });
    };

    const handleAddFundCheck = () => {
        if (fund.fundName === '' || fund.fundCode === '') {
            setOpenError(true);
        }
        else {
            handleAddFund();
            setOpenSuccess(true);
        }
    };

    const handleDeleteFund = (index) => {
        dispatch(setApplicantInfo({ attribute: 'currentInvestmentFundName', value: applicant.currentInvestmentFundName.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'currentInvestmentFundCode', value: applicant.currentInvestmentFundCode.filter((_, i) => i !== index) }));
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    };

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
    };

    const itemSpace = 4;
    const specialSpace = 6;

    const names = ['Fund Name', 'Fund Code'];
    const values =
        [
            applicant.currentInvestmentFundName,
            applicant.currentInvestmentFundCode
        ];

    const title = "Source of Contribution";
    const loanRatios = [
        LoanRatio.ONEHUNDRED,
        LoanRatio.ONEFORONE,
        LoanRatio.TWOFORONE,
        LoanRatio.THREEFORONE
    ];
    const repaymentOptions = [
        MonthlyRepayment.INTERESTONLY,
        MonthlyRepayment.PRINCIPALANDINTEREST
    ];
    const pageName = 'source';

  

    return (
        <Box className='max-w-screen-xl w-auto h-fit min-h-[80vh] ml-24 mr-24 source-of-contribution-box-overall'>

            <Box>
                <TitleText text={title} />
            </Box>

            <Box>
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className='ml-2'>
                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} >
                    <Tab style={{ fontSize: 16 }} label={tabOneLabel[applicationType]} value={0} />
                    <Tab style={{ fontSize: 16 }} label={tabTwoLabel[applicationType]} value={1} />
                </Tabs>
            </Box>

            {/* Regular and Quick Loans */}

            <Box className='mt-12 bg-mainfield-box rounded-2xl shadow-custom'>

                {['regular', 'quick'].includes(applicationType) && (
                    <Grid container spacing={2}>
                        <Grid item xs={specialSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldWithHelper text='Loan Amount Applied' helperText="Please specify the amount of money you are applying to borrow."/>
                            <NumberFieldMoney
                                setStateValue={value => handleLoanAmountChange(value)}
                                defaultValue={applicant.loanAmountApplied || 0}
                                type='number'
                                name='Loan Amount Applied'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                minValue={minLoanAmount}
                                maxValue={maxLoanAmount}
                            />
                        </Grid>

                        <Grid item xs={specialSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldWithHelper text='New Loan' helperText="Are you applying for a new loan or increasing existing loan amount?"/>
                            <RadioGroupBoolean
                                setStateValue={value => handleApplicantNewLoanChange("newLoan", value)}
                                defaultValue={applicant.newLoan}
                                type='mandatory'
                                name='Is New Loan'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={specialSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldWithHelper text='Loan ratio' helperText={`100% - Bank finances 100% of the investment
 3 For 1 - Bank lends up to 3 times the dollar amount pledged (in cash or in kind)
 2 For 1 - Bank lends up to 2 times the dollar amount pledged (in cash or in kind)
 1 For 1 - Bank matches the dollar pledged (in cash or in kind)`} />
                            <RadioGroup
                                options={loanRatios}
                                setStateValue={value => handleApplicantLoanRatioChange("loanRatio", value)}
                                defaultValue={applicant.loanRatio}
                                type='mandatory'
                                name='Loan ratio'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={specialSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldWithHelper text='Monthly Repayment Option' helperText=" Interest Only: Pay only the interest each month.
 Principal and Interest: Pay both the loan principal and interest each month."/>
                            <RadioGroup
                                options={repaymentOptions}
                                setStateValue={value => handleApplicantRepaymentOptionChange("monthlyRepaymentOption", value)}
                                defaultValue={MonthlyRepayment.INTERESTONLY}
                                type='mandatory'
                                name='Monthly Repayment Option'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={specialSpace} display={!applicant.newLoan & tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldText text='Existing Loan Amount' />
                            <NumberFieldMoney
                                setStateValue={value => handleExistingLoanAmountChange(value)}
                                defaultValue={applicant.existingLoanAmount}
                                type={!applicant.newLoan ? 'number' : 'optional'}
                                name='Existing Loan Amount'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={specialSpace} display={!applicant.newLoan & tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldText text='Existing Seg Fund Investment Account' />
                            <TextField
                                setStateValue={value => handleApplicantChange("existingSegFundInvestmentAccount", value)}
                                defaultValue={applicant.existingSegFundInvestmentAccount}
                                type={!applicant.newLoan ? 'digits' : 'optional'}
                                name='Existing Seg Fund Investment Account'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={specialSpace} display={applicant.loanRatio !== LoanRatio.ONEHUNDRED & tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Amount for Collateral' />
                            <NumberField
                                setStateValue={value => handleCollateralChange(value)}
                                defaultValue={collateralAmount}
                                type={applicant.loanRatio !== LoanRatio.ONEHUNDRED ? 'number' : 'optional'}
                                name='Amount for Collateral'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={specialSpace} display={applicant.monthlyRepaymentOption === MonthlyRepayment.PRINCIPALANDINTEREST & tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Repayment Terms' />
                            <DropdownList
                                options={repaymentTermOptions}
                                setStateValue={value => handleApplicantChange("repaymentTerms", parseFloat(value))}
                                defaultValue={applicant.repaymentTerms.toString()}
                                type={applicant.monthlyRepaymentOption === MonthlyRepayment.PRINCIPALANDINTEREST ? 'mandatory' : 'optional'}
                                name='Repayment Terms'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                    </Grid>
                )}

            </Box >

            {/* Loan Transfer */}

            <Box className='mt-12 bg-mainfield-box rounded-2xl shadow-custom'>

                {['transfer'].includes(applicationType) && (
                    <Grid container spacing={2}>

                        <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldText text='Loan Amount Transferred' />
                            <NumberField
                                setStateValue={value => handleApplicantChange("loanAmountTransferred", value)}
                                defaultValue={applicant.loanAmountTransferred}
                                type='number'
                                name='Loan Amount Transferred'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                minValue={0}
                                maxValue={1000000}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldText text='Loan From Bank' />
                            <DropdownList
                                options={institutions}
                                setStateValue={value => handleApplicantChange("loanFromBank", value)}
                                defaultValue={applicant.loanFromBank}
                                type='mandatory'
                                name='Loan From Bank'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldText text='Bank Account Number' />
                            <TextField
                                setStateValue={value => handleApplicantChange("bankAccountNumber", value)}
                                defaultValue={applicant.bankAccountNumber}
                                type='digits'
                                name='Bank Account Number'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Current/Original Investment Company' />
                            <DropdownList
                                options={investmentCompanies}
                                setStateValue={value => handleApplicantChange("currentOriginalInvestmentCompany", value)}
                                defaultValue={applicant.currentOriginalInvestmentCompany}
                                type='mandatory'
                                name='Current/Original Investment Company'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Current/Original Investment Account Number' />
                            <TextField
                                setStateValue={value => handleApplicantChange("currentOriginalInvestmentAccountNumber", value)}
                                defaultValue={applicant.currentOriginalInvestmentAccountNumber}
                                type='digits'
                                name='Current/Original Investment Account Number'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Current Investment Market Value' />
                            <NumberField
                                setStateValue={value => handleApplicantChange("currentInvestmentMarketValue", value)}
                                defaultValue={applicant.currentInvestmentMarketValue}
                                type='number'
                                name='Current Investment Market Value'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                minValue={0}
                                maxValue={9999999}
                            />
                        </Grid>

                    </Grid>
                )}

            </Box>

            {['transfer'].includes(applicationType) && (
                <Box display={tabValue === 1 ? 'block' : 'none'} >
                    <Table fieldNames={names} fieldValues={values} handleDelete={colIndex => handleDeleteFund(colIndex)} />
                </Box>
            )}

            {['transfer'].includes(applicationType) && (
                <Box className='mt-10 bg-mainfield-box rounded-2xl shadow-custom' >
                    <Grid container spacing={2}>

                        <Grid item xs={itemSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Current Investment Fund Name' />
                            <TextField
                                defaultValue={fund.fundName}
                                setStateValue={value => setFund({ ...fund, fundName: value })}
                                isClear={isClear}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Current Investment Fund Code' />
                            <TextField
                                defaultValue={fund.fundCode}
                                setStateValue={value => setFund({ ...fund, fundCode: value })}
                                isClear={isClear}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} className="pb-7" display={tabValue === 1 ? 'flex' : 'none'} alignItems="center" justifyContent="center">
                            <IconButton onClick={handleAddFundCheck} >
                                <AddBox sx={{ fontSize: 45 }} />
                                <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Add Fund</span>
                            </IconButton>
                        </Grid>

                    </Grid>
                </Box>
            )}

            {['transfer'].includes(applicationType) && (
                <Box className='mt-10 bg-mainfield-box rounded-2xl shadow-custom' >
                    <Grid container spacing={2}>

                        <Grid item xs={specialSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldText text='Upload Loan Statement' largeBox={true} />
                            <DocUploader
                                setStateValue={value => handleApplicantChange("uploadLoanStatementUrls", value)}
                                defaultValue={applicant.uploadLoanStatementUrls}
                                type='mandatory'
                                name='Applicant Loan Statement'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={false}
                                categoryName='Statement'
                            />
                        </Grid>

                        <Grid item xs={specialSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Upload Investment Statement' largeBox={true} />
                            <DocUploader
                                setStateValue={value => handleApplicantChange("uploadInvestmentStatementUrls", value)}
                                defaultValue={applicant.uploadInvestmentStatementUrls}
                                type='mandatory'
                                name='Applicant Investment Statement'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={false}
                                categoryName='Statement'
                            />
                        </Grid>

                    </Grid>
                </Box>
            )}

            {['transfer'].includes(applicationType) && (
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openError} autoHideDuration={4000} onClose={handleErrorClose}>
                    <Alert
                        onClose={handleErrorClose}
                        severity="warning"
                        variant="filled"
                        sx={{ width: '100%', fontSize: 20 }}
                    >
                        Please fill all required fields before adding
                    </Alert>
                </Snackbar>
            )}

            {['transfer'].includes(applicationType) && (
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openSuccess} autoHideDuration={4000} onClose={handleSuccessClose}>
                    <Alert
                        onClose={handleSuccessClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%', fontSize: 20 }}
                    >
                        Added Fund Successfully
                    </Alert>
                </Snackbar>
            )}

            {/* Own Funds */}

            <Box className='mt-12 bg-mainfield-box rounded-2xl shadow-custom'>

                {['nonreg', 'resp', 'rrsp', 'tfsa'].includes(applicationType) && (
                    <Grid container spacing={2}>

                        <Grid item xs={isFundTransfer ? itemSpace : specialSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldText text='Source(s) of Your Initial Contribution' />
                            <DropdownList
                                options={sourceOfInitial}
                                setStateValue={value => handleApplicantInitialContributionChange("sourceOfInitialContribution", value)}
                                defaultValue={applicant.sourceOfInitialContribution}
                                type='mandatory'
                                name='Source of Initial Contribution'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={isFundTransfer ? itemSpace : specialSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldText text='Total Amount for Contribution' />
                            <NumberField
                                setStateValue={value => handleApplicantChange("totalAmountForContribution", value)}
                                defaultValue={applicant.totalAmountForContribution}
                                minValue={0}
                                maxValue={10000000}
                                type='number'
                                name='Total Amount for Contribution'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                            <TextFieldText text='Relinquishing Institution' />
                            <TextField
                                setStateValue={value => handleApplicantChange("relinquishingInstitution", value)}
                                defaultValue={applicant.relinquishingInstitution}
                                type={isFundTransfer ? 'mandatory' : 'optional'}
                                name='Relinquishing Institution'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                            <TextFieldText text='Street Number' />
                            <TextField
                                setStateValue={value => handleApplicantChange("relinquishingInstitutionStreetNumber", value)}
                                defaultValue={applicant.relinquishingInstitutionStreetNumber}
                                type={isFundTransfer ? 'mandatory' : 'optional'}
                                name='Institution Street Number'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                            <TextFieldText text='Street Name' />
                            <TextField
                                setStateValue={value => handleApplicantChange("relinquishingInstitutionStreetName", value)}
                                defaultValue={applicant.relinquishingInstitutionStreetName}
                                type={isFundTransfer ? 'mandatory' : 'optional'}
                                name='Institution Street Name'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                            <TextFieldText text='Unit' />
                            <TextField
                                setStateValue={value => handleApplicantChange("relinquishingInstitutionUnit", value)}
                                defaultValue={applicant.relinquishingInstitutionUnit}
                                type={isFundTransfer ? 'mandatory' : 'optional'}
                                name='Institution Unit'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                            <TextFieldText text='City' />
                            <TextField
                                setStateValue={value => handleApplicantChange("relinquishingInstitutionCity", value)}
                                defaultValue={applicant.relinquishingInstitutionCity}
                                type={isFundTransfer ? 'mandatory' : 'optional'}
                                name='Institution City'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                            <TextFieldText text='Province' />
                            <DropdownList
                                options={provinces}
                                setStateValue={value => handleApplicantChange("relinquishingInstitutionProvince", value)}
                                defaultValue={applicant.relinquishingInstitutionProvince}
                                type={isFundTransfer ? 'mandatory' : 'optional'}
                                name='Institution Province'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                            <TextFieldText text='Postal Code' />
                            <TextField
                                setStateValue={value => handleApplicantChange("relinquishingInstitutionPostalCode", value)}
                                defaultValue={applicant.relinquishingInstitutionPostalCode}
                                type={isFundTransfer ? 'mandatory' : 'optional'}
                                name='Institution Postal Code'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={specialSpace} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                            <TextFieldText text='Investment Description' />
                            <TextField
                                setStateValue={value => handleApplicantChange("investmentDescription", value)}
                                defaultValue={applicant.investmentDescription}
                                type={isFundTransfer ? 'mandatory' : 'optional'}
                                name='Investment Description'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                largeBox={true}
                            />
                        </Grid>

                        <Grid item xs={specialSpace} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                            <TextFieldText text='Policy/Account Number' />
                            <TextField
                                setStateValue={value => handleApplicantChange("policyAccountNumber", value)}
                                defaultValue={applicant.policyAccountNumber}
                                type={isFundTransfer ? 'digits' : 'optional'}
                                name='Policy/Account Number'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                largeBox={true}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                            <TextFieldText text='Type of Transfer' />
                            <RadioGroup
                                options={['resp'].includes(applicationType) ? [typeOfTransfer[0]] : typeOfTransfer}
                                setStateValue={value => handleApplicantChange("ownFundTypeOfTransfer", value)}
                                defaultValue={applicant.ownFundTypeOfTransfer}
                                type={isFundTransfer ? 'mandatory' : 'optional'}
                                name='Type of Transfer'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                            <TextFieldText text='Type of Amount' />
                            <RadioGroup
                                options={typeOfAmount}
                                setStateValue={value => handleApplicantChange("ownFundTransTypeOfAmount", value)}
                                defaultValue={applicant.ownFundTransTypeOfAmount}
                                type={isFundTransfer ? 'mandatory' : 'optional'}
                                name='Type of Amount'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                            <TextFieldText text='Transfer Amount' />
                            <NumberField
                                setStateValue={value => handleApplicantChange("ownFundTransAmount", value)}
                                defaultValue={applicant.ownFundTransAmount}
                                minValue={0}
                                maxValue={applicant.ownFundTransTypeOfAmount === '$' ? 10000000 : 100}
                                type={isFundTransfer ? 'number' : 'optional'}
                                name='Transfer Amount'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        {['resp'].includes(applicationType) && (
                            <Grid item xs={itemSpace * 3} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                                <TextFieldText text='Does Relinquishing institution account have same beneficiary(s) as this new application?' largeBox={true} />
                                <RadioGroupBoolean
                                    setStateValue={value => handleApplicantChange("isSameBeneficiary", value)}
                                    defaultValue={applicant.isSameBeneficiary}
                                    type={isFundTransfer ? 'mandatory' : 'optional'}
                                    name='Is Same Beneficiary'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />
                                <span className="mx-[30px] leading-10 text-[#fa9237]" style={{ display: applicant.isSameBeneficiary === false ? 'block' : 'none' }}>
                                    Your application may be denied if you select "No"
                                </span>
                            </Grid>
                        )}

                        <Grid item xs={specialSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Set-up Recurring Contributions' />
                            <RadioGroupBoolean
                                setStateValue={value => handleApplicantHasPADChange("setUpRecurringContributionsRegularPAD", value)}
                                defaultValue={applicant.setUpRecurringContributionsRegularPAD}
                                type='mandatory'
                                name='Set-up Recurring Contributions'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={specialSpace} display={tabValue === 1 && hasPAD ? 'block' : 'none'} >
                            <TextFieldText text='PAD Amount' />
                            <NumberField
                                setStateValue={value => handleApplicantChange("regularPADAmount", value)}
                                defaultValue={applicant.regularPADAmount}
                                minValue={0}
                                maxValue={100000}
                                type={hasPAD ? 'number' : 'optional'}
                                name='PAD Amount'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={specialSpace} display={tabValue === 1 && hasPAD ? 'block' : 'none'} >
                            <TextFieldText text='PAD Fequency' />
                            <DropdownList
                                options={frequencyOfPAD}
                                setStateValue={value => handleApplicantChange("regularPADFrequency", value)}
                                defaultValue={applicant.regularPADFrequency}
                                type={hasPAD ? 'mandatory' : 'optional'}
                                name='PAD Fequency'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={specialSpace} display={tabValue === 1 && hasPAD ? 'block' : 'none'} >
                            <TextFieldText text='PAD Start Date' />
                            <DateField
                                setStateValue={value => handleApplicantChange("firstRegularPADDate", value)}
                                defaultValue={applicant.firstRegularPADDate}
                                minDay={-365}
                                maxDay={-15}
                                rangeType='day'
                                type={hasPAD ? 'date' : 'optional'}
                                name='PAD Start Date'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                    </Grid>
                )}

            </Box >

            <Box className='mt-10 bg-mainfield-box rounded-2xl shadow-custom' >

                {['nonreg', 'resp', 'rrsp', 'tfsa'].includes(applicationType) && (
                    <Grid container spacing={2}>

                        <Grid item xs={specialSpace} display={tabValue === 0 && isFundTransfer ? 'block' : 'none'} >
                            <TextFieldText text='Relinquishing Account Statement' largeBox={true} />
                            <DocUploader
                                setStateValue={value => handleApplicantChange("uploadAccountStatementUrls", value)}
                                defaultValue={applicant.uploadAccountStatementUrls}
                                type={isFundTransfer ? 'mandatory' : 'optional'}
                                name='Applicant Relinquishing Account Statement'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={false}
                                categoryName='Statement'
                            />
                        </Grid>

                    </Grid>
                )}

            </Box>

            {/* End of Input Fields */}

            <Box className="mt-12 flex justify-center">
                <PrevButton />
                <NextButton pageName={pageName} />
            </Box>

        </Box >
    );
};