import { Box } from "@mui/material"
import { ClientInfoSummary } from "../../models/clientInfoSummaryModel"
import { useApplication } from "../../contexts/ApplicationContext"
import { ClientInfoModel } from "../../models/clientInfoModel"
import { ApplicationInfoModel } from "../../models/applicationModel"
import { postApplication } from "../../services/applicationService"
import { getApplication } from "../../services/applicationService"
import { useDispatch, useSelector } from 'react-redux';
import { setApplicationInfo } from '../../reducers/applicationReducer';
import InfoField from "../../components/mediumComponents/summary/InfoField"
import CommentSection from "../../components/mediumComponents/summary/CommentSection"
import PrevButton from "../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../components/smallComponents/button/nextButton/NextButton";
import { fetchConfig } from "../../services/fetchConfigService";
import { useState, useEffect, useParams } from "react";
import PopupDialog from "../../components/smallComponents/alert/PopupDialog";
import { saveInfo, infoToSave } from "../../services/saveInfoService";
import { useNavigate } from "react-router-dom";
import { updateApp } from "../../services/appFieldUpdateService";
import { axiosInstance } from "../../axios"

/**
 * @returns - Summary page with all the information filled out by the user
 */


const SummaryPage = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [displaySummary, setDisplaySummary] = useState(true);
    const navigate = useNavigate();
    const applicant = useSelector((state) => state.applicantInfo);

    const dataObject = ClientInfoSummary();
    const dispatch = useDispatch();
    const application = useSelector((state) => state.applicationInfo);

    const applicantInfo = useSelector(state => state.applicantInfo);
    const coapplicantInfo = useSelector(state => state.coapplicantInfo);
    const applicationInfo = useSelector(state => state.applicationInfo);

    const { page, setPage } = useApplication();
    const pageMapping = {
        'General': 1,
        'Contact Information': 2,
        'Identification': 3,
        'Employment Information': 4,
        'Disclosure Information': 5,
        'Source of Contribution': 6,
        'Contribution Option' : 7,
        'Financial Analysis' : 8,
        'Beneficiary' : 9,
        'Annuitant' : 10,
        'Success Owner' :11,
        'Investor Profile' : 12,
        'Void Cheque': 7,
        'Identity Verification': 3,
        'Credit Report': 13,
        'Special Instructions' : 14,
        'Investor Profile - Applicant' :12

    };

    const handleCaseAutoGeneration = async (application) => {
        const info = infoToSave('investment-case', applicant, 'Applicant', application.applicationID, application.applicationType, application);
        // /push into Por_App_LoanInvestmentAndCasedetail
        saveInfo('investment-case', info);
    }

    const handleSubmit = async () => {
        //send POST request
        dispatch(setApplicationInfo({ attribute: 'applicationStatus', value: 'Submitted' }));
        //case auto generation after application submitted and push into Por_App_LoanInvestmentAndCasedetail
        await handleCaseAutoGeneration(application);
        // Record submission data such as submitted date, as well as change application status to submitted
        await recordSubmitData(application.applicationID);
        setDisplaySummary(false);
        setIsDialogOpen(true);
    };

    const recordSubmitData = async (applicationID) => {
        try {
            const res = await axiosInstance.post(`app/submission/${applicationID}`)
        } catch (error) {
            console.error(error);
        }
    }

    const handleDialogConfirm = () => {
        navigate("/home/my-account")
    }




    const handleBackButtonClick = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };


    const handleNextButtonClick = (attributePage) => {
        const pages = pageMapping[attributePage];
        setPage(pages-1); // set the page number for each attribute
    };
    const pageName = "summary";


    useEffect(() => {
        fetchConfig("Por_App_CustomerLiability", "DefaultPrimeRateforLoan").then((code) => {
            dispatch(setApplicationInfo({ attribute: 'defaultPrimeRate', value: code }));
        }); 
    }, []);
    
    const renderFields = (object) => {
        return (
            <div className="mx-4 grid grid-cols-2 gap-2">
                {Object.entries(object).map(([attr, obj]) => {
                    if ((attr === "Liabilities" || attr === "Assets" || attr === "Income" || attr === "Primary Beneficiary") && Array.isArray(obj)) {
                        return obj.map((itemObj, index) => (
                            <div key={index} className="w-full px-4 pb-4 col-span-2 border-b border-dashed border-slate-400">
                                <h3 className="text-xl font-bold mt-4 mb-2 underline underline-offset-2">{`${attr} ${index + 1}`}</h3>
                                <div className="w-full grid grid-cols-2 gap-2">
                                    {Object.entries(itemObj).map(([key, valueObj]) => (
                                        <InfoField
                                            key={key}
                                            description={valueObj.description}
                                            value={valueObj.value}
                                        />
                                    ))}
                                </div>
                            </div>
                        ));
                    } 
                })}
            </div>
        );
    };

    return (
        <>
            {displaySummary && <Box sx={{ flexGrow: 1 }}>
                <div className="grid grid-cols-1 h-fit m-4">
                    <div className="col-span-1 m-4 pb-8 h-fit border-2 border-gray-200">
                        <h1 className="text-start m-8 font-bold text-3xl">Summary</h1>
                        <div>
                            {Object.entries(dataObject).map(([attribute, object], index) => (
                                <>
                                    <h2 className="text-start m-8 font-bold text-2xl">{attribute}</h2>
                                    {Object.entries(object).map(([key, object]) => (
                                        <>
                                            <div className="flex items-center justify-between m-8 border-b border-gray-500">
                                                <h2 className="text-start font-bold text-xl">{key}</h2>
                                                <button
                                                    onClick={() => handleNextButtonClick(key)}
                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2" 
                                                >
                                                    Edit
                                                </button>
                                                
                                            </div>
                                            {renderFields(object)}
                                            {Object.entries(object).map(([key, object]) => {
                                                // Skip rendering for "Liabilities", "Assets", and "Income"
                                                if (!["Liabilities", "Assets", "Income", "Primary Beneficiary"].includes(key)) {
                                                    return (
                                                        <><h2 className="text-start m-8 font-bold text-lg">{key}</h2>
                                                        <div className="mx-4 grid grid-cols-2 gap-2" key={key}>

                                                            {Object.entries(object).map(([innerKey, innerObject]) => (
                                                                <InfoField
                                                                    key={innerKey}
                                                                    description={innerObject.description}
                                                                    value={innerObject.value} />
                                                            ))}
                                                        </div></>
                                                    );
                                                }
                                                // Return null for excluded keys (no rendering)
                                                return null;
                                            })}
                                        </>
                                    ))}
                                </>
                            ))}
                        </div>
                    </div>
                    {/* <CommentSection /> */}
                    <div className="flex justify-center">
                    <Box className="mt-12 flex justify-center">
                        <PrevButton />
                        <NextButton pageName={pageName} handleClick={handleSubmit}/>
                    </Box>
                    </div>

                </div>
            </Box>}
            <PopupDialog
                open={isDialogOpen}
                message={"We have received your application. Our advisors and operators will review your application."}
                confirmOption="Go to My Account Page"
                onConfirm={handleDialogConfirm}
                />
        </>
    );
};

export default SummaryPage;