import { FormLabel, styled } from "@mui/material"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextFieldWithHelper from "../helper/Helper";
import Stack from '@mui/material/Stack';
import { useState } from "react";
/** 
 * A (better) simple radio group boolean component without business logics 
 * */
const BooleanGroup = ({ bigLabel = false, label, value, onChange = () => {}, helperText="", horizontalOptions = false }) => {


    const BigFormLabel = styled(FormLabel)({
        fontSize: '1.5rem',
        color: 'black',
        marginBottom: '0.5rem',
        "&.Mui-focused": {
            color: "black", 
          },
    });

    const RegFormLabel = styled(FormLabel)({
        "&.Mui-focused": {
            color: "black",
          },
    });

    const handleChange = (event) => {
        onChange(event.target.value);
    }

    return (
        <FormControl >
            <Stack direction="row">
            {bigLabel? <BigFormLabel>{label}</BigFormLabel>: <RegFormLabel>{label}</RegFormLabel>}
            {helperText && <TextFieldWithHelper text="" helperText={helperText}/>}
            </Stack>
            <RadioGroup
                value={value}
                onChange={handleChange}
            >{horizontalOptions?
                <Stack direction="row">
                    <FormControlLabel value={true} control={<Radio />} label="YES" />
                    <FormControlLabel value={false} control={<Radio />} label="NO" />
                </Stack>:
                <>
                    <FormControlLabel value={true} control={<Radio />} label="YES" />
                    <FormControlLabel value={false} control={<Radio />} label="NO" />
                </>
            }
            </RadioGroup>
        </FormControl>
    )


}

export default BooleanGroup;