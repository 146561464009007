import { Box, Tab, Tabs } from "@mui/material";
import "../general/style/General.css";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import IdentificationInformation from "./IdentificationInformation";
import IdentificationCoAppInformation from "./IdentificationCoAppInformation";
import CoAppIdVerification from "./CoAppIdVerification";
import { useApplication } from "../../../../contexts/ApplicationContext";
import IdentificationTaxStatus from "./IdentificationTaxStatus";
import { useState, useEffect } from "react";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import { IDType, countries, reasons, authorities, nameAddressOptions } from '../../../../constants/lists';
import IdVerification from './IdVerification';
import { fetchApplicantidInfo, fetchApplicantidvInfo} from '../../../../services/fetchInfoService';
import { resetCoapplicantInfo, setCoapplicantInfo } from "../../../../reducers/coapplicantReducer";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import { fetchTaxStatus } from '../../../../services/fetchInfoService';
import { uploadToBlob } from '../../../../services/blobService';
import PopupDialog from "../../../../components/smallComponents/alert/PopupDialog";
import { useRawFiles } from "../../../../contexts/FileContext";
import { getFileNamesByUrls } from '../../../../utilities/fileHelper';
import { isFileValid } from '../../../../utilities/fileHelper';
/**
 * Renders the Identification component.
 * This component displays identification information and tax status for the applicant.
 * If there is a co-applicant, it also displays identification information and tax status for the co-applicant.
 * @returns {JSX.Element} The rendered Identification component.
 */


export default function Identification() {
    const dispatch = useDispatch();
    const files = useSelector((state) => state.files);
    const { rawFiles, setRawFiles, saveRawFile } = useRawFiles(); // Access raw files from context
    const { clickNextButton } = useApplication();

    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector(state => state.coapplicantInfo)
    const application = useSelector((state) => state.applicationInfo);
    const [tabValue, setTabValue] = useState(0);

    const applicationType = useParams().applicationType;

    const needTaxStatus = ['regular', 'quick', 'transfer', 'nonreg', 'rrsp'].includes(applicationType);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [dialogError, setDialogError] = useState('');

    const fetchApplcantIds = async ()=>{
        
        const applicantInfo = await fetchApplicantidInfo(application.applicationID);
        const applicantInfoidv = await fetchApplicantidvInfo(application.applicationID);
    
        if(applicantInfo.length >0 ){
            let isFirstID = true;
            let coIsFirstID = true;
            let updatedCustomerIDs = [];
            let updatedcoCustomerIDs = [];
            applicantInfo.map((applicantinfo, index) => {
               
                let fileURL = [];
               
                if (applicantinfo.applicantType === "Applicant") {
                
                    if(isFirstID){
                        dispatch(setApplicantInfo({ attribute: 'fullName', value: applicantinfo.fullNameOnID }));
                        dispatch(setApplicantInfo({ attribute: 'idType', value: applicantinfo.idType }));
                        dispatch(setApplicantInfo({ attribute: 'idNumber', value: applicantinfo.idNumber }));
                        dispatch(setApplicantInfo({ attribute: 'issueProvince', value: applicantinfo.issueProvince }));
                        dispatch(setApplicantInfo({ attribute: 'issueCountry', value: applicantinfo.issueCountry }));
                        dispatch(setApplicantInfo({ attribute: 'issueAuthority', value: applicantinfo.issueAuthority }));
                        dispatch(setApplicantInfo({ attribute: 'issueDate', value: applicantinfo.issueDate }));
                        dispatch(setApplicantInfo({ attribute: 'expireDate', value: applicantinfo.expiryDate === null? " ": applicantinfo.expiryDate}));
                        dispatch(setApplicantInfo({ attribute: 'verified', value: applicantinfo.verified }));
                        fileURL.push(applicantinfo.idLink);
                        dispatch(setApplicantInfo({ attribute: 'applicantID1Urls', value: fileURL }));
                        fileURL = [];
                        isFirstID =false;
                        const newCustomerID = applicantinfo.customerIdentityID;

                        // Avoid duplicates when appending
                        if (!updatedCustomerIDs.includes(newCustomerID)) {
                            updatedCustomerIDs.push(newCustomerID);
                        }
        
                    }else {

                        dispatch(setApplicantInfo({ attribute: 'fullName_2', value: applicantinfo.fullNameOnID }));
                        dispatch(setApplicantInfo({ attribute: 'idType_2', value: applicantinfo.idType }));
                        dispatch(setApplicantInfo({ attribute: 'idNumber_2', value: applicantinfo.idNumber }));
                        dispatch(setApplicantInfo({ attribute: 'issueProvince_2', value: applicantinfo.issueProvince }));
                        dispatch(setApplicantInfo({ attribute: 'issueCountry_2', value: applicantinfo.issueCountry }));
                        dispatch(setApplicantInfo({ attribute: 'issueAuthority_2', value: applicantinfo.issueAuthority }));
                        dispatch(setApplicantInfo({ attribute: 'issueDate_2', value: applicantinfo.issueDate }));
                        dispatch(setApplicantInfo({ attribute: 'expireDate_2', value: applicantinfo.expiryDate === null? " ": applicantinfo.expiryDate}));
                        dispatch(setApplicantInfo({ attribute: 'verified_2', value: applicantinfo.verified }));
                        fileURL.push(applicantinfo.idLink);
                        dispatch(setApplicantInfo({ attribute: 'applicantID2Urls', value: fileURL }));
                        fileURL = [];
                        isFirstID =true;
                        const newCustomerID = applicantinfo.customerIdentityID;

                        // Avoid duplicates when appending
                        if (!updatedCustomerIDs.includes(newCustomerID)) {
                            updatedCustomerIDs.push(newCustomerID);
                        }
        
                    }

                }else if(applicantinfo.applicantType === "Co-Applicant"){
                    if(coIsFirstID){
                        dispatch(setCoapplicantInfo({ attribute: 'fullName', value: applicantinfo.fullNameOnID }));
                        dispatch(setCoapplicantInfo({ attribute: 'idType', value: applicantinfo.idType }));
                        dispatch(setCoapplicantInfo({ attribute: 'idNumber', value: applicantinfo.idNumber }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueProvince', value: applicantinfo.issueProvince }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueCountry', value: applicantinfo.issueCountry }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueAuthority', value: applicantinfo.issueAuthority }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueDate', value: applicantinfo.issueDate }));
                        dispatch(setCoapplicantInfo({ attribute: 'expireDate', value: applicantinfo.expiryDate === null? " ": applicantinfo.expiryDate}));
                        dispatch(setCoapplicantInfo({ attribute: 'verified', value: applicantinfo.verified }));
                        fileURL.push(applicantinfo.idLink);
                        dispatch(setCoapplicantInfo({ attribute: 'coApplicantID1Urls', value: fileURL }));
                        fileURL = [];
                        coIsFirstID = false;
                        const newCustomerID = applicantinfo.customerIdentityID;
                        // Avoid duplicates when appending
                        if (!updatedcoCustomerIDs.includes(newCustomerID)) {
                            updatedcoCustomerIDs.push(newCustomerID);
                        }
                       
                    } else {
                        dispatch(setCoapplicantInfo({ attribute: 'fullName_2', value: applicantinfo.fullNameOnID }));
                        dispatch(setCoapplicantInfo({ attribute: 'idType_2', value: applicantinfo.idType }));
                        dispatch(setCoapplicantInfo({ attribute: 'idNumber_2', value: applicantinfo.idNumber }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueProvince_2', value: applicantinfo.issueProvince }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueCountry_2', value: applicantinfo.issueCountry }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueAuthority_2', value: applicantinfo.issueAuthority }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueDate_2', value: applicantinfo.issueDate }));
                        dispatch(setCoapplicantInfo({ attribute: 'expireDate_2', value: applicantinfo.expiryDate === null? " ": applicantinfo.expiryDate}));
                        dispatch(setCoapplicantInfo({ attribute: 'verified_2', value: applicantinfo.verified }));
                        fileURL.push(applicantinfo.idLink);
                        dispatch(setCoapplicantInfo({ attribute: 'coApplicantID2Urls', value: fileURL }));
                        fileURL = [];
                        coIsFirstID = true;
                        const newCustomerID = applicantinfo.customerIdentityID;
                        if (!updatedcoCustomerIDs.includes(newCustomerID)) {
                            updatedcoCustomerIDs.push(newCustomerID);
                        }
                    }
                }
               
                
            });
            dispatch(setApplicantInfo({ attribute: 'CustomerIDs', value: updatedCustomerIDs }));
            dispatch(setCoapplicantInfo({ attribute: 'CustomerIDs', value: updatedcoCustomerIDs }));
        }
        if(applicantInfoidv.length >0){
            applicantInfoidv.map((applicants, index) => {
                    
                let fileURL = [];
            
                if (applicants.applicantType === "Applicant") {
                    dispatch(setApplicantInfo({ attribute: 'applicantPersonalIDInfoVerified', value: applicants.informationVerified }));
                    dispatch(setApplicantInfo({ attribute: 'applicantPersonalIDIssuingAuthority', value: applicants.issueAuthority }));
                    dispatch(setApplicantInfo({ attribute: 'applicantPersonalIDType', value: applicants.documentType }));
                    dispatch(setApplicantInfo({ attribute: 'applicantPersonalIDAccountReference', value: applicants.accountReferenceNumber }));
                    dispatch(setApplicantInfo({ attribute: 'applicantPersonalIDExpiryDate', value: applicants.dateOfInformationExpiryDate }));
         
                    fileURL.push(applicants.verificationDocumentLink);
    
                    dispatch(setApplicantInfo({ attribute: 'applicantPersonalID_1Urls', value: fileURL }));
                    fileURL = [];
    
                }else if(applicants.applicantType === "Co-Applicant"){
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantPersonalIDInfoVerified', value: applicants.informationVerified }));
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantPersonalIDIssuingAuthority', value: applicants.issueAuthority }));
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantPersonalIDType', value: applicants.documentType }));
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantPersonalIDAccountReference', value: applicants.accountReferenceNumber }));
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantPersonalIDExpiryDate', value: applicants.dateOfInformationExpiryDate }));
         
                    fileURL.push(applicants.verificationDocumentLink);
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantPersonalID_1Urls', value: fileURL }));
                    fileURL = [];
                }
            });
        }
      
    }


    const title = "Identification Information";
    const pageName = 'identification'

    const questionList = [
        'Tax Residence of Canada',
        'Tax Residence of United States',
        'Do you have Taxpayer identification number (TIN) from the U.S. ?',
        'TaxPayer Identification Number (TIN) (US)',
        'Explain why you don\'t have TIN (US)',
        'Tax residence of a jurisdiction other than Canada or the United States?',
        'If yes, what is the country name of the Taxpayer Identification Number (TIN) that you have?',
        'Do you have Taxpayer identification number (TIN) outside the U.S. or Canada?',
        'Taxpayer identification number (TIN) (Other)',
        'Explain why you don\'t have TIN (Other)'
    ]


    const fetchTaxStatusInfo = async () => {
        const res = await fetchTaxStatus(application.applicationID, "Applicant");
        res?.forEach((info) => {
            switch (info.question) {
                case questionList[0]: dispatch(setApplicantInfo({ attribute: 'residenceOfCanada', value: info.answer || false }));
                    break;
                case questionList[1]: dispatch(setApplicantInfo({ attribute: 'residenceOfUnitedStates', value: info.answer || false }));
                    break;
                case questionList[2]: dispatch(setApplicantInfo({ attribute: 'haveTinUS', value: info.answer || false}));
                    break;
                case questionList[3]: dispatch(setApplicantInfo({ attribute: 'tinUS', value: info.answer }));
                    break;
                case questionList[4]: dispatch(setApplicantInfo({ attribute: 'reasonOfNoTinUS', value: info.answer}));
                    break;
                case questionList[5]: dispatch(setApplicantInfo({ attribute: 'otherResidence', value: info.answer || false}));
                    break;
                case questionList[6]: dispatch(setApplicantInfo({ attribute: 'countryName', value: info.answer }));
                    break;
                case questionList[7]: dispatch(setApplicantInfo({ attribute: 'haveTinOther', value: info.answer || false}));
                    break;
                case questionList[8]: dispatch(setApplicantInfo({ attribute: 'tinOther', value: info.answer }));
                    break; 
                case questionList[9]: dispatch(setApplicantInfo({ attribute: 'reasonOfNoTinOther', value: info.answer}));
                    break;
                default: break;
            }

        })

    }


    const fetchCoAppTaxStatusInfo = async () => {
        const res = await fetchTaxStatus(application.applicationID, "Co-Applicant");
        res?.forEach((info) => {
            switch (info.question) {
                case questionList[0]: dispatch(setCoapplicantInfo({ attribute: 'residenceOfCanada', value: info.answer || false }));
                    break;
                case questionList[1]: dispatch(setCoapplicantInfo({ attribute: 'residenceOfUnitedStates', value: info.answer || false }));
                    break;
                case questionList[2]: dispatch(setCoapplicantInfo({ attribute: 'haveTinUS', value: info.answer || false}));
                    break;
                case questionList[3]: dispatch(setCoapplicantInfo({ attribute: 'tinUS', value: info.answer }));
                    break;
                case questionList[4]: dispatch(setCoapplicantInfo({ attribute: 'reasonOfNoTinUS', value: info.answer}));
                    break;
                case questionList[5]: dispatch(setCoapplicantInfo({ attribute: 'otherResidence', value: info.answer || false}));
                    break;
                case questionList[6]: dispatch(setCoapplicantInfo({ attribute: 'countryName', value: info.answer }));
                    break;
                case questionList[7]: dispatch(setCoapplicantInfo({ attribute: 'haveTinOther', value: info.answer || false}));
                    break;
                case questionList[8]: dispatch(setCoapplicantInfo({ attribute: 'tinOther', value: info.answer }));
                    break; 
                case questionList[9]: dispatch(setCoapplicantInfo({ attribute: 'reasonOfNoTinOther', value: info.answer}));
                    break;
                default: break;
            }

        })

    }

    useEffect(() => {
        fetchApplcantIds();
        fetchTaxStatusInfo();
        fetchCoAppTaxStatusInfo();
    }, []);



    const isDataValid = () => {
        if (!isFileValid(rawFiles.applicantID1Urls) || !isFileValid(rawFiles.applicantID2Urls)) {
            setIsErrorDialogOpen(true);
            setDialogError('Please upload all required ID files for the applicant.');
            return false;
        } else if((!applicant.verified || !applicant.verified_2) && !isFileValid(rawFiles.applicantPersonalID_1Urls)) {
            setIsErrorDialogOpen(true);
            setDialogError('Please upload all required ID verification files for the applicant.');
            return false;
        } 

        if (applicant.anyCoapplicant) {
            if (!isFileValid(rawFiles.coApplicantID1Urls) || !isFileValid(rawFiles.coApplicantID2Urls)) {
                setIsErrorDialogOpen(true);
                setDialogError('Please upload all required ID files for the co-applicant.');
                return false;
            } else if((!coapplicant.verified || !coapplicant.verified_2) && !isFileValid(rawFiles.coApplicantPersonalID_1Urls)) {
                setIsErrorDialogOpen(true);
                setDialogError('Please upload all required ID verification files for the applicant.');
                return false;
            } 
        }
        return true;
    }

    // Handles the click event for the next button before going into the hell of next button component 
    const handleClick = async () => {

        if (!isDataValid()) {
            return false;
        }
 
        setIsLoading(true);
        
        await handleUploadToBlob(application.applicationID, 'Applicant', 'applicantID1Urls');
        await handleUploadToBlob(application.applicationID, 'Applicant', 'applicantID2Urls');
        await handleUploadToBlob(application.applicationID, 'Applicant', 'applicantPersonalID_1Urls');
        if(applicant.anyCoapplicant) {
            await handleUploadToBlob(application.applicationID, 'Co-Applicant', 'coApplicantID1Urls');
            await handleUploadToBlob(application.applicationID, 'Co-Applicant', 'coApplicantID2Urls');
            await handleUploadToBlob(application.applicationID, 'Co-Applicant', 'coApplicantPersonalID_1Urls');
        }

        setIsLoading(false);
    }

    const handleUploadToBlob = async (applicationID, applicantType, attribute) => {
        if (!rawFiles[attribute] || rawFiles[attribute].isFetched) {
            return;
        }
        const file = rawFiles[attribute];
        const res = await uploadToBlob(applicationID, applicantType, attribute, file);
        if (res.success) {
            if(applicantType === 'Applicant') {
                dispatch(setApplicantInfo({ attribute, value: res.url }));
            } else if (applicantType === 'Co-Applicant') {
                dispatch(setCoapplicantInfo({ attribute, value: res.url }));
            }
            setDialogError('');
        } else {
            setIsErrorDialogOpen(true);
            setDialogError(res);
        }

    }


    return (
        <>
        <Box className="general-box-overall">

            <Box className='mt-5'>
                <TitleText text={title} />
            </Box>

            <Box>
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className='mt-5 ml-2'>
                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} >
                    <Tab style={{ fontSize: 16 }} label="Main Applicant" value={0} />
                    {applicant.anyCoapplicant && (
                        <Tab style={{ fontSize: 16 }} label="Co-Applicant" value={1} />
                    )}
                </Tabs>
            </Box>

            <Box>
                <IdentificationInformation IDType={IDType} Countries={countries} Authorities={authorities} enable={tabValue === 0} />
                <IdentificationCoAppInformation IDType={IDType} Countries={countries} Authorities={authorities} enable={tabValue === 1} />
            </Box>

            <Box>
                <IdVerification IDType={nameAddressOptions} enable={tabValue === 0} />
                <CoAppIdVerification IDType={nameAddressOptions} enable={tabValue === 1} />
            </Box>

            {needTaxStatus && (
                <Box>
                    <IdentificationTaxStatus reasons={reasons} enable={tabValue === 0} applicantType="Applicant"/>
                    <IdentificationTaxStatus reasons={reasons} enable={tabValue === 1} applicantType="Co-Applicant"/>
                </Box>
            )}

            <Box className="general-box-button">
                <PrevButton />
                <NextButton pageName={pageName} handleClick = {handleClick}/>
            </Box>

        </Box>
        <PopupDialog
                open={isLoading}
                message={"Uploading files, please wait..."}
            />
        <PopupDialog
                open={isErrorDialogOpen}
                onClose={() => setIsErrorDialogOpen(false)}
                message={dialogError}
                title="File Upload Error" 
            />
        </>
    );
};