import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useEffect } from 'react';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

    
const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(5),
  }
));

const InputFileUpload = ({setUploadedFile = () => {}, uploadedFile = null}) => {
    const [file, setFile] = React.useState(uploadedFile);

    useEffect(() => {
        setFile(uploadedFile);
    }, [uploadedFile]);

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
        setUploadedFile(event.target.files[0]);
    };
    
  return (
    <>
    <StyledButton
      component="label"
      variant="contained"
      tabIndex={-1}
    >
      {file?.name? "Reupload" : "Upload"}
      <VisuallyHiddenInput
        type="file"
        onChange={handleFileUpload}
      />
      
    </StyledButton>
    <span>{file?.name}</span>
    </>
  );
}

export default InputFileUpload;