import React, { useState, useEffect } from "react";
import { Alert, Box, IconButton, Snackbar, TextField } from "@mui/material";
import TextFieldText from "../../../components/smallComponents/text/textFieldText/TextFieldText";
import { useDispatch, useSelector } from "react-redux";
import DropdownList from "../../../components/smallComponents/dropdownList/DropdownList";
import { setAdditionalInfo } from "../../../reducers/additionalInfoReducer";
import TextFieldComponent from "../../../components/smallComponents/textField/TextField";
import DateField from "../../../components/smallComponents/dateField/DateField";
import RadioGroup from "../../../components/smallComponents/radioGroup/RadioGroup";
import Gender from "../../../components/enumTypes/Gender"
import PhoneField from "../../../components/smallComponents/phoneField/PhoneField";
import { AddBox } from "@mui/icons-material";
import Table from "../../../components/mediumComponents/table/Table";
import ModifyTable from "../../../components/mediumComponents/table/ModifyTable";
//import { ClientAcquisitionChannel } from "../../../constants/lists";
import { useApplication } from "../../../contexts/ApplicationContext";
import { getDropdownList } from "../../../services/dropdownService";
import { fetchCustomer, fetchExistingAcquisitionChannel } from '../../../services/fetchInfoService';
import { saveInfo, infoToSave } from "../../../services/saveInfoService";
import { axiosInstance } from "../../../axios";
/**
 * @description - - This component is used for inputing of client's acquisition channel and adding referrer info
 * @param {string} color - Background color 
 * @returns - AcquisitionChannel subsection component as part of AddtionalInfoPage
 */

const AcquisitionChannel = ({ color, applicationId }) => {

    const dispatch = useDispatch();
    const additionalDetails = useSelector((state) => state.additionalInfo);
    const { clickNextButton } = useApplication();
    const [clientAcquisitionChannel, setClientAcquisitionChannel] = useState([]);
    const [intialClientAcquisitionChannel, setIntialClientAcquisitionChannel] = useState(null);
    const [updateAcquisitionChannelIndex, setUpdateAcquisitionChannelIndex] = useState(0);
    const [rowIndex, setRowIndex] = useState(-1);
    // Array of strings that represent the names of the column fields in table component
    const names = ['First Name', 'Last Name', 'Preferred Name', 'Date Of Birth', 'Gender', 'Home Phone'];
    const values = [
        additionalDetails.referrerFirstName,
        additionalDetails.referrerLastName,
        additionalDetails.referrerPreferredName,
        additionalDetails.referrerDateOfBirth,
        additionalDetails.referrerGender,
        additionalDetails.referrerHomePhone
    ];
    const [isUpdate, setIsUpdate] = useState(false);
    const pageName = 'additional';
    const genders = [Gender.MALE, Gender.FEMALE];
    const [referrer, setReferrer] = useState({
        customerID:'',
        refFirstName: '',
        refLastName: '',
        refPreferredName: '',
        refDateOfBirth: '',
        refGender: '',
        refHomePhone: ''
    });

    // State to trigger the clearing of the input fields in child components
    const [isClear, setIsClear] = useState(false);

    // Control the display of snackbar messages
    const [openError, setOpenError] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);

    const handleApplicantChange = (attribute, value) => {
        dispatch(setAdditionalInfo({ attribute, value }))
        setIntialClientAcquisitionChannel(value);
    };

    const handleApplicantChangeDetail = (attribute, value) => {
        dispatch(setAdditionalInfo({ attribute, value }))
    };

    const handleAddReferrer = async () => {
        dispatch(setAdditionalInfo({ attribute: 'referrerCustomerID', value: [...additionalDetails.referrerCustomerID, referrer.customerID] }));
        dispatch(setAdditionalInfo({ attribute: 'referrerFirstName', value: [...additionalDetails.referrerFirstName, referrer.refFirstName] }));
        dispatch(setAdditionalInfo({ attribute: 'referrerLastName', value: [...additionalDetails.referrerLastName, referrer.refLastName] }));
        dispatch(setAdditionalInfo({ attribute: 'referrerPreferredName', value: [...additionalDetails.referrerPreferredName, referrer.refPreferredName] }));
        dispatch(setAdditionalInfo({ attribute: 'referrerDateOfBirth', value: [...additionalDetails.referrerDateOfBirth, referrer.refDateOfBirth] }));
        dispatch(setAdditionalInfo({ attribute: 'referrerGender', value: [...additionalDetails.referrerGender, referrer.refGender] }));
        dispatch(setAdditionalInfo({ attribute: 'referrerHomePhone', value: [...additionalDetails.referrerHomePhone, referrer.refHomePhone] }));

        // Trigger the clearing of the input fields in child components after adding a new liability
        // Reset the state to false after 0.1 seconds to resume input action in the child components
        
        const info = infoToSave('acquisitionChannel', referrer, 'Applicant', applicationId, null,additionalDetails);
        // /push into Por_App_LoanInvestmentAndCasedetail
        const reponse = await saveInfo('acquisitionChannel', info);
        if(reponse !== null){
            dispatch(setAdditionalInfo({ attribute: 'AcquisitionChannelID', value: reponse.acquisitionChannelID }));
        }
        setIsClear(true);
        setTimeout(() => setIsClear(false), 0.1);

        // Reset the local state to empty strings after adding a new liability
        setReferrer({
            customerID:'',
            refFirstName: '',
            refLastName: '',
            refPreferredName: '',
            refDateOfBirth: '',
            refGender: '',
            refHomePhone: ''});
    }

    const handleEditingReferrer = async () => {
        dispatch(setAdditionalInfo({ attribute: 'AcquisitionChannelID', value: [updateAcquisitionChannelIndex] }));
        dispatch(setAdditionalInfo({ attribute: 'referrerCustomerID', value: [...additionalDetails.referrerCustomerID, referrer.customerID] }));
        dispatch(setAdditionalInfo({ attribute: 'referrerFirstName', value: [...additionalDetails.referrerFirstName, referrer.refFirstName] }));
        dispatch(setAdditionalInfo({ attribute: 'referrerLastName', value: [...additionalDetails.referrerLastName, referrer.refLastName] }));
        dispatch(setAdditionalInfo({ attribute: 'referrerPreferredName', value: [...additionalDetails.referrerPreferredName, referrer.refPreferredName] }));
        dispatch(setAdditionalInfo({ attribute: 'referrerDateOfBirth', value: [...additionalDetails.referrerDateOfBirth, referrer.refDateOfBirth] }));
        dispatch(setAdditionalInfo({ attribute: 'referrerGender', value: [...additionalDetails.referrerGender, referrer.refGender] }));
        dispatch(setAdditionalInfo({ attribute: 'referrerHomePhone', value: [...additionalDetails.referrerHomePhone, referrer.refHomePhone] }));

        // Trigger the clearing of the input fields in child components after adding a new liability
        // Reset the state to false after 0.1 seconds to resume input action in the child components
        
        const info = infoToSave('acquisitionChannel', referrer, 'Applicant', applicationId, null,additionalDetails);
        // /push into Por_App_LoanInvestmentAndCasedetail
        const reponse = await saveInfo('acquisitionChannel', info);
        if(reponse !== null){
            dispatch(setAdditionalInfo({ attribute: 'AcquisitionChannelID', value: reponse.acquisitionChannelID }));
        }
        setIsClear(true);
        setTimeout(() => setIsClear(false), 0.1);

        // Reset the local state to empty strings after adding a new liability
        setReferrer({
            customerID:'',
            refFirstName: '',
            refLastName: '',
            refPreferredName: '',
            refDateOfBirth: '',
            refGender: '',
            refHomePhone: ''});
    }

    const fetchChannelOpt = async ()=>{
        const channelsList = await getDropdownList("AcquisitionChannels");
        setClientAcquisitionChannel(channelsList.map(x=>x['channelType']));
    }
    const fetchExistingChannel = async ()=>{
        const existingChannel = await fetchExistingAcquisitionChannel(applicationId);
            if(existingChannel.length > 0){
                
                let newacquisitionChannelID = "";
                let newrereferrerCustomerID = [];
                let newreferrerFirstName = [];
                let newreferrerLastName = [];
                let newreferrerPreferredName = [];
                let newreferrerDateOfBirth = [];
                let newreferrerGender = [];
                let newreferrerHomePhone = [];
                for (const channel of existingChannel) {   
                 handleApplicantChangeDetail("acquisitionChannelDetail", channel.customerAcquisitionChannelDetail);
                 handleApplicantChangeDetail("acquisitionChannel", channel.customerAcquisitionChannel);
                 newreferrerFirstName.push(channel.referrerFirstName);
                 newreferrerLastName.push(channel.referrerLastName);
                 newreferrerPreferredName.push(channel.referrerPreferredName);
                 newreferrerDateOfBirth.push(channel.referrerDateofBirth);
                 newreferrerGender.push(channel.referrerGender);
                 newreferrerHomePhone.push(channel.referrerHomePhone);
                 newrereferrerCustomerID.push(channel.referrerCustomerID);
                 newacquisitionChannelID = channel.acquisitionChannelID
             } 
             if(newreferrerFirstName.length >0){
                 handleApplicantChangeDetail("AcquisitionChannelID", newacquisitionChannelID);
                 dispatch(setAdditionalInfo({ attribute: 'referrerFirstName', value: newreferrerFirstName }));
                dispatch(setAdditionalInfo({ attribute: 'referrerLastName', value: newreferrerLastName }));
                dispatch(setAdditionalInfo({ attribute: 'referrerPreferredName', value: newreferrerPreferredName }));
                dispatch(setAdditionalInfo({ attribute: 'referrerDateOfBirth', value: newreferrerDateOfBirth }));
                dispatch(setAdditionalInfo({ attribute: 'referrerGender', value: newreferrerGender }));
                dispatch(setAdditionalInfo({ attribute: 'referrerHomePhone', value: newreferrerHomePhone }));
                dispatch(setAdditionalInfo({ attribute: 'referrerCustomerID', value: newrereferrerCustomerID }));
                newreferrerFirstName = [];
                newreferrerLastName = [];
                newreferrerPreferredName = [];
                newreferrerDateOfBirth = [];
                newreferrerGender = [];
                newreferrerHomePhone = [];
                newrereferrerCustomerID = [];
             }
          
            }   
          
       
    }

    const handleAddReferrerCheck = () => {
        if (referrer.refFirstName === '' 
            || referrer.refLastName === ''
            || referrer.refDateOfBirth === ''
            || referrer.refGender === ''
            || referrer.refHomePhone === ''
            
            ) {
            setOpenError(true);
        }
        else {
            handleAddReferrer();
            setOpenSuccess(true);
        }
    }

    const handleEditReferrerCheck = () => {
        if (referrer.refFirstName === '' 
            || referrer.refLastName === ''
            || referrer.refDateOfBirth === ''
            || referrer.refGender === ''
            || referrer.refHomePhone === ''
            
            ) {
            setOpenError(true);
        }
        else {
            handleEditingReferrer();
            setOpenSuccess(true);
        }
    }


    const handleFetchCUstomerInfo = async (attribute, value) => {

        //dispatch(setAdditionalInfo({ attribute, value }));
        const custromerInfo = await fetchCustomer(value);
        if(custromerInfo !== null && custromerInfo !== undefined){
            setReferrer({
                customerID: value,
                refFirstName: custromerInfo.firstName,
                refLastName: custromerInfo.lastName,
                refPreferredName: custromerInfo.preferredName,
                refDateOfBirth: custromerInfo.dateOfBirth,
                refGender: custromerInfo.gender
            });

        }
    };

    const handleEditReferrer = (index) =>{
        setReferrer({
            customerID: additionalDetails.referrerCustomerID[index],
            refFirstName: additionalDetails.referrerFirstName[index],
            refLastName:additionalDetails.referrerLastName[index],
            refPreferredName: additionalDetails.referrerPreferredName[index],
            refDateOfBirth: additionalDetails.referrerDateOfBirth[index],
            refGender: additionalDetails.referrerGender[index],
            refHomePhone: additionalDetails.referrerHomePhone[index],
        });

      
        setIsUpdate(prevState => !prevState);
        handleDeleteReferrerNOBD(index);
        setUpdateAcquisitionChannelIndex(additionalDetails.AcquisitionChannelID);
    }


    const deleteReferral = async (applicationID, itemID) => {
        try {
            const response = await axiosInstance.delete(`acquisitionChannel/delete/refer/${applicationID}/${itemID}`);
            return response.data;
        }
        catch (error) {
            console.error('Error: ', error);
        }
    }

    const handleDeleteReferrer = async (index) => {
        const deletedReferrer = await deleteReferral(additionalDetails.applicationID, additionalDetails.AcquisitionChannelID);
        dispatch(setAdditionalInfo({ attribute: 'referrerFirstName', value: additionalDetails.referrerFirstName.filter((_, i) => i !== index) }));
        dispatch(setAdditionalInfo({ attribute: 'referrerLastName', value: additionalDetails.referrerLastName.filter((_, i) => i !== index) }));
        dispatch(setAdditionalInfo({ attribute: 'referrerPreferredName', value: additionalDetails.referrerPreferredName.filter((_, i) => i !== index) }));
        dispatch(setAdditionalInfo({ attribute: 'referrerDateOfBirth', value: additionalDetails.referrerDateOfBirth.filter((_, i) => i !== index) }));
        dispatch(setAdditionalInfo({ attribute: 'referrerGender', value: additionalDetails.referrerGender.filter((_, i) => i !== index) }));
        dispatch(setAdditionalInfo({ attribute: 'referrerHomePhone', value: additionalDetails.referrerHomePhone.filter((_, i) => i !== index) }));
        dispatch(setAdditionalInfo({ attribute: 'referrerCustomerID', value: additionalDetails.referrerCustomerID.filter((_, i) => i !== index) }));
    };

    const handleDeleteReferrerNOBD = async (index) => {
    
        dispatch(setAdditionalInfo({ attribute: 'referrerFirstName', value: additionalDetails.referrerFirstName.filter((_, i) => i !== index) }));
        dispatch(setAdditionalInfo({ attribute: 'referrerLastName', value: additionalDetails.referrerLastName.filter((_, i) => i !== index) }));
        dispatch(setAdditionalInfo({ attribute: 'referrerPreferredName', value: additionalDetails.referrerPreferredName.filter((_, i) => i !== index) }));
        dispatch(setAdditionalInfo({ attribute: 'referrerDateOfBirth', value: additionalDetails.referrerDateOfBirth.filter((_, i) => i !== index) }));
        dispatch(setAdditionalInfo({ attribute: 'referrerGender', value: additionalDetails.referrerGender.filter((_, i) => i !== index) }));
        dispatch(setAdditionalInfo({ attribute: 'referrerHomePhone', value: additionalDetails.referrerHomePhone.filter((_, i) => i !== index) }));
        dispatch(setAdditionalInfo({ attribute: 'referrerCustomerID', value: additionalDetails.referrerCustomerID.filter((_, i) => i !== index) }));
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    };

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
    };

    useEffect(() => {
        fetchChannelOpt()
    }, []);

    useEffect(() => {
        if(referrer.customerID !== '' ){
            handleFetchCUstomerInfo("referrerCustomerID", referrer.customerID)
        }
        
    }, [referrer.customerID]);

    useEffect(() => {
        fetchExistingChannel()
    }, []);

    useEffect(() => {
       
    }, [additionalDetails.acquisitionChannelDetail]);

    return (
        <div >
            <Box className='mt-10 rounded-xl shadow-lg text-black-950' bgcolor={color}>
            <Box className='flex justify-center items-center gap-8'>
                <TextFieldText text="Client Acquisition Channel" />
                
                {/* Flex container to align DropdownList and TextFieldComponent horizontally */}
                <Box className='flex gap-4 items-center mt-10'>
                    
                    <DropdownList
                        options={clientAcquisitionChannel}
                        defaultValue={additionalDetails.acquisitionChannel}
                        setStateValue={value => handleApplicantChange("acquisitionChannel", value)}
                        name='Client Acquisition Channel'
                        type='mandatory'
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                    />
                </Box>
                <TextFieldText text="Acquisition Channel Detail" />
                <Box className='flex gap-4 items-center mt-7'>
                    <TextFieldComponent
                        name={"Acquisition Channel Detail"}
                        setStateValue={value => handleApplicantChangeDetail("acquisitionChannelDetail", value)}
                        type='optional'
                        isClear={isClear}
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                        defaultValue={additionalDetails.acquisitionChannelDetail}
                    />
                </Box>
              </Box>

                {/* SearchBar - search and select existing client referrals*/}
                {/* {additionalDetails.acquisitionChannel === "Client Referral" ?
                    (<Box className="flex justify-center items-center gap-10 pb-8">
                        <TextFieldText text="Search for existing client" />
                        <Box className="bg-white">
                            <TextField label="Search" />
                        </Box>
                    </Box>)
                    : (<Box></Box>)} */}
            </Box>
            


            {/* Add Referrer Section For Non-Client referral */}
            {additionalDetails.acquisitionChannel === "Client Referral" || additionalDetails.acquisitionChannel === "Non-Client Referral" ?
                (<Box>
                    {/* Table to display Referrer's Info */}
                    <Box className="mt-10">
                        <ModifyTable
                        fieldNames={names}
                        fieldValues={values}
                        handleDelete={colIndex => handleDeleteReferrer(colIndex)}
                        handleEdit={colIndex => handleEditReferrer(colIndex)}
                        setRowIndex={setRowIndex}
                        disableEdit={false} />
                    </Box>

                    {/* --- Referrer's Info Section --- */}
                    <Box className='mt-10 rounded-xl shadow-lg text-black-950 py-5' bgcolor={color}>
                        <div className="my-5 pb-3 text-3xl font-medium flex justify-center" style={{ color: 'rgba(5, 74, 181, 1.0)' }}>Add Referrer </div>
                        <Box className='flex justify-center items-center gap-5 mb-8'>
                            <Box>
                                <TextFieldText text="Customer ID" />
                                <TextFieldComponent
                                    defaultValue={referrer.customerID}
                                    setStateValue={value => setReferrer({ ...referrer, customerID: value })}
                                    type='optional'
                                    isClear={isClear}
                                />
                            </Box>
                            <Box>
                                <TextFieldText text="First Name" />
                                <TextFieldComponent
                                    defaultValue={referrer.refFirstName}
                                    setStateValue={value => setReferrer({ ...referrer, refFirstName: value })}
                                    type='name'
                                    isClear={isClear}
                                />
                            </Box>

                            <Box>
                                <TextFieldText text="Last Name" />
                                <TextFieldComponent
                                    defaultValue={referrer.refLastName}
                                    setStateValue={value => setReferrer({ ...referrer, refLastName: value })}
                                    type='name'
                                    isClear={isClear}
                                />
                            </Box>

                            <Box className='mb-5'>
                                <TextFieldText text="Preferred Name" />
                                <TextFieldComponent
                                    defaultValue={referrer.refPreferredName}
                                    setStateValue={value => setReferrer({ ...referrer, refPreferredName: value })}
                                    type='optional'
                                    isClear={isClear}
                                />
                            </Box>
                        </Box>


                        <Box className='flex justify-center items-center gap-8'>
                            <Box>
                                <TextFieldText text="Date Of Birth" />
                                <DateField
                                    defaultValue={referrer.refDateOfBirth}
                                    setStateValue={value => setReferrer({ ...referrer, refDateOfBirth: value })}
                                    type='date'
                                    minYear={18}
                                    isClear={isClear}
                                />
                            </Box>

                            <Box>
                                <TextFieldText text="Gender" />
                                <RadioGroup
                                    options={genders}
                                    isClear={isClear}
                                    defaultValue={referrer.refGender}
                                    setStateValue={value => setReferrer({ ...referrer, refGender: value })}
                                    type='mandatory'
                                />
                            </Box>

                            <Box>
                                <TextFieldText text="Home Phone" />
                                <PhoneField
                                    defaultValue={referrer.refHomePhone}
                                    setStateValue={value => setReferrer({ ...referrer, refHomePhone: value })}
                                    type='phone'
                                />
                            </Box>
                        </Box>

                        {/* Multi - add button*/}
                        <Box className='flex items-center justify-center my-8'>
                            
                        {!isUpdate && ( 
                            <IconButton onClick={handleAddReferrerCheck} >
                            <AddBox sx={{ fontSize: 45 }} />
                            <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Add Referrer</span>
                            </IconButton>
                        )}
                         {isUpdate && ( 
                             <IconButton onClick={handleEditReferrerCheck} >
                             <AddBox sx={{ fontSize: 45 }} />
                             <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Update Referrer</span>
                            </IconButton>
                        )}
                            
                         
                        </Box>

                        {/* Error Popup messages*/}
                        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openError} autoHideDuration={4000} onClose={handleErrorClose}>
                            <Alert
                                onClose={handleErrorClose}
                                severity="warning"
                                variant="filled"
                                sx={{ width: '100%', fontSize: 20 }}
                            >
                             {(additionalDetails.referrerCustomerID.includes(referrer.customerID)) 
                            ? 'This Referrer existed already, please add a different one'
                            : 'Please fill all required fields before adding'
                            }
                            </Alert>
                        </Snackbar>

                        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openSuccess} autoHideDuration={4000} onClose={handleSuccessClose}>
                            <Alert
                                onClose={handleSuccessClose}
                                severity="success"
                                variant="filled"
                                sx={{ width: '100%', fontSize: 20 }}
                            >
                                Added Referrer Successfully
                            </Alert>
                        </Snackbar>
                    </Box>
                </Box>)
            :(<Box></Box>)}
            


        </div>
    )
}

export default AcquisitionChannel;
