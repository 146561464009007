import { Box, Tabs, Tab } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useApplication } from "../../../../contexts/ApplicationContext";
import MainAppContactSection from "./section/MainAppContactSection";
import CoAppContactSection from "./section/CoAppContactSection";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import "./style/ContactInformation.css";
import dayjs from "dayjs";
import { fetchContact, fetchAddress} from '../../../../services/fetchInfoService';
import { resetCoapplicantInfo, setCoapplicantInfo } from "../../../../reducers/coapplicantReducer";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import { isValidPostalCode } from "../../../../services/dataValidationService";
/**
 * @returns - Contact Information component for the application page
 */


export default function ContactInformation() {
    const [tabValue, setTabValue] = useState(0);
    const [allowAppNext, setAllowAppNext] = useState(false);
    const [allowCoappNext, setAllowCoappNext] = useState(false);
    const dispatch = useDispatch();
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const {handleErrorMessage} = useApplication();
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const application = useSelector((state) => state.applicationInfo);
    const title = "Contact Information";
    const pageName = 'contact';

    const fetchContactInfo = async ()=>{
    
        const allContacts = await fetchContact(application.applicationID);
        allContacts?.forEach((contact) => {
            if (contact.applicantType === "Applicant") {
                dispatch(setApplicantInfo({ attribute: 'email', value: contact.email }));
                dispatch(setApplicantInfo({ attribute: 'homePhone', value: contact.homePhone }));
                dispatch(setApplicantInfo({ attribute: 'workPhone', value: contact.workPhone }));
                dispatch(setApplicantInfo({ attribute: 'cellPhone', value: contact.cellPhone }));
            } else if (contact.applicantType === "Co-Applicant") {
                dispatch(setCoapplicantInfo({ attribute: 'email', value: contact.email }));
                dispatch(setCoapplicantInfo({ attribute: 'homePhone', value: contact.homePhone }));
                dispatch(setCoapplicantInfo({ attribute: 'workPhone', value: contact.workPhone }));
                dispatch(setCoapplicantInfo({ attribute: 'cellPhone', value: contact.cellPhone }));
            }
        });
    }
    
    const fetchAddressInfo = async () => {

        const allAddresses = await fetchAddress(application.applicationID);

        allAddresses?.forEach((address) => {
            if (address.applicantType === "Applicant"){
                var currAddress = `${address.streetNumberC} ${address.streetNameC}, ${address.cityC} ${address.provinceC} ${address.postCodeC}` === ' ,   '? '': `${address.streetNumberC} ${address.streetNameC}, ${address.citC} ${address.provinceC} ${address.postCodeC}`;
                dispatch(setApplicantInfo({ attribute: 'streetNumber', value: address.streetNumberC }));
                dispatch(setApplicantInfo({ attribute: 'streetName', value: address.streetNameC }));
                dispatch(setApplicantInfo({ attribute: 'unit', value: address.unitC }));
                dispatch(setApplicantInfo({ attribute: 'city', value: address.cityC }));
                dispatch(setApplicantInfo({ attribute: 'province', value: address.provinceC }));
                dispatch(setApplicantInfo({ attribute: 'postalCode', value: address.postCodeC }));
                dispatch(setApplicantInfo({ attribute: 'livingSince', value: address.livingSinceC }));
                dispatch(setApplicantInfo({ attribute: 'prevStreetNumber', value: address.streetNumberP }));
                dispatch(setApplicantInfo({ attribute: 'prevStreetName', value: address.streetNameP }));
                dispatch(setApplicantInfo({ attribute: 'prevUnit', value: address.unitP }));
                dispatch(setApplicantInfo({ attribute: 'prevCity', value: address.cityP }));
                dispatch(setApplicantInfo({ attribute: 'prevProvince', value: address.provinceP }));
                dispatch(setApplicantInfo({ attribute: 'prevPostalCode', value: address.postCodeP }));
                dispatch(setApplicantInfo({ attribute: 'prevLivingSince', value: address.livingSinceP }));

            } else if (address.applicantType === "Co-Applicant") {
                const coCurrAddr = `${address.streetNumberC} ${address.streetNameC}, ${address.cityC} ${address.provinceC} ${address.postCodeC}` === ' ,   '? '': `${address.streetNumberC} ${address.streetNameC}, ${address.cityC} ${address.provinceC} ${address.postCodeC}`;
                dispatch(setCoapplicantInfo({ attribute: 'streetNumber', value: address.streetNumberC }));
                dispatch(setCoapplicantInfo({ attribute: 'streetName', value: address.streetNameC }));
                dispatch(setCoapplicantInfo({ attribute: 'unit', value: address.unitC }));
                dispatch(setCoapplicantInfo({ attribute: 'city', value: address.cityC }));
                dispatch(setCoapplicantInfo({ attribute: 'province', value: address.provinceC }));
                dispatch(setCoapplicantInfo({ attribute: 'postalCode', value: address.postCodeC }));
                dispatch(setCoapplicantInfo({ attribute: 'livingSince', value: address.livingSinceC }));
                dispatch(setCoapplicantInfo({ attribute: 'prevStreetNumber', value: address.streetNumberP }));
                dispatch(setCoapplicantInfo({ attribute: 'prevStreetName', value: address.streetNameP }));
                dispatch(setCoapplicantInfo({ attribute: 'prevUnit', value: address.unitP }));
                dispatch(setCoapplicantInfo({ attribute: 'prevCity', value: address.cityP }));
                dispatch(setCoapplicantInfo({ attribute: 'prevProvince', value: address.provinceP }));
                dispatch(setCoapplicantInfo({ attribute: 'prevPostalCode', value: address.postCodeP }));
                dispatch(setCoapplicantInfo({ attribute: 'prevLivingSince', value: address.livingSinceP }));
                if(currAddress !== coCurrAddr){
                    dispatch(setCoapplicantInfo({ attribute: 'sameAddressAsPrimaryApplicant', value: false }));
                } 
            }
                
            });
        }

    useEffect(() => {
        fetchContactInfo();
        fetchAddressInfo();
    }, []);

    const isDataValid = () => {
        const res = allowAppNext && (applicant.anyCoapplicant? allowCoappNext: true);
        if(!res) {
            handleErrorMessage(pageName, 'add', 'Please fill out all the required fields');
        } else {
            handleErrorMessage(pageName, 'remove', 'Please fill out all the required fields');
        }
        return res;
    }


    return (
        <Box className="contact-box-overall">

            <Box>
                <TitleText text={title} />
            </Box>

            <Box>
                <ErrorBox pageName={pageName} enable={true} />
            </Box>

            <Box className='ml-2'>
                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} >
                    <Tab style={{ fontSize: 16 }} label="Main Applicant" value={0} />
                    {applicant.anyCoapplicant && (
                        <Tab style={{ fontSize: 16 }} label="Co-Applicant" value={1} />
                    )}
                </Tabs>
            </Box>

            <MainAppContactSection enable={tabValue === 0} setAllowNext={setAllowAppNext}/>
            <CoAppContactSection enable={tabValue === 1} setAllowNext={setAllowCoappNext}/>

            <Box className="contact-box-button">
                <PrevButton />
                <NextButton pageName={pageName} handleClick={isDataValid}/>
            </Box>

        </Box>
    );
}