import { useEffect } from "react";

export const verifiedIDOptions = [
    'Name and address',
    'Name and date of birth',
    'Name and financial account'
];

export const nameAddressOptions = [
    "Canada Pension Plan (CPP) statement",
    "Property tax assessment issued by a municipality",
    "Provincially issued vehicle registration",
    "Employment Insurance (EI) statement",
    "Old Age Security (OAS) statement",
    "Utility bill (e.g. electricity, water, telecommunications)",
    "Registered investment account statements (example, RRSP, TFSA, GIC)",
    "Insurance documents (home, auto)",
    "Other"
];

export const nameDateOfBirthOptions = [
    "Canadian Permanent Residence Card",
    "Passport",
    "Canadian Provincial Identification Card",
    "Birth Certificate",
    "Canadian Citizenship Card",
    "Health Card - BC",
    "Health Card - AB",
    "Health Card - SK",
    "Health Card - QC",
    "Health Card - NB",
    "Health Card - NL",
    "Health Card - NT",
    "Health Card - NU",
    "Health Card - YT",
    "Marriage Certificate",
    "Divorce documentation",
    "Temporary Driver’s Licence",
    "Registered investment account statements (example, RRSP, TFSA, GIC)",
    "Insurance documents (home, auto)",
    "Travel visa",
    "Other"
];

export const nameFinancialAccountOptions = [
    "Loan account statement (example, mortgage)",
    "Letter from the financial entity holding the deposit account, credit card or loan account",
    "Other"
];

export const discloseQuestions = [
    "Was or is the applicant, or any family member, and/or close associate, and/or a person on whose behalf the applicant is making a payment, a Politically Exposed Person (PEP) and/or a Head of an International Organization (HIO), or have they held such a position within the past five (5) years?",
    "Is this applicant liable as a co-signer or guarantor?",
    "Are there any suits or judgements against this applicant?",
    "Has this applicant ever declared bankruptcy or filed a consumer proposal within past 6 years?",
    "Will this loan benefit a third party?",
    "Is Income tax owed, for the most recent year?",
    "Is the applicant required to make a monthly support or alimony payment?",
    "Have you ever been subject to a garnishment?",
    "Have you ever had property seized?",
    "Is the applicant a Manulife Financial VP (or higher), or a Manulife Bank staff employee?"
]

export const disclosureVariables = [
    "isPEP_HIO",
    "isCoSigner_Guarantor",
    "hasSuitsOrJudgements",
    "hasBankruptcyOrProposal",
    "isLoanBenefittingThirdParty",
    "isIncomeTaxOwed",
    "isMonthlySupportOrAlimony",
    "isSubjectToGarnishment",
    "hasPropertySeized",
    "isManulifeStaffMember"
];

export const languages = ['English', 'French', 'Chinese'];

export const ResidentialStatus = ['Own', 'Rent', 'Living with Parents', 'Living with Others'];

export const countries = [
    "United States of America (the)",
    "Canada",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "China",
    "Chile",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Côte d'Ivoire",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia (the former Yugoslav Republic of)",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Réunion",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan (Province of China)",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "Unknown or unspecified country",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe"
];

export const provinces = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Nova Scotia",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan"
];

export const maritalStatus = ['Married', 'Single', 'Divorce', 'Seperated', 'Widowed', 'Common Law', 'Other'];

export const currAddr = [
    'streetNumber',
    'streetName',
    'unit',
    'city',
    'province',
    'postalCode'
];

export const prevAddr = [
    'prevStreetNumber',
    'prevStreetName',
    'prevUnit',
    'prevCity',
    'prevProvince',
    'prevPostalCode'
];

export const repaymentTermOptions = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20"
];

export const assetType = [
    "TFSA",
    "RRSP",
    "Group RRSP",
    "Spousal RRSP",
    "RESP",
    "Mutual Funds",
    "Segregated Funds",
    "GIC",
    "Stocks",
    "Checking Account",
    "Saving Account",
    "Principal Residence",
    "Canadian Real Estate",
    "Other"
];

export const liabilityType = [
    "Mortgage - Principal Residence",
    "Mortgage - Other Real Estate",
    "Rent",
    "Line of Credit",
    "Vehicle Loan",
    "Personal Loan",
    "Student Loan",
    "Investment Loan",
    "Credit Card",
    "Other"
];

export const institutions = [
    "240 - ABN AMRO BANK CANADA",
    "501 - ACADIA TRUST COMPANY",
    "560 - AGF TRUST COMPANY",
    "830 - AIRLINE (MALTON) CREDIT UNION",
    "810 - ALL TRANS CREDIT UNION LIMITED",
    "843 - AMCU CREDIT UNION INC.",
    "303 - AMEX BANK OF CANADA",
    "272 - ANZ BANK CANADA",
    "834 - ASCU COMMUNITY CREDIT UNION LT",
    "809 - B.C. CENTRAL CREDIT UNION",
    "618 - B2B BANK",
    "249 - BANCA COMMERCIALE ITALIANA OF",
    "254 - BANCA NAZIONALE DEL LAVORO OF",
    "264 - BANCO CENTRAL HISPANO-CANADA",
    "244 - BANK HAPOALIM (CANADA)",
    "246 - BANK LEUMI LE-ISRAEL (CANADA)",
    "241 - BANK OF AMERICA CANADA",
    "257 - BANK OF BOSTON CANADA",
    "177 - BANK OF CANADA",
    "308 - BANK OF CHINA (CANADA)",
    "251 - BANK OF CREDIT AND COMMERCE CA",
    "001 - BANK OF MONTREAL",
    "245 - BANK OF TOKYO-MITSUBISHI (CA)",
    "337 - BANK WEST",
    "450 - BANQUE MANUVIE",
    "250 - BANQUE NATIONALE DE PARIS (CA)",
    "211 - BANQUE NATIONALE DU CANADA",
    "248 - BARCLAYS BANK OF CANADA",
    "220 - BC COMMUNITY FINANCIAL SERVICE",
    "849 - BRUNSWICK CREDIT UNION FED.LTD",
    "247 - BT BANK OF CANADA",
    "835 - CAISSE D'ECONOMIE SIDBEC",
    "841 - CAISSE D'ETABLISSEMENT RIVE-SU",
    "815 - CAISSE POP. DESJARDINS",
    "187 - CANADA SAVINGS BOND REDEMPTION",
    "356 - CANADA TRUST",
    "363 - CANADA TRUST",
    "010 - CANADIAN IMPERIAL BANK OF COMM",
    "538 - CANADIAN ITALIAN TRUST",
    "030 - CANADIAN WESTERN BANK",
    "323 - CAPITAL ONE BANK (CA)",
    "848 - CBC (TORONTO) CREDIT UNION LIM",
    "284 - CHASE MANHATTAN BANK OF CANADA",
    "253 - CHEMICAL BANK OF CANADA",
    "304 - CHO HUNG BANK OF CANADA",
    "548 - CIBC TRUST CORPORATION",
    "260 - CITIBANK CANADA",
    "328 - CITIBANK, NA",
    "309 - CITIZENS BANK OF CANADA",
    "554 - CITIZENS TRUST COMPANY",
    "842 - CIVIL SERVICE CO-OP CREDIT SOC",
    "879 - CO-OP CREDIT SOC. OF MANITOBA",
    "330 - COMERICA BANK",
    "259 - COMERICA BANK CANADA",
    "807 - COMMUNICATION TECH CRED UNION",
    "507 - COMMUNITY TRUST COMPANY LTD",
    "267 - CREDIT LYONNAIS CANADA",
    "258 - CREDIT SUISSE FIRST BOSTON CA",
    "899 - CREDIT UNION CENTRAL ALBERTA L",
    "839 - CREDIT UNION CENTRAL NOVA SCO.",
    "828 - CREDIT UNION CENTRAL OF ONT.",
    "889 - CREDIT UNION CENTRAL OF SASK.",
    "608 - CS ALTERNA BANK",
    "315 - CTC BANK OF CANADA",
    "277 - DAI-ICHI KANGYO BANK (CANADA",
    "283 - DAIWA BANK CANADA",
    "265 - DEUTSCHE BANK CANADA",
    "261 - DRESDNER BANK CANADA",
    "806 - DUCA COMMUNITY CREDIT UNION LI",
    "840 - DUNDALK DISTRICT CREDIT UNION",
    "623 - EQUITABLE BANK",
    "520 - EVANGELINE SAVINGS & MORTGAGE",
    "591 - FAMILY TRUST CORPORATION",
    "865 - FED. CAISSES POP. ACADIENNES",
    "270 - FIRST CHICAGO NBD BANK CANADA",
    "332 - FIRST COMMERCIAL BANK",
    "263 - FIRST INTERSTATE BANK OF CANAD",
    "262 - FIRST NATIONAL BANK OF CHICAGO",
    "278 - FUJI BANK CANADA",
    "829 - FÉD. CAISSES POP. DE L'ONTARIO",
    "572 - GENERAL TRUST CORPORATION OF CANADA",
    "844 - GODERICH COMMUNITY CREDIT UNIO",
    "528 - GRANVILLE SAVINGS & MORTGAGE C",
    "523 - GUARDIAN TRUST COMPANY",
    "321 - HABIB CANADIAN BANK",
    "273 - HANIL BANK CANADA",
    "837 - HEPCOE CREDIT UNION LIMITED",
    "535 - HOME SAVINGS AND LOAN CORPORAT",
    "016 - HONGKONG BANK OF CANADA",
    "026 - HONGKONG BANK OF CANADA",
    "552 - HONGKONG BANK TRUST COMPANY",
    "630 - HOUSEHOLD TRUST COMPANY",
    "340 - ICICI BANK CANADA",
    "831 - INDEPENDENT CREDIT UNION IN NB",
    "821 - INDEPENDENT CREDIT UNION IN NS",
    "851 - INDEPENDENT CREDIT UNION ONT.",
    "269 - INT'L COMM BANK OF CATHAY (CA)",
    "534 - INTERNATIONAL TRUST COMPANY",
    "536 - INVESTORS GROUP TRUST CO. LTD.",
    "314 - JP MORGAN BANK CANADA",
    "281 - JP MORGAN CANADA",
    "275 - KOREA EXCHANGE BANK OF CANADA",
    "890 - L'ALLIANCE DES CP DE L'ONTARIO",
    "836 - LA CAISSE POPULAIRE DE KAPUSKA",
    "819 - LA FED DES CAISSES POP DU MAN",
    "549 - LA SOCIETE D'EPARGNE ST BERNAR",
    "546 - LAMBTON LOAN & INVESTMENT CO L",
    "803 - LATVIAN CREDIT UNION LIMITED",
    "039 - LAURENTIAN BANK OF CANADA",
    "512 - LAURENTIAN BANK OF CANADA",
    "611 - LAURENTIAN BANK OF CANADA",
    "619 - M.R.S. TRUST COMPANY",
    "540 - MANULIFE",
    "311 - MBNA CANADA BANK",
    "242 - MELLON BANK CANADA",
    "547 - MENNONITE TRUST LIMITED",
    "268 - MITSUBISHI BANK OF CANADA",
    "280 - MITSUI BANK OF CANADA",
    "544 - MONTREAL TRUST COMPANY",
    "550 - MONTREAL TRUST COMPANY OF CA",
    "553 - MUNICIPAL SAVINGS & LOAN CORP.",
    "006 - NATIONAL BANK OF CANADA",
    "286 - NATIONAL BANK OF GREECE (CA)",
    "590 - NATIONAL TRUST COMPANY",
    "287 - NATIONAL WESTMINSTER BANK OF C",
    "802 - NEWTEL CREDIT UNION LIMITED",
    "558 - NORTH WEST TRUST COMPANY",
    "812 - NORTH YORK COMMUNITY CREDIT",
    "853 - Neo Financial",
    "846 - ONTARIO CIVIL SERVICE CR UN LT",
    "999 - OTHER INSTITUTION",
    "271 - OVERSEAS UNION BK OF SINGAPORE",
    "289 - PARIBAS BANK OF CANADA",
    "320 - PC Financial",
    "568 - PEACE HILLS TRUST COMPANY",
    "850 - POLYSAR LAMBTON CREDIT UNION L",
    "326 - PRESIDENT'S CHOICE FINANCIAL",
    "557 - PROMUTUEL CAP.TRUST CO.INC.",
    "219 - PROVINCE OF ALBERTA TREASURY B",
    "239 - PROVINCE OF ONTARIO SAVINGS OF",
    "652 - PROVINCIAL TRUST COMPANY",
    "298 - REPUBLIC NAT. BANK OF NEW YORK",
    "276 - REPUBLIC NATIONAL BANK OF N.Y.",
    "003 - ROYAL BANK OF CANADA",
    "526 - ROYAL BANK OF CANADA",
    "570 - ROYAL TRUST COMPANY (THE)",
    "580 - ROYAL TRUST CORPORATION OF CA",
    "266 - SAKURA BANK (CANADA)",
    "243 - SANWA BANK CANADA",
    "522 - SAVINGS AND INVESTMENT TRUST",
    "588 - SETTLERS SAVINGS AND MORTGAGE",
    "586 - SHERBROOKE TRUST COMPANY",
    "292 - SOCIETE GENERALE (CANADA)",
    "587 - SOCIETE NATIONALE DE FIDUCIE",
    "305 - SOTTOMAYOR BANK CANADA",
    "833 - ST STANISLAUS-ST CASIMIR'S CR",
    "574 - STANDARD TRUST COMPANY",
    "294 - STATE BANK OF INDIA (CANADA)",
    "327 - STATE STREET BANK & TRUST COMP",
    "290 - SWISS BANK CORPORATION (CA)",
    "614 - TANGERINE BANK",
    "808 - TD CANADA TRUST",
    "613 - TD TRUST COMPANY",
    "861 - TEACHERS' INVESTMENT & HOUSING",
    "307 - THE BANK OF EAST ASIA (CANADA)",
    "274 - THE BANK OF NEW YORK CANADA",
    "002 - THE BANK OF NOVA SCOTIA",
    "509 - THE CANADA TRUST COMPANY",
    "252 - THE CHASE MANHATTAN BANK OF CA",
    "551 - THE CLARICA TRUST COMPANY",
    "515 - THE DOMINION TRUST COMPANY",
    "532 - THE EFFORT TRUST COMPANY",
    "279 - THE INDUSTRIAL BANK OF JAPAN C",
    "609 - THE MUNICIPAL TRUST COMPANY",
    "563 - THE PREMIER TRUST COMPANY",
    "301 - THE SUMITOMO BANK OF CANADA",
    "004 - THE TORONTO-DOMINION BANK",
    "300 - TOKAI BANK CANADA",
    "804 - TORONTO FIRE DEPT EMPLOYEES CR",
    "607 - TRIMARK TRUST",
    "577 - TRUST GENERAL DU CANADA",
    "506 - TRUST GENERAL INC",
    "306 - U.S. BANK (CANADA)",
    "342 - UBIQUITY BANK OF CANADA",
    "296 - UNION BANK OF SWITZERLAND (CA)",
    "302 - UNITED OVERSEAS BANK (CANADA)",
    "335 - UNITED OVERSEAS BANK LIMITED",
    "838 - WESTCOAST SAVINGS CREDIT UNION",
    "Other"
];

export const incomeType = [
    "Employment",
    "Self-Employment",
    "Rental",
    "Dividend",
    "Bonus",
    "Investment Income",
    "Pension",
    "Other"
];

export const industries = ['Agriculture, Forestry, Fishing and Hunting', 'Mining, Quarrying, and Oil and Gas Extraction', 'Utilities',
    'Construction', 'Manufacturing', 'Wholesale Trade', 'Retail Trade', 'Transportation and Warehousing', 'Information and Cultural Industries',
    'Finance and Insurance', 'Real Estate and Rental and Leasing', 'Professional, Scientific and Technical Services', 'Management of Companies and Enterprises',
    'Administrative and Support, Waste Management and Remediation Services', 'Educational Services', 'Health Care and Social Assistance', 'Arts, Entertainment and Recreation',
    'Accommodation and Food Services', 'Other Services (except Public Administration)', 'Public Administration'];

export const occupations = ['Director', 'Manager', 'Professional', 'Technician', 'Tradesperson', 'Clerical', 'Salesperson', 'Service Worker'];

export const investorQuestions = [
    '1. How old are you?',
    '2. What is your family annual income before tax?',
    '3. What is your family net worth? (assets minus liabilities)',
    '4. Why do you want to start investing?',
    '5. What is your goal for your this investment portfolio? Please select the most appropriate one.',
    '6. When do you plan to start withdrawing at least 25% of your savings?',
    '7. Over the next 5 years, what is your plans for withdrawals and /or make additional contribution?',
    '8. Which following indicates your level of tolerance toward the risks related to the investment of your money.',
    '9. If your investments lose significant value because of downturns in the market, how long are you prepared to hold your existing investments in anticipation of a recovery in value?',
    '10. If you owned an investment that fell by 20% over a period of six to nine months, what would you do?',
    '11. You have the possibility of investing $10,000 for one year. After one year, in what range would you accept the final potential value of your investment?',
    '12. Which following description that best describes how much you know about investing?'
];

export const allAppStatuses = [
    'Editing',
    'Submitted',
    'Advisor Reviewed',
    'Operator Processing',
    'Done'
  ];

export const answerAttributes = [
    'q1_ans',
    'q2_ans',
    'q3_ans',
    'q4_ans',
    'q5_ans',
    'q6_ans',
    'q7_ans',
    'q8_ans',
    'q9_ans',
    'q10_ans',
    'q11_ans',
    'q12_ans'
];


export const investorOptions = [
    ['Over 71', 'Between 65 and 70', 'Between 55 and 64', 'Between 41 and 54', 'Between 18 and 40'],
    ['$25,000 and less', '$25,001 to $35,000', '$35,001 to $50,000', '$50,001 to $100,000', '$100,001 and over'],
    ['$25,000 and less', '$25,001 to $50,000', '$50,001 to $100,000', '$100,001 to $200,000', '$200,001 and over'],
    ['To generate income for today', 'To generate income for the future', 'To fund a large purchase', 'To saving for retirement',
        'To provide for my dependants (I do not anticipate using these funds)'],
    ['To ensure that my portfolio remains secure', 'To see my portfolio grow and to avoid fluctuating returns',
        'To balance growth and security, and to keep pace with inflation', 'To provide growth potential, and to accept some fluctuation in returns',
        'To provide the sole objective of potential long-term growth'],
    ['In less than 1 year', 'Between 1 and 3 years', 'Between 4 and 5 years', 'Between 6 and 10 years', 'In over 15 years'],
    ["Make regular withdrawals from your capital and no contribution (Home Buyers' Plan, retirement, etc.)",
        'Make a lump-sum withdrawal from your capital and all earnings, and no contribution',
        'Make withdrawals of all your investment earnings only, and no contribution',
        'Make partial withdrawals of your investment earnings, and likely to make contribution',
        'No withdrawals and very likely to make contribution'],
    ["Very low. I don't like my investments exposed to any short-term fluctuations and fluctuations make me feel risking, although it may earn higher returns in long-term. My sole objective is to safely conserve my investments and shelter these amounts from market fluctuations.",
        'Low. While a drop in the value of my investments bothers me, I can tolerate occasional drops to a maximum of 5%, knowing that I will earn more investment earnings in the long term.',
        'Moderate. I am prepared to tolerate a short-term drop of between 5% to 10% of the value of my investments as long as I can count on higher investment earnings in the long term.',
        'High. I am comfortable with a short-term drop of between 10% to 20% of the value of my investments because I know that in the long term, my investment earnings will allow me to make up for this drop and obtain higher earnings.',
        'Very High. I hope to achieve long-term growth. A short-term drop (less than 1 year) of 20% of the value of my investments does not worry me.'],
    ['Less than 6 months', '6 months to 1 year', '1 to 2 years', '2 to 3 years', '3 years or more'],
    ['Sell all of the remaining investment', 'Sell a portion of the remaining investment', 'Hold the investment and sell nothing',
        'Buy more of the investment with cash', 'Buy more of the investment with borrowing money'],
    ['Gains only, between $10,000 and $10,300', 'Between $9,500 and $11,000', 'Between $9,000 and $11,500', 'Between $8,500 and $12,000', 'Between $8,000 and $12,500'],
    ['I am just starting to become familiar with investments.',
        'I know that certain investments are riskier than others.',
        'I know about different investment types and their risks (e.g., equities are riskier than bonds).',
        'I understand the earnings potential and risks involved with these types of investments and their fluctuations in value over time.',
        'I keep a constant eye on stock markets (equities, bonds, funds, real estate, etc.) and I have in-depth knowledge of such investments.']
];

export const purposeOfInvestment = [
    'Retirement savings',
    'Short-term Savings for purchase',
    'Other'
];

export const sourceOfFunds = [
    'Employment income',
    'Business Income',
    'Investments',
    'Loan',
    'Gift',
    'Other'
];

export const sourceOfInitial = [
    'Electronic fund transfer (EFT)',
    'Transfer from another institution'
];

export const typeOfTransfer = [
    'All in-cash',
    'Partial policy/account'
];

export const typeOfAmount = [
    '$',
    '%'
];

export const frequencyOfPAD = [
    'Weekly',
    'Bi-weekly',
    'Monthly'
];

export const planType = [
    'Family Plan',
    'Individual Plan'
];

export const investmentCompanies = [
    'iA',
    'Canada Life',
    'Equitible Life',
    "Other"
];

export const IDType = [
    "Canadian Driver License",
    "Canadian Passport",
    "Canadian Citizenship Card",
    "Native Status Certificate",
    "Birth Certificate",
    "Health Card - BC",
    "Health Card - AB",
    "Health Card - SK",
    "Health Card - QC",
    "Health Card - NB",
    "Health Card - NL",
    "Health Card - NT",
    "Health Card - NU",
    "Health Card - YT",
    "Foreign Passport",
    "Canadian Permanent Residence Card",
    "Canadian Provincial Identification Card",
    "Firearms Licence",
    "NEXUS Card",
    "CNIB Card",
    "Canadian Forces Identification Card",
    "Other"
];

export const reasons = [
    "Currently Applying",
    "Planning To Apply",
    "Other"
];

export const authorities = [
    "Government of Canada",
    "Government of Alberta",
    "Government of British Columbia",
    "Government of Manitoba",
    "Government of New Brunswick",
    "Government of Newfoundland and Labrador",
    "Government of Northwest Territories",
    "Government of Nova Scotia",
    "Government of Nunavut",
    "Government of Ontario",
    "Government of Prince Edward Island",
    "Government of Quebec",
    "Government of Saskatchewan",
    "Government of Yukon",
    "Other Country",
    "Other Issuer",
    "CNIB"
];

export const annuitantRelation = [
    "Spouse",
    "Father",
    "Mother",
    "Son",
    "Daughter",
    "Grand Father",
    "Grand Mother",
    "Sibling",
    "Relative",
    "Friend",
    "Other"
];

export const ClientAcquisitionChannel = [
    "Client Referral",
    "Non-Client Referral",
    "Offline AiF Event",
    "Offline AiF Seminar",
    "Offline Other Event",
    "Offline Other Source",
    "Organic Online Contents",
    "Organic AiF Social Media",
    "Paid Online Ads",
    "Online Seminar",
    "Online - Self Directing"
]