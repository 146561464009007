import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './DropdownList';
import { FormHelperText } from '@mui/material';

export default function BasicSelect({options, label, value, handleChange = () => {}, error = ''}) {

  return (
    <Box className="dropdown-box">
      <FormControl error={!!error}>
        <InputLabel id="select-label">{label}</InputLabel>
        <Select className="dropdown-select"
          labelId="select-label"
          value={value}
          label= {label}
          onChange={event => handleChange(event.target.value)}
          sx={{fontSize: '22px'}}
        >
        {options.map((option, index) => (
            <MenuItem key={index} value={option}>{option}</MenuItem>
        ))}
        </Select>
        <FormHelperText sx={{fontSize: '20px'}}>{error}</FormHelperText>
      </FormControl>
    </Box>
  );
}