import { Grid, Box, IconButton } from "@mui/material"
import "./style/IdentificationTaxStatus.css"
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import { setCoapplicantInfo } from "../../../../reducers/coapplicantReducer";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText"
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../components/smallComponents/textField/TextField";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import RadioGroupBoolean from "../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean";
import { useApplication } from "../../../../contexts/ApplicationContext";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import PopupDialog from "../../../../components/smallComponents/alert/PopupDialog";
import BooleanGroup from "../../../../components/smallComponents/radioGroupBoolean/BooleanGroup";
import TextFieldWithHelper from "../../../../components/smallComponents/helper/Helper";
import { validateDate } from "@mui/x-date-pickers/internals";
import TextFieldNew from "../../../../components/smallComponents/textField/TextFieldNew";
/**
 * IdentificationTaxStatus component displays the tax status information of the main applicant.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.reasons - The list of reasons for not having a Taxpayer Identification Number (TIN).
 * @returns {JSX.Element} The rendered component.
 */


export default function IdentificationTaxStatus({ reasons, enable, applicantType }) {

    const { clickNextButton, handleErrorMessage } = useApplication();
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const dispatch = useDispatch();
    let applicant;
    const mainApplicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    if(applicantType === "Applicant"){
        applicant = mainApplicant;
    } else {
        applicant = coapplicant;
    }
    // const itemSpace = 3;
    const pageName = 'identification';

    const handleApplicantChange = (attribute, value) => {
        console.log(value)
        if(applicantType === "Applicant"){
            dispatch(setApplicantInfo({ attribute, value }))
        } else if(applicantType === "Co-Applicant"){
            dispatch(setCoapplicantInfo({ attribute, value }))
        }
    };


    const handleTinOtherChange = (value) => {
        handleApplicantChange("tinOther", value);
    }

    const handleApplicantOtherResidence = (value) => {
        handleApplicantChange("otherResidence", value);
        if (value === true) {
            handleApplicantChange("haveTinOther", true);
        }
        else {
            handleApplicantChange("haveTinOther", false);
        }
    };

    const handleHaveTINOther = (value) => {
        handleApplicantChange("haveTinOther", value);

        if (value === false) {
            handleApplicantChange("tinOther", '')
            handleApplicantChange("countryName", '')
        }
        else {
            handleApplicantChange("reasonOfNoTinOther", '')
        }
    };

    const handleCAResidenceChange = (value) => {
        // must be true to proceed
        if(value === "false") {
            setIsErrorDialogOpen(true);
            return;
        } else {
            handleApplicantChange("residenceOfCanada", value);
        }
    }

    const handleUSResidenceChange = (value) => {
        handleApplicantChange("residenceOfUnitedStates", value);
        if(value === "false") {
            handleApplicantChange("haveTinUS", false);
        }
    }

    const handleTinUSChange = (value) => {
        handleApplicantChange("tinUS", value);
    }

    const handleHaveTINinUSChange = (value) => {
        handleApplicantChange("haveTinUS", value);
    }


    const handleCountryNameChange = useCallback((value) => {
        handleApplicantChange("countryName", value)
    }, [handleApplicantChange]);

    const Questions =  () => {
        return [
        <Grid item xs={12} key={0}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box>
                        <BooleanGroup 
                            label="Tax Residence of Canada"
                            value={applicant.residenceOfCanada}
                            bigLabel={true}
                            onChange={value => handleCAResidenceChange(value)}
                            helperText="Are you a Canadian resident for tax purposes?"
                            />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
        ,
        <Grid item xs={12} key={1}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box>
                        <BooleanGroup 
                            bigLabel={true}
                            label="Tax residence of the United States"
                            value={applicant.residenceOfUnitedStates}
                            onChange={handleUSResidenceChange}
                            helperText="Are you a United States citizen or a U.S. resident for U.S. tax purposes?" 
                        />
                        {applicant.residenceOfUnitedStates === "true" && 
            <>
            <Grid item xs={12}>
                <Box>
                    <BooleanGroup
                        bigLabel={true}
                        label="Do you have Taxpayer identification number (TIN) from the U.S. ?"
                        onChange={handleHaveTINinUSChange}
                        value={applicant.haveTinUS || false}
                    />
                </Box>
            </Grid>
            {applicant.haveTinUS === "true" 
                && <Grid item xs={12}>
                    <Box>
                        <TextFieldWithHelper text='TaxPayer Identification Number (TIN)' helperText="Please provide your TIN number" largeBox={true}/>
                        <TextField
                            setStateValue={handleTinUSChange}
                            defaultValue={applicant.tinUS}
                            type={applicant.haveTinUS ? 'mandatory' : 'optional'} name='Applicant TIN' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                        />
                    </Box>
                </Grid>}
            {(!applicant.haveTinUS || applicant.haveTinUS === "false" ) && <Grid item xs={12} >
                <Box>
                    <TextFieldText text="Explain why you don't have TIN" largeBox={true}/>
                    <DropdownList
                        options={reasons}
                        setStateValue={value => handleApplicantChange("reasonOfNoTinUS", value)}
                        defaultValue={applicant.reasonOfNoTinUS}
                        type={applicant.residenceOfUS && !applicant.haveTinUS ? 'mandatory' : 'optional'} 
                        name='Applicant Reason for No TIN' 
                        pageName={pageName} 
                        buttonCheck={clickNextButton[pageName]}
                    ></DropdownList>
                </Box>
            </Grid>}
        </>}
                    </Box>

                </Grid>
            </Grid>
        </Grid>
        ,
        <Grid item xs={12} key={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box>
                        <BooleanGroup
                            onChange={handleApplicantOtherResidence}
                            value={applicant.otherResidence}
                            bigLabel={true}
                            label="Tax residence of a jurisdiction other than Canada or the United States?"
                            helperText="Are you a resident for tax purposes in a country or region other than Canada or the United States?"
                        />
                    </Box>
                    {applicant.otherResidence === "true" &&
            <>
            <Grid item xs={12} >
                    <TextFieldText largeBox={true} text='If yes, what is the country name of the Taxpayer Identification Number (TIN) that you have? '
                    />
                    <TextFieldNew
                        onChange={handleCountryNameChange}
                        value={applicant.countryName}
                    />
            </Grid>
                    <Grid item xs={12} >
                        <Box>
                            <BooleanGroup
                                label="Do you have Taxpayer identification number (TIN) outside the U.S. or Canada?"
                                onChange={handleHaveTINOther}
                                value={applicant.haveTinOther || false}
                                disabled={!applicant.residenceOfOther}
                                type={applicant.residenceOfOther ? 'mandatory' : 'optional'} 
                                bigLabel={true}
                            />
                        </Box>
                    </Grid>
                    {applicant.haveTinOther === "true" && <Grid item xs={12} >
                        <Box>
                            <TextFieldText text='TaxPayer Identification Number (TIN) ' />
                            <TextField
                                disabled={!applicant.residenceOfOther || !applicant.haveTinOther}
                                setStateValue={handleTinOtherChange} 
                                defaultValue={applicant.tinOther}
                                type={applicant.residenceOfOther && applicant.haveTinOther ? 'mandatory' : 'optional'} 
                                name='Applicant TIN Other' 
                                pageName={pageName} 
                                buttonCheck={clickNextButton[pageName]}
                            ></TextField>
                        </Box>
                    </Grid>}
                    {(!applicant.haveTinOther || applicant.haveTinOther === "false") && <Grid item xs={12} >
                        <Box>
                            <TextFieldWithHelper largeBox={true} text="Explain why you don't have TIN" helperText="If you do not have a TIN for a specific jurisdiction, indicate the reason"/>
                            <DropdownList
                                options={reasons}
                                setStateValue={value => handleApplicantChange("reasonOfNoTinOther", value)}
                                defaultValue={applicant.reasonOfNoTinOther}
                                type={applicant.haveTinOther === "false" ? 'mandatory' : 'optional'} name='Applicant Reason for No TIN Other' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                            ></DropdownList>
                        </Box>
                </Grid>}
            </>}
                </Grid>

            </Grid>
        </Grid>
        ]};

    // questions tab prev and next function
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const goToPreviousQuestion = () => {
        setCurrentTabIndex(currentTabIndex - 1);
    };

    const goToNextQuestion = () => {
        setCurrentTabIndex(currentTabIndex + 1);
    };

    const questions = Questions();

    return (
        <>
            <Box className="section-main-box-field-Tax" display={enable ? 'block' : 'none'} >

                <TitleText text="Main Applicant Tax Status" />

                <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative', alignItems: 'center' }}>
                    <IconButton onClick={goToPreviousQuestion} disabled={currentTabIndex === 0} sx={{position: 'absolute', left :'0', marginLeft: '20px'}}>
                        <ArrowBack sx={{ fontSize: 45 }} />
                    </IconButton>
                    <Box>
                        {questions[currentTabIndex]}
                    </Box>
                    <IconButton onClick={goToNextQuestion} disabled={currentTabIndex === questions.length - 1}sx={{position: 'absolute', right :'0', marginRight: '20px'}}>
                        <ArrowForward sx={{ fontSize: 45 }} />
                    </IconButton>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {currentTabIndex < 2 ? 'please review until the end of the Tab' : ''}
                </Box>

            </Box>
            <PopupDialog
                open={isErrorDialogOpen}
                onClose={() => setIsErrorDialogOpen(false)}
                title="Tax Residence of Canada"
                message="The application cannot be proceeded with this answer. Please review the question."
            />
        </>
    );
};